<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    custom-class="renewTableDialog"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <div class="renewTableDialog__content">
      {{ content }}
    </div>
    <div>
      <el-form
        ref="form"
        :label-position="'left'"
        label-width="140px"
        :model="form"
      >
        <el-form-item label="收件人">
          <el-autocomplete
            style="width: 100%"
            v-model="form.receiver"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="选择转收件人"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="收件人邮箱">
          <el-input
            v-model="form.receiverEmail"
            placeholder="收件人邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="New Contract Period">
          <el-select
            clearable
            v-model="form.newContractPeriod"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in newContractPeriodList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="New Notice Period">
          <el-select
            v-model="form.noticePeriodMonth"
            placeholder="请选择"
            @change="changeNoticePeriodMonth"
          >
            <el-option
              v-for="item in newNoticePeriodList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  baseDataByCode,
  getLineManageEmailList,
  getRenewInfo,
  hcInformationContractRenew,
  hcInformationContractSendEmail
} from "@/api/employees";

export default {
  name: 'renewTableDialog',
  data() {
    return {
      dialogVisible: false,
      fullscreenLoading: false,
      title: '',
      content: '',
      flag: '',
      newContractPeriodList: [
        {
          label: '12',
          value: '12'
        },
        {
          label: '24',
          value: '24'
        },
        {
          label: '36',
          value: '36'
        },
        {
          label: '无固定月',
          value: '无固定月'
        }
      ],
      newNoticePeriodList:[],
      form: {
        id: '',
        receiver: '',
        receiverEmail: '',
        newContractPeriod: '',
        noticePeriodMonth: ''
      },
      jobGrade:'',
      level2:'',
    }
  },
  methods: {
    close() {
      this.form = {
        id: '',
        receiver: '',
        receiverEmail: '',
        newContractPeriod: '',
        noticePeriodMonth: ''
      }
      this.title = ''
      this.content = ''
      this.flag = ''
      this.dialogVisible = false
    },
    show(val) {
      if (val) {
        this.flag = val.flag
        this.title = val.title
        this.content = val.content
        this.form.id = val.candidateInformationId
        this.jobGrade = val.jobGrade
        this.level2 = val.level2
        this.getFormLine()
        this.dialogVisible = true
      }
    },
    initNoticePeriodMonth(jobGrade){
      this.$Api.induction.getNoticePeriodByLevel2AndJobGrading({
        jobGrading:this.jobGrade,
        level2:this.level2,
      }).then(res=>{
        if(res.code === 200){
          this.form.noticePeriodMonth = res.data.noticePeroid
        }
      })
    },
    changeNoticePeriodMonth(val){
      this.$forceUpdate();
      this.form.noticePeriodMonth = val
    },
    getFormLine() {
      const data = {
        ...this.form
      }
      getRenewInfo(data).then((res) => {
        if (res && res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            if(item === 'noticePeriodMonth'){ // 后端返回字段 与 提交字段不一样
              this.form.noticePeriodMonth = res.data['newNoticePeriod'] || ''
            }else {
              this.form[item] = res.data[item]
            }
          })
          // if (this.flag === 'renew') {
          //   this.initNoticePeriodMonth(this.jobGrade)
          // }
          // this.initNoticePeriodMonth(this.jobGrade)
        }
      })
      baseDataByCode( {vo: 'notice_period_months' }).then(({code,data})=>{
        if(code === 200){
          this.newNoticePeriodList = data.reverse()
        }
      })
    },
    save() {
      if (this.flag === 'renew') {
        const data = {
          ...this.form
        }
        this.fullscreenLoading = true
        hcInformationContractRenew(data).then((res) => {
          this.fullscreenLoading = false
          if (res && res.code === 200) {
            this.$message.success(res.msg)
            this.close()
            this.$emit('close')
          }
        })
      } else if (this.flag === 'sendEmail') {
        const data = {
          ...this.form
        }
        hcInformationContractSendEmail(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('发送邮件成功')
            this.close()
            this.$emit('close')
          }
        })
      }
    },
    handleSelect(item) {
      if (item) {
        console.log(item)
        this.form.receiver = item.value
        this.form.receiverEmail = item.businessMail
      }
    },
    querySearch(queryString, cb) {
      const data = {
        lineManager: queryString
      }
      getLineManageEmailList(data).then((res) => {
        if (res && res.code === 200) {
          let transferList = res.data
          const cbList = []
          if (transferList && transferList.length) {
            transferList.forEach((item) => {
              cbList.push({
                value: item.lineManager,
                businessMail: item.businessMail
              })
            })
            cb(cbList)
          } else {
            cb([])
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.renewTableDialog {
  &__content {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
