export const postTransferTableOptions = [
  {
    key: 'chineseName',
    name: 'Chinese Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'oldLevel2Leve3',
    name: '原Level2&Level3',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'oldHrbp',
    name: '原HRBP Ower',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  },
  {
    key: 'effectiveDate',
    name: 'Effective Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  }
  // {
  //   key: 'hcIsBroughtOut',
  //   name: '是否带HC调岗',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: null // 默认宽度，像素
  // }
]

export const postTransferTableOptions1 = [
  {
    key: 'newHrbp',
    name: '新HRBP Owner',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 140 // 默认宽度，像素
  },
  {
    key: 'joinDate',
    name: 'Original Join Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },

  {
    key: 'flow',
    name: '流程',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'toDoUser',
    name: '操作人',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 140 // 默认宽度，像素
  }
]
