<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    custom-class="renewTableRoleButtonEmployeeNotificationDialog"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <div class="renewTableRoleButtonEmployeeNotificationDialog__content">
      {{ content }}
    </div>
    <div>
      <el-form
        ref="form"
        :label-position="'left'"
        label-width="80px"
        :model="form"
      >
        <el-form-item label="OPS">
          <el-select
            clearable
            v-model="form.opsName"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in opsNameList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!--          <el-autocomplete-->
          <!--            style="width: 100%"-->
          <!--            v-model="form.opsName"-->
          <!--            :fetch-suggestions="querySearch"-->
          <!--            :trigger-on-focus="false"-->
          <!--            placeholder="OPS"-->
          <!--            @select="handleSelect"-->
          <!--          ></el-autocomplete>-->
        </el-form-item>
        <el-form-item label="Office 楼层">
          <el-input placeholder="Office 楼层" v-model="form.floor">
            <template slot="append">楼</template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getOps, hcInformationContractNotified } from '@/api/employees'

export default {
  name: 'renewTableRoleButtonEmployeeNotificationDialog',
  data() {
    return {
      dialogVisible: false,
      title: '',
      content: '',
      flag: '',
      opsNameList: [],
      form: {
        id: '',
        ops: '',
        opsName: '',
        floor: ''
      }
    }
  },
  methods: {
    close() {
      this.form = {
        id: '',
        opsName: '',
        ops: '',
        floor: ''
      }
      this.title = ''
      this.content = ''
      this.flag = ''
      this.dialogVisible = false
    },
    show(val) {
      if (val) {
        this.title = val.title
        this.content = val.content
        this.form.id = val.candidateInformationId
        this.querySearch()
        this.dialogVisible = true
      }
    },
    save() {
      const data = {
        ...this.form
      }
      hcInformationContractNotified(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('发起员工通知成功')
          this.close()
          this.$emit('close')
        }
      })
    },
    querySearch() {
      getOps().then((res) => {
        if (res && res.code === 200) {
          this.opsNameList = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.renewTableRoleButtonEmployeeNotificationDialog {
  &__content {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
