<script>
import { defineComponent, ref } from 'vue'
import { getItem } from '@/utils/storage'
export default defineComponent({
  props: {
    scope: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    let roleButton = ref([])
    let scope = props.scope
    let userName = getItem('userName')
    let newHrbp = scope.newHrbp || ''
    let oldHrbp = scope.oldHrbp || ''
    let status = ref(scope.status)
    const pushRoleButton = (val) => {
      roleButton.value = []
      roleButton.value.push(...val)
    }
    const oldNewHrbp = () => {
      return userName && newHrbp === oldHrbp && userName === newHrbp
    }
    //是否在流程内

    if (status.value) {
      if (oldNewHrbp()) {
        switch (status.value) {
          case '已提交，待准入':
            pushRoleButton([
              'postTransferTableRoleButtonTermination',
              'postTransferTableRoleButtonReceive'
            ])
            break
          case '已通知，待生效':
            pushRoleButton(['postTransferTableRoleButtonTermination','postTransferTableRoleButtonDetail'])
            break
          case '已转入':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '调岗已终止':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          default:
            pushRoleButton([])
        }
      } else if (userName && userName === oldHrbp) {
        switch (status.value) {
          case '已发起，待提交':
            pushRoleButton([
              'postTransferTableRoleButtonEditButton',
              'postTransferTableRoleButtonTermination'
            ])
            break
          case '已提交，待准入':
            pushRoleButton(['postTransferTableRoleButtonTermination','postTransferTableRoleButtonDetail'])
            break
          case '已通知，待生效':
            pushRoleButton(['postTransferTableRoleButtonTermination','postTransferTableRoleButtonDetail'])
            break
          case '已转入':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '调岗已终止':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          default:
            pushRoleButton([])
        }
      } else if (userName && userName === newHrbp) {
        switch (status.value) {
          case '已提交，待准入':
            pushRoleButton([
              'postTransferTableRoleButtonTermination',
              'postTransferTableRoleButtonReceive',
            ])
            break
          case '已通知，待生效':
            pushRoleButton(['postTransferTableRoleButtonTermination','postTransferTableRoleButtonDetail'])
            break
          case '已转入':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '调岗已终止':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          default:
            pushRoleButton([])
        }
      }else{
        switch (status.value) {
          case '已提交，待准入':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '已通知，待生效':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '已转入':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          case '调岗已终止':
            pushRoleButton(['postTransferTableRoleButtonDetail'])
            break
          default:
            pushRoleButton([])
        }
      }
    } else {
      roleButton.value = []
    }

    // 发送checkList邮件，参数取列表的workflowId    (transferStatus为3的 即已通知 待生效状态的才展示)
    if (scope.transferStatus == 3) {
      roleButton.value.push('postTransferTableRoleButtonSendCheckListEmail')
    }
    // roleButton.value.push('postTransferTableRoleButtonEditButton')
    return {
      roleButton,
      status
    }
  },
  render(createElement, context) {
    const emit = (val) => {
      this.$emit(val, this.scope)
    }
    const getRoleButton = (type, name) => {
      const roleButton = {
        postTransferTableRoleButtonDetail: (
        <el-button
          class="roleButton"
          type={type}
          role="transferDetail"
          v-permission
          size="mini"
          onclick={() => {
            emit('postTransferTableRoleButtonDetailButton')
          }}
        >
          查看
        </el-button>
      ),
        postTransferTableRoleButtonEditButton: (
          <el-button
            class="roleButton"
            type={type}
            role="transferEdit"
            v-permission
            size="mini"
            onclick={() => {
              emit('postTransferTableRoleButtonEditButton')
            }}
          >
            编辑
          </el-button>
        ),
        postTransferTableRoleButtonTermination: (
          <el-button
            class="roleButton"
            type={type}
            role="transferTermination"
            v-permission
            size="mini"
            onclick={() => {
              emit('postTransferTableRoleButtonTermination')
            }}
          >
            终止
          </el-button>
        ),
        postTransferTableRoleButtonReceive: (
          <el-button
            class="roleButton"
            type={type}
            role="transferIn"
            v-permission
            size="mini"
            onclick={() => {
              emit('postTransferTableRoleButtonReceive')
            }}
          >
            接收
          </el-button>
        ),
        postTransferTableRoleButtonSendCheckListEmail: (
          <el-button
            class="roleButton"
            type={type}
            role="transferIn"
            v-permission
            size="mini"
            onclick={() => {
              emit('postTransferTableRoleButtonSendCheckListEmail')
            }}
          >
            发送checklist
          </el-button>
        ),
      }
      return roleButton[name]
    }
    const mapButton = () => {
      if (this.roleButton.length) {
        if (this.roleButton.length === 1) {
          return <div>{getRoleButton('', [this.roleButton[0]])}</div>
        } else if (this.roleButton.length === 2) {
          return (
            <div>
              {getRoleButton('', [this.roleButton[0]])}
              {getRoleButton('', [this.roleButton[1]])}
            </div>
          )
        } else if (this.roleButton.length > 2) {
          return (
            <div>
              {getRoleButton('', this.roleButton[0])}
              <el-dropdown triger="click">
                <el-button class="dropdownButton">
                  <svg-icon
                    icon="ellipsis"
                    class="tablePointSvgClass"
                    title="更多"
                  />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  {this.roleButton.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <el-dropdown-item>
                          {getRoleButton('text', item)}
                        </el-dropdown-item>
                      )
                    } else {
                      return null
                    }
                  })}
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          )
        }
      } else {
        return null
      }
    }
    const mapStatus = () => {
      let status = this.status
      if (status) {
        let splitStatus = status.split('，')
        if (splitStatus && splitStatus.length) {
          const isSplit = () => {
            if (splitStatus[1]) {
              return (
                <span>
                  <span class="circle"></span>
                  <span>{splitStatus[1]}</span>
                </span>
              )
            } else {
              return null
            }
          }
          return (
            <div class="statusText">
              <span>{splitStatus[0]}</span>
              {isSplit()}
            </div>
          )
        }
      }
    }
    return (
      <div class="postTransferTableRoleButton">
        {mapStatus()} {mapButton()}
      </div>
    )
  }
})
</script>

<style lang="scss" scoped>
.postTransferTableRoleButton {
  display: flex;
  div {
    &:first-child {
      margin-right: 14px;
    }
    .el-button {
      transform: scale(0.8);
      margin-right: -2px !important;
    }
    .dropdownButton {
      transform: scale(0.75);
    }
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e6a23c;
    margin-left: 8px;
    margin-right: 4px;
  }
  .statusText {
    line-height: 30px;
  }
}
</style>
