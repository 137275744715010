<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    custom-class="becomeRegularWorkerDialog"
    append-to-body
    destroy-on-close
    :before-close="cancel"
  >
    <template v-slot:title>
      <div class="topTitle">
        <div v-if="flag === 'quit'">发起离职</div>
        <div v-else-if="flag === 'becomeRegular'">
          <div>转正审核 <span> / 离职</span></div>
        </div>
      </div>
    </template>
    <descriptions :descriptionData="descriptionData" />
    <PGTitle title="附件信息">
      <pgUploadFile
        :id="id"
        type="Leave"
        :limit="3"
        :upload-list-call="uploadListCall"
      />
    </PGTitle>
    <PGTitle title="离职信息">
      <FormDesigner ref="FormDesignerRef" :formScale="formScale" />
    </PGTitle>
    <div slot="footer" class="bottomButton">
      <div v-if="flag === 'quit'">
        <el-button @click="previous">取消</el-button>
        <el-button @click="staging" v-if="!['已通知员工，待生效','已提交，待通知员工'].includes(status)">暂存</el-button>
        <el-button type="primary" @click="submit" :loading="loading"
          >确定</el-button
        >
      </div>
      <div v-else-if="flag === 'becomeRegular'">
        <el-button @click="pre">上一步</el-button>
        <el-button type="primary" @click="submit" :loading="loading"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import FormDesigner from '@/components/FormDesigner/FormDesigner'

import {
  getCodeLeaveValue,
  getUserLeaveInfo,
  rejectLeave,
  saveLeaveForm
} from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'

export default {
  name: 'ResignationRelatedInformation',
  components: {
    descriptions,
    PGTitle,
    pgUploadFile,
    FormDesigner
  },
  created() {},
  data() {
    return {
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'socialInsuranceHousingFundPaidTill',
                rules: 'required',
                label: 'Social Insurance Housing Fund paid till',
                id: Math.random(),
                tooltip: true,
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },

            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lastWorkingDate',
                rules: 'required',
                label: 'Last Working Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lastEmploymentDate',
                rules: 'required',
                label: 'Last Employment Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'resignationSubmissionDate',
                rules: 'required',
                label: 'Resignation Submission Date',
                id: Math.random(),
                clearable: true,
                tooltip: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label:
                  'Destination profile - Where did the Voluntary leavers go?',
                prop: 'destinationProfileWhereDidTheVoluntaryLeaversGo',
                tooltip: true,
                type: 'input',
                rules: 'required',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'paidOutAnnualLeaveDays',
                rules: 'required',
                tooltip: true,
                label: 'Paid Out Annual Leave Days',
                id: Math.random(),
                placeholder: '请选择',
                type: 'inputNumber',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'overtakenAnnualLeaveDays',
                rules: 'required',
                tooltip: true,
                label: 'Overtaken Annual Leave Days',
                id: Math.random(),
                type: 'inputNumber',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'overtakenSickLeaveDays',
                rules: 'required',
                tooltip: true,
                label: 'Overtaken Sick Leave Days',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Leave Type- Leave Reason',
                prop: 'leaveTypeLeaveReason',
                type: 'select',
                tooltip: true,
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                onClick: () => {},
                change: () => {}
              }
            },
            {
              span: 18,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Remark',
                prop: 'remark',
                type: 'input',
                options: [],
                rules: '',
                value: '',
                placeholder: '请输入'
              }
            }
          ]
        }
      ],
      descriptionData: [],
      descriptionDataQuit: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: ''
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: ''
            }
          ]
        }
      ],
      descriptionDataBecomeRegular: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: ''
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: ''
            }
          ]
        }
      ],
      id: '',
      flag: 'quit',
      dialogVisible: false,
      uploadListCall: [],
      cpForm: [],
      loading: false,
      status:''
    }
  },
  methods: {
    pre() {
      this.$emit('previous')
    },
    show(val) {
      if (val) {
        this.id = val.id
        this.flag = val.flag
        if (this.flag === 'quit') {
          this.descriptionData = this.descriptionDataQuit
          this.status = val.status
        } else if (this.flag === 'becomeRegular') {
          this.descriptionData = this.descriptionDataBecomeRegular
        }
        this.cpForm = [...this.formScale]
        this.queryApi()
        this.dialogVisible = true
      }
    },
    async staging() {
      this.loading = true
      const data = await this.$refs.FormDesignerRef.getValue()
      if (data) {
        const returnData = {
          id: this.id,
          saveType: '2',
          ...data
        }
        saveLeaveForm(returnData).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.$message.success('离职暂存成功')
            this.cancel()
            this.closeEmit()
          }
        })
      } else {
        this.loading = false
      }
    },
    closeEmit() {
      this.$emit('close')
    },
    async submit() {
      this.loading = true
      const data = await this.$refs.FormDesignerRef.getValue()
      if (data) {
        const returnData = {
          id: this.id,
          saveType: '1',
          ...data
        }
        let api = null
        switch (this.flag) {
          case 'becomeRegular':
            api = rejectLeave
            break
          case 'quit':
            api = saveLeaveForm
            break
        }
        if (api) {
          api(returnData).then((res) => {
            this.loading = false
            if (res && res.code === 200) {
              this.$message.success('离职提交成功')
              this.cancel()
              this.closeEmit()
            }
          })
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    previous() {
      this.cancel()
    },
    close() {
      this.dialogVisible = false
    },
    cancel() {
      this.formScale = this.cpForm
      this.uploadListCall = []
      this.id = ''
      this.dialogVisible = false
    },
    queryApi() {
      getCodeLeaveValue().then((res) => {
        if (res && res.code === 200) {
          const data = {
            leaveTypeLeaveReason: res.data.map((item) => {
              return {
                label: item.label,
                value: item.value
              }
            })
          }
          this.$refs.FormDesignerRef.setOptions(data)
        }
      })
      const data = {
        id: this.id
      }
      getUserLeaveInfo(data).then((res) => {
        if (res && res.code === 200) {
          mergeDesDataOfPort(this.descriptionData, res.data)
          this.uploadListCall = res.data.files
          this.$refs.FormDesignerRef.setValue(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topTitle {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  color: #909399;
  span {
    font-size: 21px;
    font-weight: 500;
    color: #303133;
  }
}
</style>
