<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="25%"
    custom-class="becomeRegularWorkerDialog"
    append-to-body
    destroy-on-close
    :before-close="cancel"
  >
    <template v-slot:title>
      <div class="topTitle">
        <div>发起员工离职通知</div>
      </div>
    </template>
    <div class="ctit">即将向{{ name }}发送离职通知邮件</div>
    <div class="name">离职相关负责人</div>
    <el-form
      class="emailForm"
      ref="ruleEduFormRef"
      :model="form"
      :rules="rules"
      label-width="140px"
      label-position="right"
    >
      <el-form-item
        label="Finance Department"
        prop="financeValue"
        style="margin-bottom: 20px !important"
      >
        <el-select
          v-model="form.financeValue"
          placeholder="请选择"
          multiple
          style="width: 300px"
          @change="change('financeValue')"
          filterable
        >
          <el-option
            v-for="item in finance"
            :key="item.value"
            :label="item.value"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Admin Department"
        prop="adminValue"
        style="margin-bottom: 20px !important"
      >
        <el-select
          v-model="form.adminValue"
          placeholder="请选择"
          multiple
          style="width: 300px"
          @change="change('adminValue')"
          filterable
        >
          <el-option
            v-for="item in admin"
            :key="item.value"
            :label="item.value"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="IT Department"
        prop="itValue"
        style="margin-bottom: 20px !important"
      >
        <el-select
          v-model="form.itValue"
          placeholder="请选择"
          multiple
          style="width: 300px"
          @change="change('itValue')"
          filterable
        >
          <el-option
            v-for="item in it"
            :key="item.value"
            :label="item.value"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Talent Department"
        prop="talentValue"
        style="margin-bottom: 20px !important"
      >
        <el-select
          v-model="form.talentValue"
          placeholder="请选择"
          multiple
          style="width: 300px"
          @change="change('talentValue')"
          filterable
        >
          <el-option
            v-for="item in talent"
            :key="item.value"
            :label="item.value"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="bottomButton">
      <div>
        <el-button @click="previous">取消</el-button>
        <el-button type="primary" @click="submit" :loading="loading"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { departmentPeople, sendExitEmail } from '@/api/quto'

export default {
  name: 'ResignationRelatedInformation',

  created() {},
  data() {
    return {
      rules: {
        adminValue: [
          {
            validator: this.validArr,
            message: '请选择',
            trigger: 'blur',
            len: 1
          }
        ],
        financeValue: [
          { validator: this.validArr, message: '请选择', trigger: 'blur' }
        ],
        itValue: [
          { validator: this.validArr, message: '请选择', trigger: 'blur' }
        ],
        talentValue: [
          { validator: this.validArr, message: '请选择', trigger: 'blur' }
        ]
      },
      form: {
        adminValue: [],
        financeValue: [],
        itValue: [],
        talentValue: []
      },
      id: '',
      name: '',
      admin: [],
      finance: [],
      it: [],
      talent: [],
      dialogVisible: false,
      loading: false
    }
  },
  methods: {
    // 单项验证
    change(name) {
      this.$refs.ruleEduFormRef.validateField(name)
    },
    validArr(rule, value, callback) {
      console.log('value', value)
      if (!value.length) {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    },
    get() {
      departmentPeople().then((res) => {
        if (res && res.code === 200) {
          this.admin = res.data.admin
          this.finance = res.data.finance
          this.it = res.data.it
          this.talent = res.data.talent
        }
      })
    },
    show(val) {
      this.dialogVisible = true
      if (val) {
        this.get()
        this.id = val.id
        this.name = val.chineseName
      }
    },
    submit() {
      this.$refs.ruleEduFormRef.validate((valid) => {
        if (valid) {
          this.loading = true
          let data = {
            candidateId: this.id,
            admin: this.form.adminValue,
            finance: this.form.financeValue,
            it: this.form.itValue,
            talent: this.form.talentValue
          }
          sendExitEmail(data).then((res) => {
            if (res && res.code === 200) {
              this.$message({
                type: 'success',
                message: '发送成功'
              })
              this.dialogVisible = false
              this.form = {
                adminValue: [],
                financeValue: [],
                itValue: [],
                talentValue: []
              }
              this.id = ''
              this.$emit('close')
            }
            this.loading = false
          })
        }
      })
    },
    previous() {
      this.cancel()
    },
    close() {
      this.dialogVisible = false
    },
    cancel() {
      this.form = {
        adminValue: [],
        financeValue: [],
        itValue: [],
        talentValue: []
      }
      this.name = ''
      this.id = ''
      this.dialogVisible = false
    }
  }
}
</script>

<style>
.emailForm .el-select{
  width:100% !important;
}

.emailForm .el-form-item__label {
  text-align: right !important;
  /* margin-right: 10px; */
  padding-right: 10px;
  box-sizing: border-box;
}
</style>
<style lang="scss" scoped>
.name {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ctit {
  font-size: 20px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.topTitle {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #333;
  span {
    font-size: 21px;
    font-weight: 500;
    color: #333;
  }
}
</style>
