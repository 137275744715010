<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="promotionTableAuditDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
  >
    <template v-slot:title>
      <div>填写晋升信息</div>
    </template>
    <div class="drawer_content">
      <el-steps
        style="display: none"
        :active="pageActive"
        simple
        finish-status="success"
      >
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
      </el-steps>
      <transition name="fade-transform" mode="out-in">
        <AudiData
          :rowData="rowData"
          ref="AudiDataRef"
          v-if="pageActive === 0"
        />
        <AudiDataNext :rowData="rowData" v-if="pageActive === 1" @setId="setId"></AudiDataNext>
      </transition>
    </div>
    <div class="dialog-footer">
      <el-button @click="close(false)">取 消</el-button>
      
      <el-button @click="next" v-if="pageActive === 0">下一步</el-button>
      <el-button @click="pre" v-if="pageActive === 1">上一步</el-button>
      <el-button
        @click="stagingClose('staging')"
        :loading="stagingLoading"
        type="primary"
        v-if="pageActive === 0"
        >暂存</el-button
      >
      <el-button v-if="pageActive === 1" @click="submitPro" type="primary"
        >提交</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import AudiData from '@/views/employee/components/AudiData'
import AudiDataNext from '@/views/employee/components/AudiDataNext'
import { editPromotion, staffPromotionSubmit,promotionInfo } from '@/api/employees'
export default {
  name: 'promotionTableAuditDialog',
  components: {
    AudiData,
    AudiDataNext
  },
  data() {
    return {
      drawer: false,
      pageActive: 0,
      buttonLoading: false,
      stagingLoading: false,
      rowData: {},
      jumpFlag: '',
      // 后端反馈的提交用此id
      workflowId:'',
    }
  },
  methods: {
    setId(workflowId){
      this.workflowId = workflowId;
    },


    close(val = true) {
      const remove = () => {
        this.rowData = false
        this.drawer = false
        this.pageActive = 0
        this.buttonLoading = false
        this.stagingLoading = false
      }
      if (val) {
        remove()
        this.$emit('back')
      } else {
        remove()
      }
    },
    stagingClose(val) {
      this.staging(val, () => {
        this.close()
      })
    },
    show(row) {
      if (row) {
        const data = {
          candidateInformationId:row.candidateInformationId,
          flag: row.flag
        }
        promotionInfo(data).then((res) => {
          if (res && res.code === 200) {
            this.rowData = row
            this.jumpFlag = this.rowData.jumpFlag
            this.drawer = true
          }
        })
       
      }
    },
    next() {
      if (this.pageActive !== 1) {
        let that = this
        this.staging('submit', function () {
          that.pageActive++
        })
      }
    },
    pre() {
      if (this.pageActive !== 0) {
        this.rowData.flag = '1'
        this.pageActive--
      }
    },

    // id错误
    submitPro() {
      const data = {
        id: this.workflowId
      }
      staffPromotionSubmit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('晋升提交成功')
          this.close()
        }
      })
    },
    async staging(val = 'staging', cb = () => {}) {
      const AudiDataRef = await this.$refs.AudiDataRef.getData(val)
      if (AudiDataRef) {
        this.stagingLoading = true
        editPromotion(AudiDataRef).then((res) => {
          this.stagingLoading = false
          if (res && res.code === 200) {
            this.$message.success('暂存成功')
            cb()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.promotionTableAuditDialog {
  .el-drawer__header {
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
</style>
