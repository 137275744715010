import { staffTemplateDownload } from '@/api/employees'
import { computed } from 'vue'
const { Message } = $PCommon
export const downloadStaff = (data, onTheJobPageData) => {
  const isSelectVal = computed(() => onTheJobPageData.selectVal.length !== 0)
  if (isSelectVal.value) {
    const downloadData = {
      flag: data.flag,
      ids: onTheJobPageData.selectVal.map((item) => item.candidateInformationId)
    }
    console.log(JSON.stringify(downloadData.ids));
    staffTemplateDownload(downloadData)
  } else {
    Message.warning('请选择下载项')
  }
}
