<template>
  <el-dialog
    title="审核"
    :visible.sync="positiveReviewDialogVisible"
    width="400px"
    custom-class="renewAuditDialog"
    append-to-body
    destroy-on-close
    :before-close="cancel"
  >
    <div class="renewAuditDialog__positiveReviewContent">
      <div class="renewAuditDialog__positiveReviewContent__item">
        <span>Name</span>
        <span>{{ PositiveReviewData.englishName }}</span>
      </div>
      <div class="renewAuditDialog__positiveReviewContent__item">
        <span>LM续签审核意见</span>
        <span>{{
          PositiveReviewData.isRenew ? '同意续签' : '不同意续签'
        }}</span>
      </div>
      <div class="renewAuditDialog__positiveReviewContent__item">
        <span>审核</span>
        <span>
          <el-switch
            v-model="PositiveReviewData.review"
            active-color="#6EBAAF"
            inactive-color="#E36124"
          >
          </el-switch>
          <span class="switchText" v-if="PositiveReviewData.review">同意</span>
          <span class="switchText" v-else>不同意</span>
        </span>
      </div>
    </div>
    <div slot="footer" align="right" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button
        type="primary"
        @click="agreeReview"
        :loading="PositiveReviewData.loading"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  hcInformationContractApprove,
  hcInformationContractReject,
  positive
} from '@/api/employees'
import quitDialog from '@/views/employee/dialog/quitDialog'

export default {
  name: 'PositiveReviewDialog',
  components: {
    quitDialog
  },
  data() {
    return {
      positiveReviewDialogVisible: false,
      PositiveReviewData: {
        englishName: '',
        assessmentResult: '',
        review: false,
        loading: false,
        isRenew: false
      },
      rowData: {}
    }
  },
  methods: {
    show(val) {
      if (val) {
        this.PositiveReviewData.englishName = val.englishName
        this.rowData = JSON.parse(JSON.stringify(val))
        this.PositiveReviewData.isRenew = val.isRenew
        this.PositiveReviewData.assessmentResult = val.assessmentResult
        this.positiveReviewDialogVisible = true
      }
    },
    cancel() {
      this.positiveReviewDialogVisible = false
      this.rowData = {}
      this.PositiveReviewData = {
        englishName: '',
        assessmentResult: '',
        review: false,
        loading: false
      }
    },
    close() {
      this.$emit('close')
    },
    agreeReview() {
      this.PositiveReviewData.loading = true
      const data = {
        id: this.rowData.candidateInformationId
      }
      if (this.PositiveReviewData.review) {
        hcInformationContractApprove(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('续签审核通过')
            this.cancel()
            this.$emit('close')
          }
        })
      } else {
        hcInformationContractReject(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('续签审核不通过')
            this.cancel()
            this.$emit('close')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.renewAuditDialog {
  .renewAuditDialog__positiveReviewContent {
    .renewAuditDialog__positiveReviewContent__item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          display: inline-block;
          width: 120px;
          margin-right: 12px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #868686;
        }
        &:last-child {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323435;
          display: inline-block;
          position: relative;
          .switchText {
            display: inline-block;
            margin-left: 4px;
            width: 100px;
            position: absolute;
            top: 2.5px;
          }
        }
      }
    }
  }
}
</style>
