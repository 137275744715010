<template>
  <div class="PG-uploadFile">
    <div class="bankCard">
      <FileUpload
        class="uploadButton"
        :disabled="limitShow"
        v-loading="loading"
        :limit="limit"
        @onChange="bankCardChange"
      >
        <span v-show="limit !== 0" class="iconTitle">点击上传（图片）</span>
      </FileUpload>
      <div class="bankCardList" v-if="uploadList.length">
        <div
          class="bankCardList-item"
          v-for="(item, index) in uploadList"
          :key="index"
          style="padding-bottom: 10px"
        >
          <el-popover
            class="item"
            title=""
            trigger="hover"
            width="300"
            :content="item.name"
            placement="top-start"
          >
            <span
              slot="reference"
              class="hoverOpen"
              >{{ item.name }}</span
            >
          </el-popover>
          <div
            v-show="limit !== 0"
            class="mark"
            @click="deleteImg(uploadList, index)"
            v-loading="deleteLoading"
          >
            <svg-icon
              icon="delete"
              title="删除"
              style="width: 16px; height: 16px; cursor: pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from './FileUpload.vue'
import { candidateUpload, deleteFile, upload } from '@/api/intern'
import { check, typeRange } from '@/utils/util'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
export default {
  name: 'PG-uploadFile',
  components: {
    SvgIcon,
    FileUpload
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: ''
    },
    id: {
      type: String | Number,
      default: ''
    },
    uploadListCall: {
      type: Array,
      default: () => []
    },
    getFile: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: 'upload'
    },
    isSuffix: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    uploadListCall: {
      handler(val) {
        if (val) {
          if (check(val) === typeRange.array && val.length !== 0) {
            this.uploadList = val
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    //限制上传多少个。
    limitShow() {
      return this.uploadList.length >= this.limit
    }
  },
  created() {
    switch (this.url) {
      case 'upload':
        this.uploadUrl = upload
        break
      case 'candidateUpload':
        this.uploadUrl = candidateUpload
        break
    }
  },
  data() {
    return {
      uploadList: [],
      loading: false,
      deleteLoading: false,
      uploadUrl: null
    }
  },
  methods: {
    openUpload(url) {
      window.open(url)
    },
    bankCardChange(val) {
      console.log("val11",val)
      
      // 新增文件验证
      let suffix = val.name.substring(val.name.lastIndexOf('.') + 1)
      if (
        this.isSuffix &&
        suffix !== 'rar' &&
        suffix !== 'zip' &&
        suffix !== 'doc' &&
        suffix !== 'docx' &&
        suffix !== 'pdf'
      ) {
        return this.$message.info('文件格式不支持')
      }

      // this.loading = true
      // const data = new FormData()
      // data.append('type', this.type)
      // data.append('file', val.raw)
      // data.append('id', this.id)
      this.uploadList.push(val)
      if (this.getFile) {
        this.$emit('file', val)
      }

      // this.uploadUrl(data)
      //   .then((res) => {
      //     setTimeout(() => {
      //       this.loading = false
      //     }, 500)
      //     if (res && res.code === 200) {
      //       if (res.data) {
      //         this.uploadList.push(res.data)
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     this.loading = false
      //   })
    },
    getUploadList() {
      return this.uploadList
    },
    checkText(file) {
      if (file) {
        let fileArr = file.split('.')
        let fileName = fileArr[fileArr.length - 1]
        let arr = ['png', 'jpg', 'jpeg']
        return arr.includes(fileName)
      }
    },
    deleteImg(arr, index) {
      if (this.limit !== 0) {
        this.deleteLoading = true
        this.uploadList.splice(index, 1)
        this.deleteLoading = false
        // deleteFile({ ids: arr[index].id })
        //   .then((res) => {
        //     if (res && res.code === 200) {
        //       this.uploadList.splice(index, 1)
        //       this.deleteLoading = false
        //     }
        //   })
        //   .catch((err) => {
        //     this.loading = false
        //   })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.PG-uploadFile {
  .bankCard {
    //display: flex;
    .uploadButton {
      width: 380.5px;
      height: 81px;
      background: #fbfbfb;
      border-radius: 4px;
      border: 2px dashed #c4c8cf;
      position: relative;
      :deep(.upload-icon) {
        border: none;
        position: absolute;
        left: 50%;
        top: 40%;
        background: none;
        overflow: hidden;
        .svg-icon {
          color: $--color-button-primary;
        }
        transform: translate(-50%, -50%);
      }
      .iconTitle {
        position: absolute;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #545454;
        left: 0;
        top: 35%;
      }
    }
    .bankCardList {
      height: 100%;
      width: 380.5px;
      margin-top: 12px;
      .bankCardList-item {
        width: 385px;
        height: 18px;
        margin-top: 5px;
        //margin-bottom: 5px;
        border-bottom: 1px solid #dbdbdb;
        position: relative;
        text-align: left;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1d1e20;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .uploadText {
          font-size: 60px;
          margin-top: 10px;
        }
        .hoverOpen {
          width: 90%;
          display: inline-block; /*内联对象需加*/
          word-break: keep-all; /* 不换行 */
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
          text-overflow: ellipsis;

          &:hover {
            color: $--color-button-primary;
            cursor: pointer;
          }
        }
        .mark {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
