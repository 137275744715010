<template>
  <div class="terminateReviewDialog">
    <el-dialog
      title="提示"
      :visible.sync="terminateReviewDialogVisible"
      width="400px"
      custom-class="becomeRegularWorkerDialog"
      append-to-body
      destroy-on-close
      :before-close="cancel"
    >
      <div class="terminate-content">确认终止转正流程?</div>
      <div class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="buttonLoading"
          >确 定</el-button
        >
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { terminate } from '@/api/employees';

export default {
  data() {
    return {
      terminateReviewDialogVisible: false,
      rowData: {},
      buttonLoading: false
    }
  },
  methods: {
    show(val) {
      if (val) {
        console.log('val', val)
        this.rowData = JSON.parse(JSON.stringify(val))
        this.terminateReviewDialogVisible = true
      }
    },
    cancel() {
      this.rowData = {};
      this.terminateReviewDialogVisible = false;
    },
    close() {
      this.$emit('close')
    },
    submit() {
      let params = {
        id: this.rowData.id
      }
      this.buttonLoading = true
      terminate(params).then(res => {
        this.buttonLoading = false
        if (res && res.code === 200) {
          this.$message.success('终止转正成功')
          this.cancel()
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.terminate-content{
  font-size: 16px;
  margin-bottom: 20px;
}
.dialog-footer {
  text-align: right;
  padding-bottom: 20px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
:deep(.el-drawer__header) {
  margin-bottom: 16px;
}
</style>