import { render, staticRenderFns } from "./promotionTable.vue?vue&type=template&id=24c327dc&scoped=true"
import script from "./promotionTable.vue?vue&type=script&lang=js"
export * from "./promotionTable.vue?vue&type=script&lang=js"
import style0 from "./promotionTable.vue?vue&type=style&index=0&id=24c327dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c327dc",
  null
  
)

export default component.exports