<template>
  <div class="PositiveReviewDialog">
    <el-dialog
      title="转正审核"
      :visible.sync="positiveReviewDialogVisible"
      width="400px"
      custom-class="becomeRegularWorkerDialog"
      append-to-body
      destroy-on-close
      :before-close="cancel"
    >
      <div class="becomeRegularWorkerDialog__positiveReviewContent">
        <div class="becomeRegularWorkerDialog__positiveReviewContent__item">
          <span>Name</span>
          <span>{{ PositiveReviewData.englishName }}</span>
        </div>
        <div class="becomeRegularWorkerDialog__positiveReviewContent__item">
          <span>LM审核意见</span>
          <span>{{ PositiveReviewData.assessmentResult }}</span>
        </div>
        <div class="becomeRegularWorkerDialog__positiveReviewContent__item">
          <span>审核</span>
          <span>
<!--            <el-switch-->
<!--              v-model="PositiveReviewData.review"-->
<!--              active-color="#6EBAAF"-->
<!--              inactive-color="#E36124"-->
<!--            >-->
<!--            </el-switch>-->
<!--            <span class="switchText" v-if="PositiveReviewData.review"-->
<!--              >同意</span-->
<!--            >-->
<!--            <span class="switchText" v-else>不同意</span>-->

            <el-radio v-model="PositiveReviewData.review" :label="true">转正</el-radio>
            <el-radio v-model="PositiveReviewData.review" :label="false">不转正</el-radio>

          </span>
        </div>
      </div>
      <div slot="footer" align="right" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button
          type="primary"
          v-if="PositiveReviewData.review"
          @click="agreeReview"
          :loading="PositiveReviewData.loading"
          >确定</el-button
        >
        <el-button type="primary" v-else @click="nextStep">下一步</el-button>
      </div>
    </el-dialog>
    <quitDialog
      ref="quitDialogRef"
      @previous="previous"
      @close="close"
    ></quitDialog>
  </div>
</template>

<script>
import { positive } from '@/api/employees'
import quitDialog from '@/views/employee/dialog/quitDialog'

export default {
  name: 'PositiveReviewDialog',
  components: {
    quitDialog
  },
  data() {
    return {
      positiveReviewDialogVisible: false,
      PositiveReviewData: {
        englishName: '',
        assessmentResult: '',
        review: true,
        loading: false
      },
      rowData: {}
    }
  },
  methods: {
    show(val) {
      if (val) {
        console.log('val', val)
        this.PositiveReviewData.englishName = val.englishName
        this.rowData = JSON.parse(JSON.stringify(val))
        this.PositiveReviewData.assessmentResult = val.assessmentResult
        // this.PositiveReviewData.review = val.isRenew || false
        this.positiveReviewDialogVisible = true
      }
    },
    cancel() {
      this.rowData = {}
      this.PositiveReviewData = {
        englishName: '',
        assessmentResult: '',
        review: true,
        loading: false
      }
      this.positiveReviewDialogVisible = false
    },
    nextStep() {
      this.positiveReviewDialogVisible = false
      this.$refs.quitDialogRef.show({
        flag: 'becomeRegular',
        id: this.rowData.candidateInformationId
      })
    },
    close() {
      this.$emit('close')
    },
    agreeReview() {
      this.PositiveReviewData.loading = true
      const data = {
        id: this.rowData.candidateInformationId
      }
      positive(data).then((res) => {
        this.PositiveReviewData.loading = false
        if (res && res.code === 200) {
          this.$message.success('转正审核成功')
          this.cancel()
          this.close()
        }
      })
    },
    previous() {
      this.$refs.quitDialogRef.close()
      this.show(this.rowData)
    }
  }
}
</script>

<style lang="scss" scoped>
.becomeRegularWorkerDialog {
  .becomeRegularWorkerDialog__positiveReviewContent {
    .becomeRegularWorkerDialog__positiveReviewContent__item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          display: inline-block;
          width: 77px;
          margin-right: 12px;
          text-align: left;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #868686;
        }
        &:last-child {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323435;
          display: inline-block;
          position: relative;
          .switchText {
            display: inline-block;
            margin-left: 4px;
            width: 100px;
            position: absolute;
            top: 2.5px;
          }
        }
      }
    }
  }
}
</style>
