<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="promotionAuditDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>审核晋升信息</div>
    </template>
    <div class="drawer_content">
      <descriptions :description-data="descriptionData" />
      <descriptions :description-data="descriptionData1" />
      <PGTitle title="本次晋升信息">
        <div class="AudiDataNext">
          <div class="AudiDataNext__EffectiveDate">
            <span>{{ AudiDataNextData.effectiveDate | interceptTime }}</span>
            <span>生效日期</span>
          </div>
          <div class="AudiDataNext__AudiDataNextTable">
            <PocTable
              v-loading="loading"
              :data="positionNextTableData"
              :max-height="100000000"
              class="AudiDataNext__AudiDataNextTable__positionTableData"
              hidepage
              stripe
            >
              <el-table-column
                v-for="item in positionTableDataColumn"
                :key="item.key"
                :prop="item.key"
                :label="item.name"
                :fixed="item.fixed"
                :sortable="item.sortable"
                :width="item.width"
                align="left"
                show-overflow-tooltip
              ></el-table-column>
            </PocTable>
            <PocTable
              v-loading="loading"
              :data="SalaryNextTableData"
              :max-height="100000000"
              class="AudiDataNext__AudiDataNextTable__positionTableData"
              hidepage
              stripe
            >
              <el-table-column
                v-for="item in SalaryTableDataColumn"
                :key="item.key"
                :prop="item.key"
                :label="item.name"
                :fixed="item.fixed"
                :sortable="item.sortable"
                :width="item.width"
                align="left"
                show-overflow-tooltip
              ></el-table-column>
            </PocTable>
          </div>
        </div>
      </PGTitle>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button @click="rejected">驳回</el-button>
        <el-button type="primary" @click="pass">通过</el-button>
      </div>
    </div>
    <el-dialog
      title="驳回"
      :visible.sync="dialogVisible"
      width="400px"
      class="promotionAuditDialog"
      append-to-body
      destroy-on-close
      :before-close="promotionAuditDialogClose"
    >
      <el-form
        :rules="promotionAuditDialogDataRules"
        :model="promotionAuditDialogData"
        :inline="true"
        :label-position="'top'"
        ref="promotionAuditDialogDataForm"
      >
        <el-form-item label="驳回理由" prop="rejectRemarks" style="width: 100%">
          <el-input
            type="textarea"
            placeholder="请输入驳回理由"
            v-model="promotionAuditDialogData.rejectRemarks"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="promotionAuditDialogClose">取 消</el-button>
        <el-button type="primary" @click="promotionAuditDialogSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import { promotionInfo, staffPromotionPass, turnDown } from '@/api/employees'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'promotionAuditDialog',
  components: {
    descriptions,
    PGTitle
  },
  data() {
    return {
      drawer: false,
      loading: false,
      promotionAuditDialogData: {
        rejectRemarks: ''
      },
      dialogVisible: false,
      promotionAuditDialogDataRules: {
        rejectRemarks: [
          {
            required: true,
            trigger: 'blur'
          }
        ]
      },
      descriptionData: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Current Annual Base Salary(CNY)',
              key: 'afterPromotionBaseSalaryCny',
              value: ''
            },
            {
              label: 'Current Job Title EN Title',
              key: 'afterPromotionJobTitleCn',
              value: ''
            },
            {
              label: 'Current 13 Monthly Salary',
              key: 'afterPromotionThirteenSalary',
              value: ''
            }
          ]
        }
      ],
      descriptionData1: [
        {
          title: '上次晋升信息',
          column: 4,
          content: [
            {
              label: 'Last Increase Date',
              key: 'lastIncreaseDate',
              value: ''
            },
            {
              label: 'HRBP',
              key: 'hrbp',
              value: ''
            },
            {
              label: 'Annual Increase Amount',
              key: 'annualIncreaseAmount',
              value: ''
            },
            {
              label: 'Annual Base Salary Increase%',
              key: 'annualBaseSalaryIncrease',
              value: ''
            }
          ]
        }
      ],
      positionNextTableData: [],
      SalaryNextTableData: [],
      AudiDataNextData: {},
      positionTableDataColumn: [
        {
          key: 'position',
          name: 'Position',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'positionBefore',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'positionAfter',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      SalaryTableDataColumn: [
        {
          key: 'salary',
          name: 'Salary',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'beforePromotionBenefitsCny',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'afterPromotionBenefitsCny',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      rowData: {}
    }
  },
  methods: {
    close() {
      this.rowData = {}
      this.positionNextTableData = []
      this.SalaryNextTableData = []
      this.AudiDataNextData = {}
      this.drawer = false
    },
    rejected() {
      this.dialogVisible = true
    },
    promotionAuditDialogClose() {
      this.promotionAuditDialogData = {
        rejectRemarks: ''
      }
      this.dialogVisible = false
    },
    promotionAuditDialogSubmit() {
      console.log('aa--aa', this.rowData)
      this.$refs.promotionAuditDialogDataForm.validate((validate) => {
        if (validate) {
          const data = {
            id: this.rowData.workflowId,
            rejectRemarks: this.promotionAuditDialogData.rejectRemarks
          }
          turnDown(data).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('驳回成功')
              this.drawer = false
              this.promotionAuditDialogClose()
              this.$emit('close')
              this.$emit('refresh') //刷新列表
            }
          })
        } else {
          return new Error('error')
        }
      })
    },
    pass() {
      // console.log("aaa--",this.rowData)
      const data = {
        id: this.rowData.workflowId || ''
      }
      staffPromotionPass(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('通过成功')
          this.close()
          this.$emit('close')
          this.$emit('refresh') //刷新列表
        }
      })
    },
    show(val) {
      if (val) {
        this.rowData = val
        this.initData()
        this.drawer = true
      }
    },
    initData() {
      if (this.rowData) {
        let candidateInformationId = this.rowData.candidateInformationId
        const data = {
          candidateInformationId,
          flag: '1'
        }
        const positionTableListData = [
          {
            position: 'Job Title EN',
            positionBeforeProp: 'beforePromotionJobTitleEn',
            positionAfterProp: 'afterPromotionJobTitleEn',
            positionBefore: '',
            positionAfter: ''
          },
          {
            position: 'Job Title CN',
            positionBeforeProp: 'beforePromotionJobTitleCn',
            positionAfterProp: 'afterPromotionJobTitleCn',
            positionBefore: '',
            positionAfter: ''
          },
          {
            position: 'Job Grade',
            positionBeforeProp: 'beforePromotionJobGrade',
            positionAfterProp: 'afterPromotionJobGrade',
            positionBefore: '',
            positionAfter: ''
          },
          // {
          //   position: 'Annual Leave Days',
          //   positionBeforeProp: 'beforePromotionAnnualLeaveDays',
          //   positionAfterProp: 'afterPromotionAnnualLeaveDays',
          //   positionBefore: '',
          //   positionAfter: ''
          // }
        ]
        const salaryTableListData = [
          {
            salary: 'Base Salary(CNY)',
            salaryBeforeProp: 'beforePromotionBaseSalaryCny',
            salaryAfterProp: 'afterPromotionBaseSalaryCny',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: '13 Month Salary',
            salaryBeforeProp: 'beforePromotionThirteenSalary',
            salaryAfterProp: 'afterPromotionThirteenSalary',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Annual Allowance',
            salaryBeforeProp: 'beforePromotionAnnualAllowance',
            salaryAfterProp: 'afterPromotionAnnualAllowance',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Annual Total Salary',
            salaryBeforeProp: 'beforePromotionAnnualTotalSalary',
            salaryAfterProp: 'afterPromotionAnnualTotalSalary',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Relocation Allowance',
            salaryBeforeProp: 'beforePromotionRelocationAllowance',
            salaryAfterProp: 'afterPromotionRelocationAllowance',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Benefits(CNY)',
            salaryBeforeProp: 'beforePromotionBenefitsCny',
            salaryAfterProp: 'afterPromotionBenefitsCny',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Target Bonus',
            salaryBeforeProp: 'beforePromotionTargetBonus',
            salaryAfterProp: 'afterPromotionTargetBonus',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Contractural Target Bonus',
            salaryBeforeProp: 'beforePromotionContracturalTargetBonus',
            salaryAfterProp: 'afterPromotionContracturalTargetBonus',
            salaryBefore: '',
            salaryAfter: ''
          }
        ]
        promotionInfo(data).then((res) => {
          if (res && res.code === 200) {
            this.AudiDataNextData = { ...this.AudiDataNextData, ...res.data }
            positionTableListData.forEach((item) => {
              item.positionAfter = this.AudiDataNextData[item.positionAfterProp]
              item.positionBefore =
                this.AudiDataNextData[item.positionBeforeProp]
            })
            this.positionNextTableData = positionTableListData
            salaryTableListData.forEach((item) => {
              item.afterPromotionBenefitsCny =
                this.AudiDataNextData[item.salaryAfterProp]
              item.beforePromotionBenefitsCny =
                this.AudiDataNextData[item.salaryBeforeProp]
            })
            this.SalaryNextTableData = salaryTableListData
            mergeDesDataOfPort(this.descriptionData, res.data)
            mergeDesDataOfPort(this.descriptionData1, res.data)
            this.rowData.workflowId = res.data.workflowId
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.promotionAuditDialog {
  .AudiDataNext {
    &__EffectiveDate {
      width: 224px;
      height: 56px;
      background: #f4f4f4;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 12px 16px 12px 16px;
      span {
        &:first-child {
          font-size: 24px;
          font-family: Oswald-Bold;
          font-weight: normal;
          color: #323435;
          margin-right: 8px;
        }
        &:last-child {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #323435;
        }
      }
    }
    &__AudiDataNextTable {
      display: flex;
      margin-top: 12px;
      &__positionTableData {
        margin-right: 20px;
      }
    }
  }
  .el-drawer__header {
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
</style>
