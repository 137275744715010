<template>
  <div v-if="quitTimeLineList.length" class="QuitTimeLine">
    <div class="QuitTimeLine__lineWarp">
      <div class="QuitTimeLine__lineWarp__line">
        <div
          v-for="item in quitTimeLineList"
          class="QuitTimeLine__lineWarp__line__point"
        ></div>
      </div>
    </div>
    <div class="QuitTimeLine__content">
      <div
        v-for="contentItem in quitTimeLineList"
        class="QuitTimeLine__content__item"
      >
        <div class="QuitTimeLine__content__item__title">
          {{ contentItem.date }} {{ contentItem.name }}
        </div>
        <div class="QuitTimeLine__content__item__list">
          <div
            class="QuitTimeLine__content__item__list__item"
            v-for="itemCol in contentItem.record"
          >
            <div class="contentTitle">{{ itemCol.filedName }}</div>
            <div :class="isColBlock(itemCol)">
              <div style="overflow: hidden">
                <div class="beforeClass">Before</div>
                <div class="beforeContent">
                  <el-tooltip
                    :content="itemCol.before"
                    placement="top"
                  >
                    <div class="beforeContent">
                      {{ itemCol.before }}
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div style="overflow: hidden">
                <div class="beforeClass">After</div>
                <div class="beforeContent">
                  <el-tooltip
                    :content=" itemCol.after "
                    placement="top"
                  >
                    <div class="beforeContent">
                      {{  itemCol.after  }}
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="QuitTimeLine__content__item__list__item">-->
          <!--            <div class="contentTitle">Leave Type- Leave Reason</div>-->
          <!--            <div class="contentBeforeContentTwo">-->
          <!--              <div>-->
          <!--                <div class="beforeClass">Before</div>-->
          <!--                <el-tooltip-->
          <!--                  class="item"-->
          <!--                  effect="dark"-->
          <!--                  :content="contentItem.beforeLeaveType"-->
          <!--                  placement="right"-->
          <!--                >-->
          <!--                  <div class="beforeContent">-->
          <!--                    {{ contentItem.beforeLeaveType }}-->
          <!--                  </div>-->
          <!--                </el-tooltip>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <div class="beforeClass topH">After</div>-->
          <!--                <el-tooltip-->
          <!--                  class="item"-->
          <!--                  effect="dark"-->
          <!--                  :content="contentItem.afterLeaveType"-->
          <!--                  placement="right"-->
          <!--                >-->
          <!--                  <div class="beforeContent">-->
          <!--                    {{ contentItem.afterLeaveType }}-->
          <!--                  </div>-->
          <!--                </el-tooltip>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="QuitTimeLine__content__item__list__item">-->
          <!--            <div class="contentTitle">Remark</div>-->
          <!--            <div class="contentBeforeContentTwo">-->
          <!--              <div>-->
          <!--                <div class="beforeClass">Before</div>-->
          <!--                <el-tooltip-->
          <!--                  class="item"-->
          <!--                  effect="dark"-->
          <!--                  :content="contentItem.beforeRemark"-->
          <!--                  placement="right"-->
          <!--                >-->
          <!--                  <div class="beforeContent">-->
          <!--                    {{ contentItem.beforeRemark }}-->
          <!--                  </div>-->
          <!--                </el-tooltip>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <div class="beforeClass topH">After</div>-->
          <!--                <el-tooltip-->
          <!--                  class="item"-->
          <!--                  effect="dark"-->
          <!--                  :content="contentItem.afterRemark"-->
          <!--                  placement="right"-->
          <!--                >-->
          <!--                  <div class="beforeContent">-->
          <!--                    {{ contentItem.afterRemark }}-->
          <!--                  </div>-->
          <!--                </el-tooltip>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
  <el-empty v-else description="无编辑记录"></el-empty>
</template>

<script>
export default {
  name: 'QuitTimeLine',
  props: {
    quitTimeLineList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    isColBlock(val) {
      let block = ['Remark','Leave Type Leave Reason']
      if (block.includes(val.filedName)) {
        return 'contentBeforeContentTwo'
      } else {
        return 'contentBeforeContent'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin itemList {
  div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    .beforeClass {
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #acacac;
    }
    .topH {
      margin-top: 4px;
    }
    .beforeContent {
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      word-break: keep-all; /* 不换行 */
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
      text-overflow: ellipsis;
      color: #323435;
    }
  }
}
.QuitTimeLine {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  box-sizing: border-box;
  position: relative;
  &__lineWarp {
    height: 11px;
    box-sizing: border-box;
    overflow: hidden;
    &__line {
      height: 1px;
      margin-top: 5px;
      background: #ebeef5;
      display: flex;
      &__point {
        width: 5px;
        height: 5px;
        margin-top: -2px;
        border-radius: 50%;
        background: #bb9667;
        margin-right: 276px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__content {
    width: 100%;
    display: flex;
    &__item {
      margin-top: 11px;
      margin-right: 21px;
      &__title {
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #828282;
        margin-bottom: 8px;
      }
      &__list {
        width: 261px;
        max-height: 260px;
        background: #f4f4f4;
        border-radius: 4px;
        overflow-x: hidden;
        overflow-y: scroll;
        &__item {
          box-sizing: border-box;
          padding: 8px 9px 8px 9px;
          border-bottom: 1px solid #ffffff;

          .contentTitle {
            font-size: 12px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #323435;
            margin-bottom: 4px;
          }
          .contentBeforeContent {
            display: flex;
            @include itemList();
          }
          .contentBeforeContentTwo {
            @include itemList();
          }
        }
      }
    }
  }
}
</style>
