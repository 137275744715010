<template>
  <el-dialog
    :visible.sync="sendEmailDialogData.sendEmailDialogVisi"
    title="发送邮件"
    width="400px"
    append-to-body
    custom-class="becomeRegularWorkerDialog"
    :before-close="sendEmailDialogClose"
  >
    <div class="becomeRegularWorkerDialog__contentLM">
      是否要再次发送邮件?
    </div>
    <el-form
      ref="form"
      :label-position="'left'"
      label-width="80px"
      :model="form"
    >
      <el-form-item label="收件人">
        <el-autocomplete
          style="width: 100%"
          v-model="form.lineManager"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          placeholder="选择转收件人"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="收件人邮箱">
        <el-input v-model="form.lineManagerEmail"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="sendEmailDialogClose">取 消</el-button>
      <el-button
        type="primary"
        @click="submitEmail"
        :loading="sendEmailDialogData.emailLoading"
        >发 送</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getLineManageEmailList,
  getPositiveInfo,
  sendEmailHc
} from '@/api/employees'

export default {
  name: 'becomeRegularWorkerTableTwoDialog',
  data() {
    return {
      form: {
        lineManager: '',
        lineManagerEmail: ''
      },
      sendEmailDialogData: {
        chineseName: '',
        englishName: '',
        ids: '',
        sendEmailDialogVisi: false,
        emailLoading: false
      }
    }
  },
  methods: {
    handleSelect(item) {
      if (item) {
        this.form.lineManagerEmail = item.businessMail
      }
    },
    getFormData(id) {
      const data = {
        id
      }
      getPositiveInfo(data).then((res) => {
        if (res && res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
        }
      })
    },
    show(val) {
      if (val) {
        this.getFormData(val.candidateInformationId)
        Object.keys(this.sendEmailDialogData).forEach((item) => {
          this.sendEmailDialogData[item] = val[item]
        })
        this.sendEmailDialogData.ids = val.id
        this.sendEmailDialogData.sendEmailDialogVisi = true
      }
    },
    querySearch(queryString, cb) {
      const data = {
        lineManager: queryString
      }
      getLineManageEmailList(data).then((res) => {
        if (res && res.code === 200) {
          let transferList = res.data
          const cbList = []
          if (transferList && transferList.length) {
            transferList.forEach((item) => {
              cbList.push({
                value: item.lineManager,
                businessMail: item.businessMail
              })
            })
            cb(cbList)
          } else {
            cb([])
          }
        }
      })
    },
    sendEmailDialogClose() {
      this.sendEmailDialogData.chineseName = ''
      this.sendEmailDialogData.englishName = ''
      this.sendEmailDialogData.ids = ''
      this.sendEmailDialogData.sendEmailDialogVisi = false
    },
    submitEmail(val) {
      this.sendEmailDialogData.emailLoading = true
      const data = {
        ...this.form,
        id: this.sendEmailDialogData.ids
      }

      sendEmailHc(data).then((res) => {
        this.sendEmailDialogData.emailLoading = false
        if (res && res.code === 200) {
          this.$message.success('发送邮件成功')
          this.sendEmailDialogClose()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.becomeRegularWorkerDialog__contentLM{
  text-align: center;
}
</style>
