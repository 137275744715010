import { render, staticRenderFns } from "./postTransferDetailDialog.vue?vue&type=template&id=7f436f93&scoped=true"
import script from "./postTransferDetailDialog.vue?vue&type=script&lang=js"
export * from "./postTransferDetailDialog.vue?vue&type=script&lang=js"
import style0 from "./postTransferDetailDialog.vue?vue&type=style&index=0&id=7f436f93&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f436f93",
  null
  
)

export default component.exports