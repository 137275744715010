<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__postTransferTable"
    stripe
    ref="changeManagement__table__postTransferTableRef"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
  >
    <el-table-column
      v-for="item in postTransferTableOptions"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column width="130" label="是否带HC调岗" align="left">
      <template slot-scope="scope">
        {{ scope.row.hcIsBroughtOut ? '是' : '否' }}
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in postTransferTableOptions1"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column label="状态" width="280" align="left">
      <template slot-scope="scope">
        <postTransferTableRoleButton
          :scope="scope.row"
          @postTransferTableRoleButtonEditButton="
            postTransferTableRoleButtonEdit
          "
          @postTransferTableRoleButtonTermination="
            postTransferTableRoleButtonTermination
          "
          @postTransferTableRoleButtonReceive="
            postTransferTableRoleButtonReceive
          "
          @postTransferTableRoleButtonDetailButton="
            postTransferTableRoleButtonDetailButton
          "
          @postTransferTableRoleButtonSendCheckListEmail="
            postTransferTableRoleButtonSendCheckListEmail
          "
        />
      </template>
    </el-table-column>
    <el-table-column
      key="lastTime"
      prop="lastTime"
      label="最近操作时间"
      align="left"
      width="120"
      show-overflow-tooltip
    >
    </el-table-column>
    <HcTransferredOutDialog ref="HcTransferredOutDialogRef" @close="queryApi" />
    <noHcTransferredOutDialog
      ref="noHcTransferredOutDialogRef"
      @close="queryApi"
    ></noHcTransferredOutDialog>
    <TurnsOutInformationDialog
      ref="TurnsOutInformationDialogRef"
      @onConfirm="onPersonnelSelectionConform"
      @onStaging="onPersonnelSelectionStaging"
    />
    <postTransferDetailDialog
      ref="postTransferDetailDialogRef"
    />
  </PocTable>
</template>

<script>
import { postTransferTableOptions } from '@/views/employee/columnConfig/postTransferTableOptions'
import { getItem, setItem } from '@/utils/storage'
import HcTransferredOutDialog from '@/views/employee/dialog/HcTransferredOutDialog'
import { getEmployeesList, termination } from '@/api/employees'
import postTransferTableRoleButton from '@/views/employee/pages/components/postTransferTableRoleButton'
import noHcTransferredOutDialog from '@/views/employee/dialog/NoHcTransferredOutDialog'
import { saveTransferUser, sendCheckListEmail } from '@/api/quto'
import { postTransferTableOptions1 } from '@/views/employee/columnConfig/postTransferTableOptions'
import TurnsOutInformationDialog from '@/views/employee/dialog/TurnsOutInformationDialog.vue'
import postTransferDetailDialog from "@/views/employee/dialog/postTransferDetailDialog";
import store from '@/store'
const postTransferConst = {
  //调岗提交
  submit: 1,
  //调岗暂存
  staging: 2
}
export default {
  mixins: [$PCommon.TableMixin],
  name: 'postTransferTable',
  components: {
    postTransferTableRoleButton,
    HcTransferredOutDialog,
    noHcTransferredOutDialog,
    postTransferDetailDialog,
    TurnsOutInformationDialog
  },
  data() {
    return {
      postTransferTableOptions,
      postTransferTableOptions1,
      tableData: [],
      OutGoingId: '',
      loading: false
    }
  },
  methods: {
    //需求去掉
    postTransferTableRoleButtonSubmit(val) {
      const data = {
        hcIsBroughtOut: val.hcIsBroughtOut,
        id: val.candidateInformationId,
        saveType: '1',
        userId: ''
      }
      if (val.selectVal && val.selectVal.length) {
        data.userId = val.selectVal[0].id.toString()
      }
      saveTransferUser(data).then((res) => {
        if (res && res.code === 200) {
          this.$refs.TurnsOutInformationDialogRef.close()
          Message.success(`${message}成功`)
          this.queryApi()
        }
      })
    },
    postTransferAPI(val, flag) {
      const message =
        flag === postTransferConst.submit ? '调岗提交' : '调岗暂存'
      const data = {
        hcIsBroughtOut: val.hcIsBroughtOut,
        id: this.OutGoingId,
        saveType: flag,
        userId: ''
      }
      if (val.selectVal && val.selectVal.length) {
        data.userId = val.selectVal[0].id.toString()
      }

      saveTransferUser(data).then((res) => {
        if (res && res.code === 200) {
          this.$refs.TurnsOutInformationDialogRef.close()
          this.$message.success(`${message}成功`)
          this.queryApi()
        }
      })
    },
    onPersonnelSelectionConform(val) {
      this.postTransferAPI(val, postTransferConst.submit)
    },
    onPersonnelSelectionStaging(val) {
      this.postTransferAPI(val, postTransferConst.staging)
    },
    postTransferTableRoleButtonEdit(row) {
      setItem('tabsClickData__yc', row)
      this.OutGoingId = row.candidateInformationId
      this.$refs.TurnsOutInformationDialogRef.show({
        id: row.candidateInformationId
      })
    },
    postTransferTableRoleButtonTermination(val) {
      const data = {
        id: val.candidateInformationId
      }
      termination(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('终止成功')
          this.queryApi()
        }
      })
    },
    postTransferTableRoleButtonReceive(val) {
      const data = {
        id: val.candidateInformationId
      }
      if (val.hcIsBroughtOut) {
        this.$refs.HcTransferredOutDialogRef.show(data)
      } else {
        this.$refs.noHcTransferredOutDialogRef.show(data)
      }
    },
    postTransferTableRoleButtonDetailButton(val){
      const data = {
        workflowId: val.workflowId,
        hcIsBroughtOut:val.hcIsBroughtOut,
      }
      this.$refs.postTransferDetailDialogRef.show(data)
    },
    /**
     * 发送 checkList 邮件
     */
    postTransferTableRoleButtonSendCheckListEmail(val) {
      console.log(1111111, val)
      sendCheckListEmail({ workflowId: val.workflowId }).then((res) => {
        if (res && res.code === 200) {
          Message.success(`${message}成功`)
        }
      })

    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__postTransferTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
