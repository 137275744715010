<script>
import { defineComponent, ref } from 'vue'
import { getItem } from '@/utils/storage'
export default defineComponent({
  props: {
    scope: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    let roleButton = ref([])
    let scope = props.scope
    let userRole = getItem('userRole')
    const isHrbpLStatus = (val) => {
      if (userRole) {
        return userRole.includes(val)
      } else {
        return false
      }
    }
    let status = ref(scope.status)
    const pushRoleButton = (val) => {
      roleButton.value = []
      roleButton.value.push(...val)
    }
    const buttonRoleFunc = () => {
      //是否在流程内
      if (status.value) {
        if (isHrbpLStatus('HRBP Leader') && isHrbpLStatus('HRBP')) {
          switch (status.value) {
            // 修改1455--12-26  下午 18：46  看如下图，发起晋升，暂存，待提交  的记录，经办人是当前登录人，记录没有编辑按钮。
            case '已发起，待提交':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonEditButton'])
              break
            case '已提交，待提交':
              pushRoleButton(['promotionTableRoleButtonTermination'])
              break
            case '已提交，待审核':
              pushRoleButton([
                'promotionTableDetailButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonAudit'
              ])
              break
            case '已驳回，待提交':
              pushRoleButton([
                'promotionTableDetailButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonEditButton'
              ])
              break
            case '表单已审核，待上传线下审批附件':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '线下审核通过，待通知':
              pushRoleButton([
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonAudit'
              ])
              break
            case '已通知，待生效':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已晋升':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '晋升已终止':
              pushRoleButton(['promotionTableDetailButton'])
              break
            default:
              pushRoleButton([])
          }
        } else if (isHrbpLStatus('HRBP Leader')) {
          switch (status.value) {
            case '已发起，待提交':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已提交，待提交':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已提交，待审核':
              pushRoleButton([
                'promotionTableDetailButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonAudit'
              ])
              break
            case '已驳回，待提交':
              pushRoleButton([
                'promotionTableDetailButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonEditButton'
              ])
              break
            case '表单已审核，待上传线下审批附件':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '线下审核通过，待通知':
              pushRoleButton([
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonAudit'
              ])
              break
            case '已通知，待生效':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已晋升':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '晋升已终止':
              pushRoleButton(['promotionTableDetailButton'])
              break
            default:
              pushRoleButton([])
          }
        } else if (isHrbpLStatus('HRBP')) {
          switch (status.value) {
            case '已发起，待提交':
              pushRoleButton([
                'promotionTableRoleButtonEditButton',
                'promotionTableRoleButtonTermination'
              ])
              break
            case '已提交，待审核':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已驳回，待提交':
              pushRoleButton([
                'promotionTableRoleButtonEditButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonRejectReason'
              ])
              break
            case '表单已审核，待上传线下审批附件':
              pushRoleButton([
                'promotionTableDetailButton',
                'promotionTableRoleButtonTermination',
                'promotionTableRoleButtonApprovalAttachments'
              ])
              break
            case '线下审核通过，待通知':
              pushRoleButton(['promotionTableRoleButtonTermination'])
              break
            case '已通知，待生效':
              pushRoleButton(['promotionTableDetailButton','promotionTableRoleButtonTermination'])
              break
            case '已晋升':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '晋升已终止':
              pushRoleButton(['promotionTableDetailButton'])
              break
            default:
              pushRoleButton([])
          }
        }else{
          switch (status.value) {
            // 修改1455--12-26  下午 18：46  看如下图，发起晋升，暂存，待提交  的记录，经办人是当前登录人，记录没有编辑按钮。
            case '已发起，待提交':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '已提交，待审核':
              pushRoleButton([
                'promotionTableDetailButton'
              ])
              break
            case '已驳回，待提交':
              pushRoleButton([
                'promotionTableDetailButton'
              ])
              break
            case '表单已审核，待上传线下审批附件':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '已通知，待生效':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '已晋升':
              pushRoleButton(['promotionTableDetailButton'])
              break
            case '晋升已终止':
              pushRoleButton(['promotionTableDetailButton'])
              break
            default:
              pushRoleButton([])
          }
        }
      } else {
        roleButton.value = []
      }
    }

    buttonRoleFunc()

    // pushRoleButton([
    //   'promotionTableRoleButtonEditButton',
    //   'promotionTableRoleButtonSubmitButton',
    //   'promotionTableRoleButtonTermination',
    //   'promotionTableRoleButtonAudit',
    //   'promotionTableRoleButtonApprovalAttachments'
    // ])
    return {
      roleButton,
      status
    }
  },
  render(createElement, context) {
    const emit = (val) => {
      this.$emit(val, this.scope)
    }

    const getRoleButton = (type, name) => {
      const roleButton = {
        promotionTableDetailButton: (
          <el-button
            class="roleButton"
            type={type}
            role="promotionDetail"
            v-permission
            size="mini"
            onclick={() => {
              emit('promotionTableDetailButton')
            }}
          >
            查看
          </el-button>
        ),
        promotionTableRoleButtonEditButton: (
          <el-button
            class="roleButton"
            type={type}
            role="promotionEdit"
            v-permission
            size="mini"
            onclick={() => {
              emit('promotionTableRoleButtonEditButton')
            }}
          >
            编辑
          </el-button>
        ),
        promotionTableRoleButtonTermination: (
          <el-button
            class="roleButton"
            type={type}
            role="promotionTermination"
            v-permission
            size="mini"
            onclick={() => {
              emit('promotionTableRoleButtonTermination')
            }}
          >
            终止
          </el-button>
        ),
        promotionTableRoleButtonAudit: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="promotionAudit"
            v-permission
            onclick={() => {
              emit('promotionTableRoleButtonAudit')
            }}
          >
            审核
          </el-button>
        ),
        promotionTableRoleButtonApprovalAttachments: (
          <el-button
            class="roleButton"
            type={type}
            role="promotionUpload"
            v-permission
            size="mini"
            onclick={() => {
              emit('promotionTableRoleButtonApprovalAttachments')
            }}
          >
            上传线下审批附件
          </el-button>
        ),
        promotionTableRoleButtonRejectReason: (
          <el-button
            class="roleButton"
            type={type}
            role="promotionUpload"
            v-permission
            size="mini"
            onclick={() => {
              emit('promotionTableRoleButtonRejectReason')
            }}
          >
            驳回理由
          </el-button>
        )
      }
      return roleButton[name]
    }
    const mapButton = () => {
      if (this.roleButton.length) {
        if (this.roleButton.length === 1) {
          return <div>{getRoleButton('', [this.roleButton[0]])}</div>
        } else if (this.roleButton.length === 2) {
          return (
            <div>
              {getRoleButton('', [this.roleButton[0]])}
              {getRoleButton('', [this.roleButton[1]])}
            </div>
          )
        } else if (this.roleButton.length > 2) {
          return (
            <div>
              {getRoleButton('', this.roleButton[0])}
              <el-dropdown triger="click">
                <el-button class="dropdownButton">
                  <svg-icon
                    icon="ellipsis"
                    class="tablePointSvgClass"
                    title="更多"
                  />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  {this.roleButton.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <el-dropdown-item>
                          {getRoleButton('text', item)}
                        </el-dropdown-item>
                      )
                    } else {
                      return null
                    }
                  })}
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          )
        }
      } else {
        return null
      }
    }
    const mapStatus = () => {
      let status = this.status
      if (status) {
        let splitStatus = status.split('，')
        if (splitStatus && splitStatus.length) {
          const isSplit = () => {
            if (splitStatus[1]) {
              return (
                <span>
                  <span class="circle"></span>
                  <span>{splitStatus[1]}</span>
                </span>
              )
            } else {
              return null
            }
          }
          return (
            <div class="statusText">
              <span>{splitStatus[0]}</span>
              {isSplit()}
            </div>
          )
        }
      }
    }
    return (
      <div class="promotionTableRoleButton">
        {mapStatus()} {mapButton()}
      </div>
    )
  }
})
</script>

<style lang="scss" scoped>
.promotionTableRoleButton {
  display: flex;
  div {
    &:first-child {
      margin-right: 14px;
    }
    .el-button {
      transform: scale(0.8);
      margin-right: -2px !important;
    }
    .dropdownButton {
      transform: scale(0.75);
    }
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e6a23c;
    margin-left: 8px;
    margin-right: 4px;
  }
  .statusText {
    display: inline-block;
    line-height: 30px;
    overflow: hidden;
  }
}
</style>
