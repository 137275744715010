<template>
  <div class="allTableRoleButton">
    <component
      :is="buttonComponent"
      :scope="scope"
      @becomeRegularWorkerRoleButtonPositiveSendEmail="
        becomeRegularWorkerRoleButtonPositiveSendEmail
      "
      @becomeRegularWorkerRoleButtonAudit="becomeRegularWorkerRoleButtonAudit"
      @quitTableRoleButtonChat="quitTableRoleButtonChat"
      @quitTableRoleButtonEditButton="quitTableRoleButtonEditButton"
      @quitTableRoleButtonTermination="quitTableRoleButtonTermination"
      @quitTableRoleButtonSendQuitEmail="mail"
      @postTransferTableRoleButtonEditButton="postTransferTableRoleButtonEdit"
      @postTransferTableRoleButtonTermination="
        postTransferTableRoleButtonTermination
      "
      @quitTableRoleButtonGenerateQuota="quota"
      @quitTableRoleButtonResignationCertificate="resignationCertificate"

      @postTransferTableRoleButtonReceive="postTransferTableRoleButtonReceive"
      @promotionTableRoleButtonEditButton="promotionTableRoleButtonEditButton"
      @promotionTableRoleButtonAudit="promotionTableRoleButtonAudit"
      @promotionTableRoleButtonTermination="promotionTableRoleButtonTermination"
      @promotionTableRoleButtonApprovalAttachments="approvalAttachments"
      @renewTableRoleButtonSendEmail="renewTableRoleButtonSendEmail"
      @renewTableRoleButtonAudit="renewTableRoleButtonAudit"
      @renewTableRoleButtonReceive="renewTableRoleButtonReceive"
      @renewTableRoleButtonCompletionRenewal="
        renewTableRoleButtonCompletionRenewal
      "
      @renewTableRoleButtonEmployeeNotification="
        renewTableRoleButtonEmployeeNotification
      "
      @promotionTableDetailButton="promotionTableDetail"
      @postTransferTableRoleButtonDetailButton="postTransferTableRoleButtonDetailButton"
      @becomeRegularWorkerRoleButtonTerminate="terminate"
    ></component>
    <quitDialog ref="quitDialogRef" @close="close" />
    <quitChatDialog ref="quitChatDialogRef" @close="close" />
    <hcTransferredOutDialog ref="HcTransferredOutDialogRef" @close="close" />
    <noHcTransferredOutDialog
      ref="noHcTransferredOutDialogRef"
      @close="close"
    ></noHcTransferredOutDialog>
    <promotionTableAuditDialog
      ref="promotionTableAuditDialogRef"
      @back="close"
    />
    <becomeRegularWorkerTableTwoDialog
      ref="becomeRegularWorkerTableTwoDialogRef"
      @close="close"
    />
    <PositiveReviewDialog ref="PositiveReviewDialogRef" @close="close" />
    <quitTableRoleButtonSendQuitEmailDialog
      ref="quitTableRoleButtonSendQuitEmailDialogRef"
      @close="close"
    />
    <promotionAuditDialog ref="promotionAuditDialogRef" @close="close" />
    <renewTableDialog ref="renewTableDialogRef" @close="close" />
    <renewAuditDialog ref="renewAuditDialogRef" @close="close" />
    <quitTableRoleButtonSendQuitEmailDialog
      ref="quitTableRoleButtonSendQuitEmailDialogRef"
      @close="close"
    />
    <renewTableRoleButtonEmployeeNotificationDialog
      ref="renewTableRoleButtonEmployeeNotificationDialogRef"
      @close="close"
    />
    <promotionAttachmentsAuditDialog
      ref="promotionAttachmentsAuditDialogRef"
      @close="close"
    />
    <promotionAttachmentsAuditDialog
      ref="promotionAttachmentsAuditDialogRef"
      @close="close"
    />
    <TurnsOutInformationDialog
      ref="TurnsOutInformationDialogRef"
      @onConfirm="onPersonnelSelectionConform"
      @onStaging="onPersonnelSelectionStaging"
    />
    <mailDialog ref="mailDialogRef" @close="close" />
    <postTransferDetailDialog
      ref="postTransferDetailDialogRef"
    />
    <promotionDetailDialog
      ref="promotionDetailDialogRef"
    />
    <terminateReviewDialog ref="terminateReviewDialogRef" @close="close" />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import postTransferTableRoleButton from '@/views/employee/pages/components/postTransferTableRoleButton'
import TurnsOutInformationDialog from '@/views/employee/dialog/TurnsOutInformationDialog.vue'
import promotionTableRoleButton from '@/views/employee/pages/components/promotionTableRoleButton'
import quitTableRoleButton from '@/views/employee/pages/components/quitTableRoleButton'
import becomeRegularWorkerRoleButton from '@/views/employee/pages/components/becomeRegularWorkerRoleButton'
import quitDialog from '@/views/employee/dialog/quitDialog'
import quitChatDialog from '@/views/employee/dialog/quitChatDialog'
import noHcTransferredOutDialog from '@/views/employee/dialog/NoHcTransferredOutDialog'
import hcTransferredOutDialog from '@/views/employee/dialog/HcTransferredOutDialog'
import promotionTableAuditDialog from '@/views/employee/dialog/promotionTableAuditDialog'
import becomeRegularWorkerTableTwoDialog from '@/views/employee/dialog/becomeRegularWorkerTableTwoDialog'
import PositiveReviewDialog from '@/views/employee/dialog/PositiveReviewDialog'
import quitTableRoleButtonSendQuitEmailDialog from '@/views/employee/dialog/quitTableRoleButtonSendQuitEmailDialog'
import promotionAuditDialog from '@/views/employee/dialog/promotionAuditDialog'
import renewTableRoleButton from '@/views/employee/pages/components/renewTableRoleButton'
import renewTableDialog from '@/views/employee/dialog/renewTableDialog'
import renewAuditDialog from '@/views/employee/dialog/renewAuditDialog'
import renewTableRoleButtonEmployeeNotificationDialog from '@/views/employee/dialog/renewTableRoleButtonEmployeeNotificationDialog'
import promotionAttachmentsAuditDialog from '@/views/employee/dialog/promotionAttachmentsAuditDialog'
import emptyRoleButton from '@/views/employee/pages/components/emptyRoleButton.vue'
import mailDialog from '@/views/employee/dialog/mailDialog.vue'
import postTransferDetailDialog from "@/views/employee/dialog/postTransferDetailDialog";
import promotionDetailDialog from "@/views/employee/dialog/promotionDetailDialog";

import terminateReviewDialog from '@/views/employee/dialog//terminateReviewDialog.vue';

import {
  staffPromotionTerminate,
  termination,
  terminationQuit,
  generateQuota
} from '@/api/employees'
import { setItem } from '@/utils/storage'
import { saveTransferUser } from '@/api/quto'
import { downloadStaff } from "@/views/employee/Hooks/downloadStaff";


export default defineComponent({
  components: {
    postTransferTableRoleButton,
    promotionTableRoleButton,
    quitTableRoleButton,
    becomeRegularWorkerRoleButton,
    renewTableRoleButton,
    quitDialog,
    quitChatDialog,
    noHcTransferredOutDialog,
    hcTransferredOutDialog,
    promotionTableAuditDialog,
    becomeRegularWorkerTableTwoDialog,
    PositiveReviewDialog,
    quitTableRoleButtonSendQuitEmailDialog,
    promotionAuditDialog,
    renewTableDialog,
    renewAuditDialog,
    renewTableRoleButtonEmployeeNotificationDialog,
    promotionAttachmentsAuditDialog,
    emptyRoleButton,
    TurnsOutInformationDialog,
    postTransferDetailDialog,
    promotionDetailDialog,
    mailDialog,
    terminateReviewDialog
  },
  props: {
    scope: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      buttonComponent: '',
      OutGoingId: '',
      postTransferConst: {
        //调岗提交
        submit: 1,
        //调岗暂存
        staging: 2
      }
    }
  },
  created() {
    let componentList = [
      {
        type: '离职中',
        component: 'quitTableRoleButton'
      },
      {
        type: '调岗中',
        component: 'postTransferTableRoleButton'
      },
      {
        type: '晋升中',
        component: 'promotionTableRoleButton'
      },
      {
        type: '转正中',
        component: 'becomeRegularWorkerRoleButton'
      },
      {
        type: '续签中',
        component: 'renewTableRoleButton'
      }
    ]
    // 问题标注：后端返回的flow为空了
    let flow = this.scope.flow
    if (flow) {
      let com = componentList.find((item) => item.type === flow)
      if (com) {
        this.buttonComponent = com.component
      } else {
        this.buttonComponent = null
      }
    } else {
      if(['调岗已终止','已转入'].includes(this.scope.status)){
        let com = componentList.find((item) => item.type === '调岗中')
        this.buttonComponent = com.component
      }else if(['已晋升','晋升已终止'].includes(this.scope.status)){
        let com = componentList.find((item) => item.type === '晋升中')
        this.buttonComponent = com.component
      }else if(['已离职'].includes(this.scope.status)){
        let com = componentList.find((item) => item.type === '离职中')
        this.buttonComponent = com.component
      }
      else {
        // 流程结束 flow 返回空
        this.buttonComponent = 'emptyRoleButton'
      }
    }
  },
  methods: {
    resignationCertificate(val){
      let params = {
        selectVal : [val]
      }
      downloadStaff({ flag: 3 }, params)
    },
    quota(row) {
      // this.$dayjs.extend(isBetween)
      // let endDate = this.$dayjs(val.joinDate).add(val.probationPeriodMonth,'month').format('YYYY-MM-DD');
      // let isDuring = this.$dayjs(row.quitDate).isBetween(row.joinDate, endDate, 'day', '[)');
      // if(isDuring) {

      // }
      let params = {
        id: row.candidateInformationId
      }
      generateQuota(params).then(res => {
        if (res && res.code === 200) {
          this.$message.success('操作成功')
        }
      })
    },
    terminate(row) {
      console.log('转正终止按钮', row)
      this.$refs.terminateReviewDialogRef.show(row)
    },

    promotionTableDetail(val){
      console.log("promotionTableDetail");
      if (val) {
        this.$refs.promotionDetailDialogRef.show(val)
      }
    },
    postTransferTableRoleButtonDetailButton(val){
      console.log("postTransferTableRoleButtonDetailButton");
      const data = {
        workflowId: val.workflowId,
        hcIsBroughtOut:val.hcIsBroughtOut,
      }
      this.$refs.postTransferDetailDialogRef.show(data)
    },
    close() {
      this.$emit('close')
    },
    quitTableRoleButtonTermination(val) {
      if (val) {
        const data = {
          title: '终止流程',
          content: `是否要终止该员工的离职流程?`,
          id: val.candidateInformationId,
          flag: 'receive'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    quitTableRoleButtonSendQuitEmail(val) {
      if (val) {
        const data = {
          title: '发送离职通知及离职清单',
          content: `是否要向${val.englishName}发送离职通知及离职手续清单?`,
          id: val.candidateInformationId,
          flag: 'quit'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    quitTableRoleButtonChat(val) {
      if (val) {
        this.$refs.quitChatDialogRef.show(val)
      }
    },
    quitTableRoleButtonEditButton(val) {
      if (val) {
        val.flag = 'quit'
        this.$refs.quitDialogRef.show(val)
      }
    },
    mail(val) {
      if (val) {
        console.log('val', val)
        this.$refs.mailDialogRef.show(val)
      }
    },
    onPersonnelSelectionConform(val) {
      this.postTransferAPI(val, this.postTransferConst.submit)
    },
    onPersonnelSelectionStaging(val) {
      this.postTransferAPI(val, this.postTransferConst.staging)
    },
    postTransferAPI(val, flag) {
      const message =
        flag === this.postTransferConst.submit ? '调岗提交' : '调岗暂存'
      const data = {
        hcIsBroughtOut: val.hcIsBroughtOut,
        id: this.OutGoingId,
        saveType: flag,
        userId: ''
      }
      if (val.selectVal && val.selectVal.length) {
        data.userId = val.selectVal[0].id.toString()
      }
      saveTransferUser(data).then((res) => {
        if (res && res.code === 200) {
          this.$refs.TurnsOutInformationDialogRef.close()
          this.$message.success(`${message}成功`)
          this.close()
        }
      })
    },
    postTransferTableRoleButtonEdit(row) {
      this.OutGoingId = row.candidateInformationId
      this.$refs.TurnsOutInformationDialogRef.show({
        id: row.candidateInformationId
      })
    },
    postTransferTableRoleButtonTermination(val) {
      const data = {
        id: val.candidateInformationId
      }
      termination(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('终止成功')
          this.close()
        }
      })
    },
    postTransferTableRoleButtonReceive(val) {
      console.log('val', val)
      // 问题标注：后端返回的hcIsBroughtOut为null
      const data = {
        id: val.candidateInformationId
      }
      if (val.hcIsBroughtOut) {
        this.$refs.HcTransferredOutDialogRef.show(data)
      } else {
        this.$refs.noHcTransferredOutDialogRef.show(data)
      }
    },

    promotionTableRoleButtonEditButton(row) {
      row.jumpFlag = 'edit'
      row.flag = '1'
      this.$refs.promotionTableAuditDialogRef.show(row)
    },
    promotionTableRoleButtonAudit(val) {
      if (val) {
        this.$refs.promotionAuditDialogRef.show(val)
      }
    },
    promotionTableRoleButtonTermination(val) {
      const data = {
        id: val.id
      }
      staffPromotionTerminate(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('终止成功')
          this.close()
        }
      })
    },
    approvalAttachments(val) {
      if (val) {
        this.$refs.promotionAttachmentsAuditDialogRef.show(val)
      }
    },
    becomeRegularWorkerRoleButtonPositiveSendEmail(val) {
      this.$refs.becomeRegularWorkerTableTwoDialogRef.show(val)
    },
    becomeRegularWorkerRoleButtonAudit(val) {
      this.$refs.PositiveReviewDialogRef.show(val)
    },
    renewTableRoleButtonEmployeeNotification(val) {
      console.log(121212)
      if (val) {
        val.title = '发起员工通知'
        val.content = '是否要通知所选的员工进行线下合同流程?'
        this.$refs.renewTableRoleButtonEmployeeNotificationDialogRef.show(val)
      }
    },
    renewTableRoleButtonAudit(val) {
      this.$refs.renewAuditDialogRef.show(val)
    },
    renewTableRoleButtonSendEmail(row) {
      row.title = '发送邮件'
      row.flag = 'sendEmail'
      row.content = '是否要向该员工的LM再次发送合同续签确认?'
      this.$refs.renewTableDialogRef.show(row)
    },
    chatOnTheJobItem(row) {
      setItem('tabsClickData__yc', row)
      const obj = JSON.stringify({
        jumpFlag: 'goDetail',
        candidateInformationId: row.candidateInformationId,
        idNumber: row.idNumber
      })
      this.$router.push({
        path: '/employees/employeesItemDetails',
        query: {
          jumpParams: obj
        }
      })
    },
    renewTableRoleButtonReceive(val) {
      if (val) {
        const data = {
          title: '提示',
          content: `是否要终止该员工的续签流程?`,
          id: val.candidateInformationId,
          flag: 'renewTableRoleButtonReceive'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    renewTableRoleButtonCompletionRenewal(val) {
      if (val) {
        const data = {
          title: '提示',
          content: `是否确认该员工已经完成合同续签?`,
          id: val.candidateInformationId,
          flag: 'renewTableRoleButtonCompletionRenewal'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.allTableRoleButton {
  display: flex;
  div {
    &:first-child {
      margin-right: 14px;
    }
    .el-button {
      transform: scale(0.8);
      margin-right: -2px !important;
    }
    .dropdownButton {
      transform: scale(0.75);
    }
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e6a23c;
    margin-left: 8px;
    margin-right: 4px;
  }
  .statusText {
    line-height: 30px;
  }
}
</style>
