<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__allTable"
    stripe
    ref="changeManagement__table__allTableRef"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
  >
    <el-table-column
      key="englishName"
      prop="englishName"
      label="English Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.englishName }}</span
        >
      </template>
    </el-table-column>

    <el-table-column
      key="chineseName"
      prop="chineseName"
      label="Chinese Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.chineseName }}</span
        >
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in allTableColumn"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column label="状态" fixed="right" width="380" align="left">
      <template slot-scope="scope">
        <allTableRoleButton
          ref="allTableRoleButtonRef"
          :scope="scope.row"
          @close="queryApi"
        />
      </template>
    </el-table-column>
  </PocTable>
</template>

<script>
import { allTableColumn } from '@/views/employee/columnConfig/allTableColumn'
import { getItem, setItem } from '@/utils/storage'
import {
  getEmployeesList,
  staffPromotionTerminate,
  termination
} from '@/api/employees'
import store from "@/store";
import allTableRoleButton from '@/views/employee/pages/components/allTableRoleButton'
import md5 from "md5";

export default {
  mixins: [$PCommon.TableMixin],
  name: 'allTable',
  components: { allTableRoleButton },
  data() {
    return {
      allTableColumn,
      tableData: [],
      loading: false
    }
  },
  methods: {
    termination(val) {
      const data = {
        a: {
          ch: '调岗中',
          api: termination
        },
        b: {
          ch: '晋升中',
          api: staffPromotionTerminate
        }
      }
      const apiData = {
        id: val.candidateInformationId
      }
      Object.keys(data).forEach((item) => {
        if (data[item].ch === val.flow) {
          data[item].api(apiData).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('终止成功')
              this.queryApi()
            }
          })
        }
      })
    },
    chatOnTheJobItem(row) {
      setItem('tabsClickData__yc', row)
      const obj = JSON.stringify({
        jumpFlag: 'goDetail',
        candidateInformationId: row.candidateInformationId,
        idNumber: row.idNumber,
        userName : md5(getItem("userName"))
      })
      let page = this.$router.resolve({
        path: '/employees/employeesItemDetails',
        query: {
          jumpParams: obj
        },
      })
      window.open(page.href, '_blank')
    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__postTransferTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.changeManagement__table__allTable {
  :deep(.el-table) {
    .cell {
      white-space: pre-line;
      text-align: left;
      line-height: 13px;
    }
  }
}
</style>
