<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="postTransferDetailDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>调岗信息-{{hcIsBroughtOut?'带':'不带'}}HC</div>
    </template>
    <div class="drawer_content">
      <div v-if="beforeEffectiveDate || afterEffectiveDate" class="effective-date">
        {{ afterEffectiveDate || beforeEffectiveDate }}  <span>生效日期</span>
      </div>
      <descriptions :descriptionData="listData" />
      <PocTable
        v-loading="loading"
        :data="tableData"
        :max-height="maxHeight"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        hidepage
        stripe
      >
        <el-table-column
          v-for="item in tableColumn"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="left"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </div>


  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import { mergeDesDataOfPort } from "@/utils/util";

export default {
  name: "postTransferDetailDialog",
  mixins: [$PCommon.TableMixin],
  components: {
    descriptions,
  },
  data(){
    const h = document.getElementsByTagName('body')[0].clientHeight - 260 + 'px'
    return {
      maxHeight:h,
      drawer:false,
      loading:false,
      workflowId:'',
      hcIsBroughtOut:'',
      tableData:[],
      beforeEffectiveDate:'',
      afterEffectiveDate:'',
      tableColumn:[
        {
          key: 'attribute',
          name: '',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'before',
          name: '调岗前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'after',
          name: '调岗后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
      ],
      listData: [
        {
          title: '基础信息',
          column: 5,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 8
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: '',
              lg: 8
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: '',
              lg: 8
            },
            {
              label: '原HRBP',
              key: 'oldHrbpName',
              value: '',
              lg: 8
            },
            {
              label: '新HRBP',
              key: 'newHrbpName',
              value: '',
              lg: 8
            },
          ]
        }
      ],
    }
  },
  methods:{
    getData(){
      let params = {
        workflowId :this.workflowId
      }
      this.$Api.staff.detail(params).then(({code,data})=>{
        if(code === 200 && data){
          this.beforeEffectiveDate = data.beforeEffectiveDate
          this.afterEffectiveDate = data.afterEffectiveDate
          this.initTableData(data)
          mergeDesDataOfPort(this.listData, data)
        }
      })
    },
    initTableData(data){
      this.tableData = []
      this.tableData.push({attribute:'HFM Code', after:data.afterHfmCode,before:data.beforeHfmCode,})
      this.tableData.push({attribute:'Legal Entity', after:data.afterLegalEntity,before:data.beforeLegalEntity,})
      this.tableData.push({attribute:'Level 2', after:data.afterLevel2,before:data.beforeLevel2,})
      this.tableData.push({attribute:'Level 3', after:data.afterLevel3,before:data.beforeLevel3,})
      this.tableData.push({attribute:'Client', after:data.afterClient,before:data.beforeClient,})
      this.tableData.push({attribute:'Business Unit', after:data.afterBusinessUnit,before:data.beforeBusinessUnit,})
      this.tableData.push({attribute:'Department', after:data.afterDepartment,before:data.beforeDepartment,})
      this.tableData.push({attribute:'Business Email', after:data.afterBusinessEmail,before:data.beforeBusinessEmail,})
      this.tableData.push({attribute:'English Title', after:data.afterEnglishTitle,before:data.beforeEnglishTitle,})
      this.tableData.push({attribute:'CN Title', after:data.afterCnTitle,before:data.beforeCnTitle,})
      this.tableData.push({attribute:'Job Grading', after:data.afterJobGrading,before:data.beforeJobGrading,})
      this.tableData.push({attribute:'Work Schedule', after:data.afterWorkSchedules,before:data.beforeWorkSchedules,})
      this.tableData.push({attribute:'Parent Function', after:data.afterParentFunction,before:data.beforeParentFunction,})
      this.tableData.push({attribute:'Child Function', after:data.afterChildFunction,before:data.beforeChildFunction,})
      // this.tableData.push({attribute:'Labor Code', after:data.afterLaborCode,before:data.beforeLaborCode,})
      this.tableData.push({attribute:'Location', after:data.afterLocation,before:data.beforeLocation,})
      this.tableData.push({attribute:'Office', after:data.afterOffice,before:data.beforeOffice,})
      this.tableData.push({attribute:'Lion ID of LM', after:data.afterLineIdManager,before:data.beforeLineIdManager,})
      this.tableData.push({attribute:'Name of LM', after:data.afterLineManagerName,before:data.beforeLineManagerName,})
      // this.tableData.push({attribute:'LM Email', after:data.afterLineManagerEmail,before:data.beforeLineManagerEmail,})
      // this.tableData.push({attribute:'Name of Vega LM', after:data.afterNameOfVegaLm,before:data.beforeNameOfVegaLm,})
      // this.tableData.push({attribute:'Lion ID of LM Vega', after:data.afterLionIdOfLmVega,before:data.beforeLionIdOfLmVega,})
      this.tableData.push({attribute:'Performance Manager', after:data.afterPerformanceManagerName,before:data.beforePerformanceManagerName,})
      this.tableData.push({attribute:'Lion ID of Performance Manager', after:data.afterLionIdOfPerformanceManager,before:data.beforeLionIdOfPerformanceManager,})
      this.tableData.push({attribute:'CEO', after:data.newCeoName,before:data.beforeCeoName,})
      this.tableData.push({attribute:'New Contract', after:data.afterNewContract,before:data.beforeNewContract,})
      this.tableData.push({attribute:'New Contract Period', after:data.newContractPeriod,before:data.beforeContractPeriod,})
      this.tableData.push({attribute:'New Notice Period(Months)', after:data.afterNoticePeriod,before:data.beforeNoticePeriod,})
      this.tableData.push({attribute:'Contract Start Date', after:data.afterContractStartDate,before:data.beforeContractStartDate,})
      this.tableData.push({attribute:'Contract Due Date', after:data.afterContractDueDate,before:data.beforeContractDueDate,})
      this.tableData.push({attribute:'Effective Date', after:data.afterEffectiveDate,before:data.beforeEffectiveDate,})
    },
    show(val) {
      if (val) {
        this.workflowId = val.workflowId
        this.hcIsBroughtOut = val.hcIsBroughtOut
        if (this.workflowId) {
          this.getData()
          this.drawer = true
        } else {
          this.$message.warning('缺失id')
        }
      }
    },
    close() {
      this.workflowId = ''
      this.tableData = []
      this.drawer = false
    },
  }
};
</script>

<style lang="scss" scoped>
.postTransferDetailDialog {
  .effective-date{
    position: relative;
    top: 22px;
    left: 85px;
    font-size: 14px;
    font-weight: 600;
    width: 162px;
    height: 31px;
    background: #f4f4f4;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px 5px 12px 16px;
    span{
      font-size: 12px;
    }
  }
  .el-drawer__header {
    margin-bottom: 16px !important;
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 80px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  //padding-top: 30px;
  //padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
:deep(.el-drawer__header) {
  margin-bottom: 0;
}
</style>
