<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__quitTable"
    stripe
    ref="changeManagement__table__quitTableRef"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
  >
    <el-table-column
      key="englishName"
      prop="englishName"
      label="English Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.englishName }}</span
        >
      </template>
    </el-table-column>

    <el-table-column
      key="chineseName"
      prop="chineseName"
      label="Chinese Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.chineseName }}</span
        >
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in quitTableColumn"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column label="状态" width="400" align="left">
      <template slot-scope="scope">
        <quitTableRoleButton
          :scope="scope.row"
          @quitTableRoleButtonMail="mail"
          @quitTableRoleButtonChat="chat"
          @quitTableRoleButtonEditButton="editButton"
          @quitTableRoleButtonTermination="termination"
          @quitTableRoleButtonSendQuitEmail="mail"
          @quitTableRoleButtonGenerateQuota="quota"
          @quitTableRoleButtonResignationCertificate="resignationCertificate"
        />
      </template>
    </el-table-column>
    <quitChatDialog ref="quitChatDialogRef" />
    <quitDialog ref="quitDialogRef" @close="queryApi" />
    <mailDialog ref="mailDialogRef" @close="queryApi" />
    <quitTableRoleButtonSendQuitEmailDialog
      ref="quitTableRoleButtonSendQuitEmailDialogRef"
      @close="queryApi"
    />
  </PocTable>
</template>

<script>
import { getItem, setItem } from '@/utils/storage'
import { getEmployeesList, terminationQuit, generateQuota } from '@/api/employees'
import { quitTableColumn } from '@/views/employee/columnConfig/quitTableColumn'
import quitTableRoleButton from '@/views/employee/pages/components/quitTableRoleButton'
import quitChatDialog from '@/views/employee/dialog/quitChatDialog'
import quitDialog from '@/views/employee/dialog/quitDialog'
import mailDialog from '@/views/employee/dialog/mailDialog'
import quitTableRoleButtonSendQuitEmailDialog from '@/views/employee/dialog/quitTableRoleButtonSendQuitEmailDialog.vue'
import store from '@/store'
import md5 from "md5";
import { downloadStaff } from "@/views/employee/Hooks/downloadStaff";

export default {
  mixins: [$PCommon.TableMixin],
  name: 'allTable',
  components: {
    quitTableRoleButton,
    quitChatDialog,
    quitDialog,
    mailDialog,
    quitTableRoleButtonSendQuitEmailDialog
  },
  data() {
    return {
      quitTableColumn,
      tableData: [],
      loading: false
    }
  },
  methods: {
    resignationCertificate(val){
      let params = {
        selectVal : [val]
      }
      downloadStaff({ flag: 3 }, params)
    },
    quota(val) {
      // let endDate = this.$dayjs(val.joinDate).add(val.probationPeriodMonth,'month').format('YYYY-MM-DD');
      // let isDuring = this.$dayjs(val.quitDate).isBetween(val.joinDate, endDate, 'day', '[)');
      // console.log(val, '点击生成配额-离职', '开始-期间-结束',val.joinDate+'---'+val.quitDate+'---'+endDate, isDuring, )
      // if(isDuring) {

      // }
      let params = {
        id: val.candidateInformationId
      }
      generateQuota(params).then(res => {
        if (res && res.code === 200) {
          this.$message.success('操作成功')
        }
      })
    },
    mail(val) {
      if (val) {
        console.log('val', val)
        this.$refs.mailDialogRef.show(val)
      }
    },
    chatOnTheJobItem(row) {
      setItem('tabsClickData__yc', row)
      const obj = JSON.stringify({
        jumpFlag: 'goDetail',
        candidateInformationId: row.candidateInformationId,
        idNumber: row.idNumber,
        userName : md5(getItem("userName"))
      })
      let page = this.$router.resolve({
        path: '/employees/employeesItemDetails',
        query: {
          jumpParams: obj
        },
      })
      window.open(page.href, '_blank')
    },
    termination(val) {
      if (val) {
        const data = {
          title: '终止流程',
          content: `是否要终止该员工的离职流程?`,
          id: val.candidateInformationId,
          flag: 'receive'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    quitTableRoleButtonSendQuitEmail(val) {
      if (val) {
        const data = {
          title: '发送离职通知及离职清单',
          content: `是否要向${val.englishName}发送离职通知及离职手续清单?`,
          id: val.candidateInformationId,
          flag: 'quit'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    chat(val) {
      console.log('aaa')
      if (val) {
        this.$refs.quitChatDialogRef.show(val)
      }
    },
    editButton(val) {
      if (val) {
        val.flag = 'quit'
        this.$refs.quitDialogRef.show(val)
      }
    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__postTransferTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.changeManagement__table__quitTable {
  :deep(.el-table) {
    .cell {
      white-space: pre-line;
      text-align: left;
      line-height: 13px;
    }
  }
}
</style>
