<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__promotionTable"
    stripe
    ref="changeManagement__table__promotionTable"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
  >
    <el-table-column
      v-for="item in promotionTableColumn"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column label="状态" width="450" align="left">
      <template slot-scope="scope">
        <promotionTableRoleButton
          ref="promotionTableRoleButton"
          @promotionTableRoleButtonEditButton="edit"
          @promotionTableRoleButtonAudit="audit"
          @promotionTableRoleButtonTermination="termination"
          @promotionTableRoleButtonApprovalAttachments="approvalAttachments"
          @promotionTableRoleButtonRejectReason="rejection(scope.row)"
          @promotionTableDetailButton="promotionTableDetail"
          :scope="scope.row"
        />
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in promotionTableColumn1"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    ></el-table-column>
    <promotionAuditDialog ref="promotionAuditDialogRef" @refresh="queryApi"/>
    <el-dialog
      title="驳回理由"
      :visible.sync="rejectReasonVisible"
      width="450px"
      append-to-body
      destroy-on-close
    >
      <!-- :before-close="handleClose" -->
      <div style="background: #fff">
        <div class="lbox">
          <div class="lname">驳回理由</div>
          <div class="ltext">{{ rejectReasonObj.rejectRemarks }}</div>
        </div>
        <div class="lbox">
          <div class="lname">操作人</div>
          <div class="ltext">
            <div>{{ rejectReasonObj.operator }}</div>
            <div>{{ rejectReasonObj.email }}</div>
          </div>
        </div>
        <div class="lbox">
          <div class="lname">驳回时间</div>
          <div class="ltext">{{ rejectReasonObj.rejectDate }}</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectReasonVisible = false">取 消</el-button>
        <el-button type="primary" @click="rejectReasonVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <promotionTableAuditDialog
      ref="promotionTableAuditDialogRef"
      @back="queryApi"
    />
    <promotionAttachmentsAuditDialog
      ref="promotionAttachmentsAuditDialogRef"
      @close="queryApi"
    />
    <promotionDetailDialog
      ref="promotionDetailDialogRef"
    />
  </PocTable>
</template>

<script>
import { promotionTableColumn } from '@/views/employee/columnConfig/promotionTableColumn'
import { promotionTableColumn1 } from '@/views/employee/columnConfig/promotionTableColumn'
import promotionTableRoleButton from '@/views/employee/pages/components/promotionTableRoleButton'
import promotionAuditDialog from '@/views/employee/dialog/promotionAuditDialog'
import promotionTableAuditDialog from '@/views/employee/dialog/promotionTableAuditDialog'
import promotionAttachmentsAuditDialog from '@/views/employee/dialog/promotionAttachmentsAuditDialog'
import promotionDetailDialog from "@/views/employee/dialog/promotionDetailDialog";
import { getItem } from '@/utils/storage'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import {
  getEmployeesList,
  staffPromotionSubmit,
  staffPromotionTerminate,
  rejectReason
} from '@/api/employees'
import store from "@/store";

export default {
  mixins: [$PCommon.TableMixin],
  name: 'promotionTable',
  components: {
    promotionTableRoleButton,
    promotionAuditDialog,
    pgUploadFile,
    promotionTableAuditDialog,
    promotionDetailDialog,
    promotionAttachmentsAuditDialog
  },
  data() {
    return {
      promotionTableColumn,
      promotionTableColumn1,
      tableData: [],
      loading: false,
      rejectReasonVisible: false,
      rejectReasonObj: {}
    }
  },
  methods: {
    // 查看驳回信息
    rejection(val) {
      const data = {
        id: val.id
      }
      rejectReason(data).then((res) => {
        if (res && res.code === 200) {
          this.rejectReasonObj = res.data
          this.rejectReasonVisible = true
        }
      })
    },
    edit(row) {
      row.jumpFlag = 'edit'
      row.flag = '1'
      this.$refs.promotionTableAuditDialogRef.show(row)
    },
    approvalAttachments(val) {
      if (val) {
        this.$refs.promotionAttachmentsAuditDialogRef.show(val)
      }
    },
    promotionTableDetail(val){
      if (val) {
        this.$refs.promotionDetailDialogRef.show(val)
      }
    },
    submit(val) {
      const data = {
        id: val.candidateInformationId
      }
      staffPromotionSubmit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('提交成功')
          this.queryApi()
        }
      })
    },
    termination(val) {
      const data = {
        id: val.id
      }
      staffPromotionTerminate(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('终止成功')
          this.queryApi()
        }
      })
    },
    audit(val) {
      if (val) {
        this.$refs.promotionAuditDialogRef.show(val)
      }
    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__postTransferTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lbox {
  display: flex;
  margin-bottom: 20px;
  .lname {
    color: #999;
    width: 70px;
    text-align: right;
  }
  .ltext {
    color: #000;
    margin-left: 20px;
  }
}
.changeManagement__table__promotionTable {
  :deep(.el-table) {
    .cell {
      white-space: pre-line;
      text-align: left;
      line-height: 13px;
    }
  }
}
</style>
