<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="quitChatDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    v-loading="loading"
    element-loading-text="正在获取中..."
    element-loading-spinner="el-icon-loading"
    destroy-on-close
  >
    <template v-slot:title>
      <div>查看离职信息</div>
    </template>
    <div class="drawer_content">
      <descriptions :description-data="baseDescription" />
      <PGTitle title="附件信息">
        <div class="descriptionFile">
          <div
            v-for="item in fileList"
            @click="openFile(item)"
            class="descriptionFile__FileItem"
          >
            <span>{{ item.fileName }}</span>
          </div>
        </div>
      </PGTitle>
      <descriptions
        style="margin-top: 20px"
        :description-data="quitDescription"
      />
      <div class="quitCollapse">
        <div class="quitCollapse__title" @click="rotateAvg">
          <span>编辑记录</span>
          <span :class="rotateAvgClass">
            <svg-icon icon="putAway" />
          </span>
        </div>
        <div class="quitCollapse__content" :class="quitHeight">
          <quitTimeLine :quit-time-line-list="quitTimeLineList" />
        </div>
      </div>
    </div>
    <div class="closeButton">
      <el-button @click="close">返回</el-button>
    </div>
  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import quitTimeLine from '@/views/employee/components/QuitTimeLine'
import { getUserInfo, getUserInfoLeave } from '@/api/employees'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'quitChatDialog',
  components: {
    descriptions,
    PGTitle,
    quitTimeLine
  },
  data() {
    return {
      loading: false,
      drawer: false,
      baseDescription: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 9
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: '',
              lg: 8
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      rotateAvgClass: '',
      quitHeight: 'quitHeightMin',
      quitDescription: [
        {
          title: '离职信息',
          column: 4,
          content: [
            {
              label: 'Social Insurance / Housing Fund paid til',
              key: 'socialInsuranceHousingFundPaidTill',
              value: '',
              lg: 8
            },
            {
              label: 'Last Working Date',
              key: 'lastWorkingDate',
              value: '',
              lg: 8
            },
            {
              label: 'Last Employment Date',
              key: 'lastEmploymentDate',
              value: '',
              lg: 8
            },
            {
              label: 'Resignation Submission Date',
              key: 'resignationSubmissionDate',
              value: '',
              lg: 8
            },
            {
              label: 'Destination profile - Where did theVoluntary leavers go',
              key: 'destinationProfileWhereDidTheVoluntaryLeaversGo',
              value: '',
              lg: 8
            },
            {
              label: 'Paid Out Annual Leave Days',
              key: 'paidOutAnnualLeaveDays',
              value: '',
              lg: 8
            },
            {
              label: 'Overtaken Annual Leave Days',
              key: 'overtakenAnnualLeaveDays',
              value: '',
              lg: 8
            },
            {
              label: 'Overtaken Sick Leave Days',
              key: 'overtakenSickLeaveDays',
              value: '',
              lg: 8
            },
            {
              label: 'LeaveType Leave reason',
              key: 'leaveTypeLeaveReason',
              value: '',
              lg: 8
            },
            {
              label: 'Remark',
              key: 'remark',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      quitTimeLineList: [],
      fileList: [],
      candidateInformationId: ''
    }
  },
  methods: {
    close() {
      this.loading = false
      this.drawer = false
      this.rotateAvgClass = ''
      this.quitHeight = 'quitHeightMin'
    },
    show(val) {
      if (val) {
        this.candidateInformationId = val.candidateInformationId
        this.getData()
        this.drawer = true
      }
    },
    rotateAvg() {
      if (this.rotateAvgClass) {
        this.rotateAvgClass = ''
        this.quitHeight = 'quitHeightMin'
      } else {
        this.rotateAvgClass = 'rotateAvgClass'
        this.quitHeight = 'quitHeightMax'
      }
      console.log(this.rotateAvgClass)
    },
    openFile(val) {
      if (val) {
        window.open(val.url)
      }
    },
    getData() {
      const data = {
        id: this.candidateInformationId
      }
      getUserInfoLeave(data).then((res) => {
        if (res && res.code === 200) {
          mergeDesDataOfPort(this.baseDescription, res.data)
          mergeDesDataOfPort(this.quitDescription, res.data)
          this.quitTimeLineList = res.data.history
          this.fileList = res.data.files
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.quitChatDialog {
  .el-drawer__header {
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
  .descriptionFile {
    display: flex;
    &__FileItem {
      margin-right: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1b1d1e;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #f4f4f4;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0 4px 2px 4px;
      &:hover {
        cursor: pointer;
        background: #dbdbdb;
      }
    }
  }
  .quitCollapse {
    &__title {
      span {
        &:first-child {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }
      }
      .rotateAvgClass {
        display: inline-block;
        transform: rotate(180deg);
        transition: all 0.2s ease-out;
      }
    }
    &__content {
      transition: all 1s ease-out;
      margin-top: 10px;
    }
    .quitHeightMax {
      height: 100%;
    }
    .quitHeightMin {
      height: 0;
      display: none;
    }
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
.closeButton {
  text-align: right;
  margin-top: 30px;
  margin-right: 119px;
}
</style>
