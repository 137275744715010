<template>
  <el-drawer :visible.sync="drawer" size="95%" custom-class="HcTransferredOutDialog" direction="btt" width="80%"
    :before-close="close" append-to-body v-loading="loading" element-loading-text="正在获取中..."
    element-loading-spinner="el-icon-loading" destroy-on-close>
    <template v-slot:title>
      <div>填写转入信息-带HC</div>
    </template>
    <div class="drawer_content">
      <descriptions :descriptionData="listData" />
      <PGTitle title="附件及其他">
        <pgUploadFile :id="candidateInformationId" url="candidateUpload" :limit="3" type="Transfer"
          :upload-list-call="uploadListCall" />
      </PGTitle>
      <PGTitle title="转入信息">
        <FormDesignerNew ref="FormDesignerRef" :formScale="formScale"
                         @hfmCodeChange="hfmCodeChange"
                         @assigmentProjectLevel2Change="assigmentProjectLevel2Change"
                         @jobGradingChange="jobGradingChange"
                         @parentFunctionChange="parentFunctionChange"
                         @locationChange="locationChange"
                         @newContractChange="newContractChange"
                         @newContractPeriodChange="newContractPeriodChange"
                         @effectiveDateChange="effectiveDateChange"
                         @legalEntityChange="legalEntityChange"
        />
      </PGTitle>
      <PGTitle title="是否发送check list">
        <span class="hc__text">发送check list</span>
        <span>
          <el-switch
            v-model="ifSendCheckList"
            active-color="#6EBAAF"
            inactive-color="#E36124"
          >
          </el-switch>
          <span class="switchText" v-if="ifSendCheckList">发送</span>
          <span class="switchText" v-else>不发送</span>
        </span>
      </PGTitle>
    </div>
    <div class="dialog-footer">
      <el-button @click="close(false)">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="buttonLoading">确认接收</el-button>
    </div>
    <personnelSelectionDialog ref="personnelSelectionDialogRef" @onConfirm="onConfirm" />
  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import FormDesignerNew from '@/components/FormDesigner/FormDesignerNew'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'

import { getSysOrgOrganizations, getTransferUserList } from "@/api/organization";
import { getUserInfo } from '@/api/employees'
import { mergeDesDataOfPort } from '@/utils/util'
import {
  getChildFunction,
  getCodeValue,
  getLineManager,
  getOfficeList,
  saveHc
} from '@/api/quto'
import {
  getAssigmentProjectLevel2,
  getDescriptionLevel3,
  getClient1,
  getLegalEntity
} from '@/api/personnelRequisition'
import { getEntityHfmCode, getBusinessUnitNameByHfmCode } from '@/api/intern'
import dayjs from 'dayjs'
export default {
  name: 'TurnsOutInformationDialog',
  components: {
    descriptions,
    PGTitle,
    pgUploadFile,
    FormDesignerNew,
    PersonnelSelectionDialog
  },
  data() {
    return {
      ifSendCheckList: false, // 是否发送 checkList
      drawer: false,
      loading: false,
      candidateInformationId: '',
      tableData: [],
      uploadListCall: [],
      selectPersons: {},
      listData: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 8
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: '',
              lg: 8
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: '',
              lg: 8
            },
            {
              label: '原HRBP Owner',
              key: 'oldHrbp',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      selectVal: [],
      buttonLoading: false,
      oldNoticePeriod:0,
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'HFM Code',
                prop: 'hfmCode',
                type: 'select',
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: 'hfmCodeChange',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'legalEntity',
                rules: 'required',
                label: 'Legal Entity',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: 'legalEntityChange',
                placeholder: '请选择',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Assigment/project (Level2)',
                prop: 'assigmentProjectLevel2',
                type: 'select',
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change:'assigmentProjectLevel2Change',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'descriptionLevel3',
                rules: 'required',
                label: 'Description (Level3)',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                // change: () => { },
                onClick: () => { }
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'client',
                rules: '',
                label: 'Client',
                id: Math.random(),
                type: 'select',
                value: '',
                options: [],
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                // change: () => { },
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              //  rules: 'required',
              formData: {
                prop: 'businessUnit',
                rules: 'required',
                label: 'Business Unit',
                id: Math.random(),
                type: 'select',
                value: '',
                options: [],
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                // change: () => { },
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Department',
                prop: 'department',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                // change: () => { },
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'employeeNewEmail',
                rules: 'required|email',
                label: 'Employee New Email',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'englishTitle',
                rules: 'required',
                label: 'English Title',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'cnTitle',
                rules: 'required',
                label: 'CN Title',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'jobGrading',
                rules: 'required',
                label: 'Job Grading',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: 'jobGradingChange',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Work Schedules',
                prop: 'workSchedules',
                type: 'select',
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                // change: () => { },
                onClick: () => { }
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'parentFunction',
                rules: 'required',
                label: 'Parent Function',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                onClick: () => { },
                change: 'parentFunctionChange'
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'childFunction',
                rules: 'required',
                label: 'Child Function',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                // change: () => { },
                onClick: () => { }
              }
            },
            // {
            //   span: 6,
            //   id: Math.random(),
            //   formData: {
            //     prop: 'laborCode',
            //     rules: 'required',
            //     label: 'Labor Code',
            //     id: Math.random(),
            //     options: [],
            //     clearable: true,
            //     filterable: true,
            //     type: 'select',
            //     value: '',
            //     change: () => { },
            //     onClick: () => { }
            //   }
            // },
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Location',
                prop: 'location',
                type: 'select',
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: 'locationChange',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'office',
                rules: 'required',
                label: 'office',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                // change: () => { },
                onClick: () => { }
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lionIdOfLineManager',
                rules: 'required',
                label: 'Lion ID of LM',
                id: Math.random(),
                type: 'input',
                value: '',
                onClick: () => {
                  this.selectPerson('induction')
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lineManager',
                rules: 'required',
                label: 'Name of LM',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'input',
                value: '',
                // change: () => { },
                onClick: (val) => {
                  this.selectPerson('induction')
                  // this.$refs.FormDesignerRef.setValSign(
                  //   'lionIdOfLineManager',
                  //   val.lionIdOfLineManager,
                  //   this.formScale
                  // )
                }
              }
            },
            /* {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lionManagerEmail',
                rules: 'required',
                label: 'LM Email',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'input',
                value: '',
                change: () => {},
                onClick: () => {
                  this.selectPerson('induction')
                }
              }
            }, */
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'nameOfPerformanceLm',
                rules: 'required',
                label: 'Performance Manager',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'input',
                value: '',
                // change: () => { },
                onClick: (val) => {
                  // this.$refs.FormDesignerRef.setValSign(
                  //   'lionIdOfPerformanceManager',
                  //   val.lionIdOfLineManager,
                  //   this.formScale
                  // )
                  this.selectPerson('performance')
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lionIdOfPerformanceManager',
                rules: 'required',
                label: 'Lion ID of Performance Manager',
                id: Math.random(),
                type: 'input',
                value: '',
                onClick: (val) => {
                  this.selectPerson('performance')
                }
              }
            },
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            /* {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Name of Vega LM',
                prop: 'nameOfVegaLm',
                type: 'select',
                options: [],
                rules: 'required',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => { },
                onClick: (val) => {
                  this.$refs.FormDesignerRef.setValSign(
                    'lionIdOfLmVega',
                    val.lionIdOfLmVega,
                    this.formScale
                  )
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lionIdOfLmVega',
                rules: 'required',
                label: 'Lion ID of LM Vega',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }, */
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'ceoName',
                rules: 'required',
                label: 'CEO',
                id: Math.random(),
                type: 'input',
                value: '',
                onClick: (val) => {
                  this.selectPerson('CEO',"CEO")
                }
              }
            },

            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'ceoName1',
                rules: '',
                label: 'CEO-1',
                id: Math.random(),
                type: 'input',
                value: '',
                onClick: (val) => {
                  this.selectPerson('induction', 'ceo1')
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newContract',
                rules: 'required',
                label: 'New Contract',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: 'newContractChange',
                onClick: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newContractPeriod',
                rules: 'required',
                label: 'New Contract Period',
                id: Math.random(),
                options: [
                  { value:'12',label:'12'},
                  { value:'24',label:'24'},
                  { value:'36',label:'36',},
                  { value:'无固定月',label:'无固定月',}
                ],
                clearable: true,
                filterable: true,
                type: 'select',
                placeholder: '请选择',
                value: '',
                change:'newContractPeriodChange'
              }
            },
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newNoticePeriod',
                rules: 'required',
                label: 'New Notice Period(Months)',
                id: Math.random(),
                options: [
                  { value:'1',label:'1'},
                  { value:'2',label:'2'},
                  { value:'3',label:'3',},
                  { value:'6',label:'6',}
                ],
                clearable: true,
                filterable: true,
                type: 'select',
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                value: '',
                description:`员工原有Notice Period为0个月`,
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newEmploymentContractStartDate',
                rules: 'required',
                label: 'New Employment Contract Start Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                // change: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newEmploymentContractDueDate',
                rules: 'required',
                label: 'New Employment Contract Due Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                // change: () => { }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'effectiveDate',
                rules: 'required',
                label: 'Effective Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: 'effectiveDateChange'
              }
            }
          ]
        }
      ],
      apiCountNumber: 0,
      isClearBusinessUnit: false,
      perType:'',
      ceo:'',
      ceo1:'',
      ceoType1:'',
    }
  },
  computed: {
    HFMCODE() {
      return this.formScale[0].colList[0].formData.value;
    }
  },
  watch: {
    apiCountNumber: {
      handler(val) {
        if (val === 2) {
          setTimeout(() => {
            this.loading = false
          }, 10)
        }
      }
    },
    HFMCODE: {
      handler(curVal, oldVal) {
        this.getBusinessUnitData(curVal, this.isClearBusinessUnit)
      },
      deep: true
    },
    oldNoticePeriod:{
      handler(curVal,oldVal){
        this.$refs.FormDesignerRef.setDescriptionSign(
          'newNoticePeriod',
          `员工原有Notice Period为${curVal}个月`
        )
      }
    }
  },
  methods: {
    async hfmCodeChange(val){
      // let isRight = await this.$refs.FormDesignerRef.checkOrganization();
      // if(!isRight) return;
      this.isClearBusinessUnit = true;
      this.loading = true
      this.$refs.FormDesignerRef.setValSign(
        'hfmCode',
        val,
        this.formScale
      )
      this.getBusinessUnitData(val)
      this.getCEO()
      await getLegalEntity({ hfmCode: val }).then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          let data = {
            legalEntity: LegalEntity.data
          }
          this.$refs.FormDesignerRef.setOptions(
            data,
            this.formScale
          )
          this.$refs.FormDesignerRef.setValSign(
            'legalEntity',
            '',
            this.formScale
          )
        }
      })
      this.loading = false
    },
    assigmentProjectLevel2Change(val){
      this.$refs.FormDesignerRef.setValSign(
        'assigmentProjectLevel2',
        val,
        this.formScale
      )
      this.getNoticePeriodByLevel2AndJobGrading()
      this.getCEO()
    },
    jobGradingChange(){
      this.getNoticePeriodByLevel2AndJobGrading()
    },
    parentFunctionChange(val){
      getChildFunction({ parentFunction: val }).then((res) => {
        if (res && res.code && res.code === 200) {
          if (res.data) {
            let data = {
              childFunction: res.data
            }
            this.$refs.FormDesignerRef.setOptions(
              data,
              this.formScale
            )
          }
        }
      })
    },
    async locationChange(val, firstLoading = false){
      // let isRight = await this.$refs.FormDesignerRef.checkOrganization();
      // if(!isRight) return;
      this.loading = true
      this.$refs.FormDesignerRef.setValSign(
        'location',
        val,
        this.formScale
      )
      this.getCEO()
      getOfficeList({ location: val }).then((res) => {
        setTimeout(() => {
          this.loading = false
        })
        if (res && res.code && res.code === 200) {
          if (res.data) {
            let data = {
              office: res.data
            }
            if (!firstLoading) {
              this.$refs.FormDesignerRef.setValSign(
                'office',
                '',
                this.formScale
              )
            }
            this.$refs.FormDesignerRef.setOptions(
              data,
              this.formScale
            )
          }
        }
      })
    },
    newContractChange(val){
      if (val === '是') {
        this.$refs.FormDesignerRef.changeOptions(
          'newEmploymentContractStartDate',
          {
            rules: 'required',
            disabled: false
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newEmploymentContractDueDate',
          {
            rules: 'required',
            disabled: false
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newNoticePeriod',
          {
            rules: 'required',
            disabled: false
          },
          this.formScale
        )
        this.getNoticePeriodByLevel2AndJobGrading()
        this.$refs.FormDesignerRef.setValSign(
          'effectiveDate',
          this.$refs.FormDesignerRef.getSingleVal(
            'newEmploymentContractStartDate'
          ),
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newContractPeriod',
          {
            rules: 'required',
            disabled: false
          },
          this.formScale
        )
      } else {
        let year = dayjs().year()
        let month = dayjs().month() + 2
        let nextMonthDay = ''
        if (month < 10) {
          nextMonthDay = `${year}-0${month}-01`
        } else {
          nextMonthDay = `${year}-${month}-01`
        }
        this.$refs.FormDesignerRef.setValSign(
          'effectiveDate',
          nextMonthDay,
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newEmploymentContractStartDate',
          {
            rules: '',
            disabled: true
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newEmploymentContractDueDate',
          {
            rules: '',
            disabled: true
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newNoticePeriod',
          {
            rules: '',
            disabled: true
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.setValSign(
          'newNoticePeriod',
          '',
          this.formScale
        )
        this.$refs.FormDesignerRef.changeOptions(
          'newContractPeriod',
          {
            rules: '',
            disabled: true
          },
          this.formScale
        )
        this.$refs.FormDesignerRef.setValSign(
          'newContractPeriod',
          '',
          this.formScale
        )
        this.$refs.FormDesignerRef.setValSign(
          'newEmploymentContractDueDate',
          '',
          this.formScale
        )
      }
    },
    newContractPeriodChange(val){
      if(val === '无固定月'){
        this.$refs.FormDesignerRef.setValSign(
          'newEmploymentContractDueDate',
          '9999-12-31',
          this.formScale
        )
      }else {
        this.$refs.FormDesignerRef.setValSign(
          'newEmploymentContractDueDate',
          '',
          this.formScale
        )
      }

    },
    effectiveDateChange(val){
      if (val) {
        let day = val.split('-')[2]
        if (day && day !== '01') {
          this.$message.warning('*请确认，目前所选日期不是1号')
        }
      }
    },

    getNoticePeriodByLevel2AndJobGrading(){
      let assigmentProjectLevel2 = this.$refs.FormDesignerRef.getSingleVal(
        'assigmentProjectLevel2'
      )
      let jobGrading = this.$refs.FormDesignerRef.getSingleVal(
        'jobGrading'
      )
      let newContract = this.$refs.FormDesignerRef.getSingleVal(
        'newContract'
      )
      if(assigmentProjectLevel2 && jobGrading && newContract != '否'){
        this.$Api.induction.getNoticePeriodByLevel2AndJobGrading({
          jobGrading:jobGrading,
          level2:assigmentProjectLevel2,
        }).then(res=>{
          if(res.code === 200){
            console.log(res);
            this.$refs.FormDesignerRef.setValSign(
              'newNoticePeriod',
              res.data.noticePeroid,
              this.formScale
            )
          }
        })
      }
    },
    getBusinessUnitData(val, type = true) {
      getBusinessUnitNameByHfmCode({ hfmCode: val }).then((BusinessUnit) => {
        console.log(BusinessUnit)
        if (BusinessUnit.code && BusinessUnit.code === 200) {
          let data = {
            businessUnit: BusinessUnit.data
          }
          this.$refs.FormDesignerRef.setOptions(
            data,
            this.formScale
          )
          if (type == true) {
            this.$refs.FormDesignerRef.setValSign(
              'businessUnit',
              '',
              this.formScale
            )
          }
        }
      })
    },
    /**
     * 获取clientlist
     */
    getClientList() {
      getClient1().then((res) => {
        let data = {
          client: res.data
        }
        this.$refs.FormDesignerRef.setOptions(data, this.formScale)
        // this.$refs.FormDesignerRef.setValSign('client', '', this.formScale)

      })
    },

    legalEntityChange(val){
      console.log(val);
      this.$refs.FormDesignerRef.setValSign(
        'legalEntity',
        val,
        this.formScale
      )
      console.log(this.$refs.FormDesignerRef.getSingleVal(
        'legalEntity'
      ));
      this.getCEO()
    },
    getCEO(){
      let legalEntity = this.$refs.FormDesignerRef.getSingleVal(
        'legalEntity'
      )
      if(legalEntity.split('_')[1]){
        legalEntity = legalEntity.split('_')[1]
      }
      let params = {
        assigmentProjectId: this.$refs.FormDesignerRef.getSingleVal(
          'assigmentProjectLevel2'
        ),
        hfm_code: this.$refs.FormDesignerRef.getSingleVal(
          'hfmCode'
        ),
        legalEntity: legalEntity,
        workLoaction: this.$refs.FormDesignerRef.getSingleVal(
          'location'
        ),
      }
      getSysOrgOrganizations(params).then(res=>{
        console.log(res);
        if(res.data){
          this.formScale.forEach(i => {
            i.colList.forEach(m => {
              if (m.formData.prop === 'ceoName') {
                m.formData.value =  res.data.ceoName
                this.ceo = res.data.ceo
                this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
              }
            })
          })
        }else {
          this.formScale.forEach(i => {
            i.colList.forEach(m => {
              if (m.formData.prop === 'ceoName') {
                m.formData.value =  ""
                this.ceo = ""
                this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
              }
            })
          })
        }
      })
    },

    close(val = true) {
      const removeData = () => {
        this.selectVal = []
        this.radioModel = false
        this.uploadListCall = []
        this.hcIsBroughtOut = false
        this.candidateInformationId = ''
        this.tableData = []
        this.apiCountNumber = 0
        this.drawer = false
        this.buttonLoading = false
        this.$refs.FormDesignerRef.clearAllValue()
      }
      if (val) {
        removeData()
        this.$emit('close')
      } else {
        removeData()
      }
    },
    loadingOpen() {
      this.buttonLoading = true
    },
    loadingClose() {
      this.buttonLoading = false
    },
    getOptions() {
      const data = {
        id: this.candidateInformationId
      }
      getCodeValue(data).then((res) => {
        this.apiCountNumber++
        if (res && res.code === 200) {
          res.data['nameOfPerformanceLm'] = res.data.lineManager
          this.$refs.FormDesignerRef.setOptions(res.data, this.formScale, ['businessUnit'])
          this.getNoticePeriodByLevel2AndJobGrading()
        }
      })

      this.getClientList()
      // getLineManager().then((res) => {
      //   this.apiCountNumber++
      //   if (res && res.code === 200) {
      //     this.$refs.FormDesignerRef.setOptions(res.data, this.formScale)
      //   }
      // })
      // getAssigmentProjectLevel2().then((res) => {
      //   this.apiCountNumber++
      //   if (res && res.code && res.code === 200) {
      //     if (res.data) {
      //       let data = {
      //         assigmentProjectLevel2: res.data
      //       }
      //       this.$refs.FormDesignerRef.setOptions(data, this.formScale)
      //     }
      //   }
      // })
      // getDescriptionLevel3().then((res) => {
      //   this.apiCountNumber++
      //   if (res && res.code && res.code === 200) {
      //     if (res.data) {
      //       let data = {
      //         descriptionLevel3: res.data
      //       }
      //       this.$refs.FormDesignerRef.setOptions(data, this.formScale)
      //     }
      //   }
      // })
      // getChildFunction().then((res) => {
      //   this.apiCountNumber++
      //   if (res && res.code && res.code === 200) {
      //     if (res.data) {
      //       let data = {
      //         childFunction: res.data
      //       }
      //       this.$refs.FormDesignerRef.setOptions(data, this.formScale)
      //     }
      //   }
      // })
      // getEntityHfmCode().then((HfmCode) => {
      //   this.apiCountNumber++
      //   if (HfmCode && HfmCode.code === 200) {
      //     let data = {
      //       hfmCode: HfmCode.data
      //     }
      //     this.$refs.FormDesignerRef.setOptions(data, this.formScale)
      //   }
      // })
      // getLegalEntity().then((LegalEntity) => {
      //   this.apiCountNumber++
      //   if (LegalEntity.code && LegalEntity.code === 200) {
      //     let data = {
      //       legalEntity: LegalEntity.data
      //     }
      //     this.$refs.FormDesignerRef.setOptions(data, this.formScale)
      //   }
      // })
    },
    getData() {
      this.loading = true
      const data1 = {
        id: this.candidateInformationId
      }
      getUserInfo(data1).then((res) => {
        if (res && res.code === 200) {
          this.apiCountNumber++
          this.uploadListCall = res.data.files
          this.oldNoticePeriod = res.data.oldNoticePeriod
          this.ceo = res.data.ceo
          mergeDesDataOfPort(this.listData, res.data)
          res.data.lineManagerBaseId ? this.selectPersons.lineManagerBaseId = res.data.lineManagerBaseId : ''
          res.data.lineManagerPerformanceBaseId ? this.selectPersons.lineManagerPerformanceBaseId = res.data.lineManagerPerformanceBaseId : ''
          this.$refs.FormDesignerRef.setValue(res.data)
          this.getNoticePeriodByLevel2AndJobGrading()
        }
      })
    },
    show(val) {
      if (val) {
        this.candidateInformationId = val.id
        if (this.candidateInformationId) {
          this.getOptions()
          this.getData()
          this.drawer = true
        } else {
          this.$message.warning('缺失id')
        }
      }
    },
    getCurrentRow(row) {
      this.selectVal = [row]
    },
    emit(val) {
      const data = {
        candidateInformationId: this.candidateInformationId
      }
      this.$emit(val, data)
    },
    async submit() {
      this.buttonLoading = true
      const data = await this.$refs.FormDesignerRef.getValue()
      if (data) {
        const returnData = {
          id: this.candidateInformationId,
          ceo:this.ceo,
          ...data,
          ...this.selectPersons
        }
        returnData.ceo = this.ceo
        returnData.ceo1 = this.ceo1
        returnData.ceoType1 = this.ceoType1
        
        returnData.ifSendCheckList = this.ifSendCheckList
        
        saveHc(returnData).then((res) => {
          this.buttonLoading = false
          if (res && res.code === 200) {
            this.$message.success('接收成功')
            this.close()
          }
        })
      } else {
        this.buttonLoading = false
      }
    },
    save() {
      this.emit('onStaging')
    },
    selectPerson(val,type='') {
      this.perType=type
      if(type==='CEO'){
        this.$refs.personnelSelectionDialogRef.show({
          type: val,
          position: 'Personnel',
          radio:true
        })
      }else {
        this.$refs.personnelSelectionDialogRef.show({
          position: val,
          radio: true
        })
      }
    },
    onConfirm(val) {
      if(this.perType === "CEO"){
        console.log(val);
        this.formScale.forEach(i => {
          i.colList.forEach(m => {
            if (m.formData.prop === 'ceoName') {
              m.formData.value =  val[0].userName
              this.ceo = val[0].id
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
          })
        })
        return
      }
      if (this.perType === "ceo1") {
        this.formScale.forEach(i => {
          i.colList.forEach(m => {
            if (m.formData.prop === 'ceoName1') {
              m.formData.value =  val[0].englishName
              this.ceo1 = val[0].lineManagerLiid
              this.ceoType1 = val[0].ceoType1
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
          })
        })
        return
      }
      console.log(val, val[0].lineManagerBaseId !== undefined)
      val[0].lineManagerBaseId !== undefined ? (this.selectPersons.lineManagerBaseId = val[0].lineManagerBaseId) : ''
      val[0].lineManagerPerformanceBaseId !== undefined ? (this.selectPersons.lineManagerPerformanceBaseId = val[0].lineManagerPerformanceBaseId) : ''
      this.formScale.forEach(i => {
        i.colList.forEach(m => {
          if(val[0].lineManagerBaseId !== undefined) {
            if (m.formData.prop === 'lionIdOfLineManager') {
              m.formData.value = val[0].lineManagerLiid
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
            if (m.formData.prop === 'lineManager') {
              m.formData.value = val[0].englishName || val[0].userName
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
            if (m.formData.prop === 'lionManagerEmail') {
              m.formData.value = val[0].email
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
          }
          if(val[0].lineManagerPerformanceBaseId !== undefined) {
            if (m.formData.prop === 'lionIdOfPerformanceManager') {
              m.formData.value = val[0].lineManagerLiid
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
            if (m.formData.prop === 'nameOfPerformanceLm') {
              m.formData.value = val[0].englishName || val[0].userName
              this.$refs.FormDesignerRef.setFormDataValSign(m.formData.prop, m.formData.value)
            }
          }
        })
      })
      console.log(this.formScale)
    }
  }
}
</script>

<style lang="scss" scoped>
.HcTransferredOutDialog {
  .el-drawer__header {
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }

  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }

  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }

  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}

.dialog-footer {
  text-align: right;
  padding-right: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}

:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
</style>
