<template>
  <div class="AudiDataNext">
    <PGTitle title="确认信息">
      <div class="AudiDataNext__EffectiveDate">
        <span>{{ AudiDataNextData.effectiveDate }}</span>
        <span>生效日期</span>
      </div>
      <div class="AudiDataNext__AudiDataNextTable">
        <PocTable
          v-loading="loading"
          :data="positionNextTableData"
          :max-height="100000000"
          class="AudiDataNext__AudiDataNextTable__positionTableData"
          hidepage
          stripe
        >
          <el-table-column
            v-for="item in positionTableDataColumn"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
        </PocTable>
        <div class="leftBox">
          <PocTable
            v-loading="loading"
            :data="SalaryNextTableData"
            :max-height="100000000"
            class="AudiDataNext__AudiDataNextTable__positionTableData"
            hidepage
            stripe
          >
            <el-table-column
              v-for="(item, index) in SalaryTableDataColumn"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="flex">
                  <div v-if="scope.row[item.key]">
                    {{ scope.row[item.key] }}
                  </div>
                  <div
                    class="increase"
                    v-if="item.key == 'salaryAfter' && scope.$index == 3"
                  >
                    （较晋升前涨幅{{ Increase }}）
                  </div>
                </div>
              </template>
            </el-table-column>
          </PocTable>
          <div class="ftit">*若薪资涨幅超过50%,需要Groupe CEO进行审批</div>
        </div>
      </div>
    </PGTitle>
  </div>
</template>

<script>
import PGTitle from '@/components/PG-title/PG-title'
import { baseDataByCode, promotionInfo } from '@/api/employees'
import { check, mergeDesDataOfPort, typeRange } from '@/utils/util'
import dayjs from 'dayjs'
export default {
  name: 'AudiDataNext',
  components: {
    PGTitle
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Increase: '0%',
      loading: false,
      positionNextTableData: [],
      SalaryNextTableData: [],
      AudiDataNextData: {},
      positionTableDataColumn: [
        {
          key: 'position',
          name: 'Position',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'positionBefore',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'positionAfter',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      SalaryTableDataColumn: [
        {
          key: 'salary',
          name: 'Salary',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'salaryBefore',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'salaryAfter',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ]
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      if (this.rowData) {
        let candidateInformationId = this.rowData.candidateInformationId
        const data = {
          candidateInformationId,
          flag: '1'
        }
        const positionTableListData = [
          {
            position: 'Job Title EN',
            positionBeforeProp: 'beforePromotionJobTitleEn',
            positionAfterProp: 'afterPromotionJobTitleEn',
            positionBefore: '',
            positionAfter: ''
          },
          {
            position: 'Job Title CN',
            positionBeforeProp: 'beforePromotionJobTitleCn',
            positionAfterProp: 'afterPromotionJobTitleCn',
            positionBefore: '',
            positionAfter: ''
          },
          {
            position: 'Job Grade',
            positionBeforeProp: 'beforePromotionJobGrade',
            positionAfterProp: 'afterPromotionJobGrade',
            positionBefore: '',
            positionAfter: ''
          },
          // {
          //   position: 'Annual Leave Days',
          //   positionBeforeProp: 'beforePromotionAnnualLeaveDays',
          //   positionAfterProp: 'afterPromotionAnnualLeaveDays',
          //   positionBefore: '',
          //   positionAfter: ''
          // }
        ]
        const salaryTableListData = [
          {
            salary: 'Base Salary(CNY)',
            salaryBeforeProp: 'beforePromotionBaseSalaryCny',
            salaryAfterProp: 'afterPromotionBaseSalaryCny',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: '13 Month Salary',
            salaryBeforeProp: 'beforePromotionThirteenSalary',
            salaryAfterProp: 'afterPromotionThirteenSalary',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Annual Allowance',
            salaryBeforeProp: 'beforePromotionAnnualAllowance',
            salaryAfterProp: 'afterPromotionAnnualAllowance',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Annual Total Salary',
            salaryBeforeProp: 'beforePromotionAnnualTotalSalary',
            salaryAfterProp: 'afterPromotionAnnualTotalSalary',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Relocation Allowance',
            salaryBeforeProp: 'beforePromotionRelocationAllowance',
            salaryAfterProp: 'afterPromotionRelocationAllowance',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Benefits(CNY)',
            salaryBeforeProp: 'beforePromotionBenefitsCny',
            salaryAfterProp: 'afterPromotionBenefitsCny',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Target Bonus %',
            salaryBeforeProp: 'beforePromotionTargetBonus',
            salaryAfterProp: 'afterPromotionTargetBonus',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Contractural Target Bonus %',
            salaryBeforeProp: 'beforePromotionContracturalTargetBonus',
            salaryAfterProp: 'afterPromotionContracturalTargetBonus',
            salaryBefore: '',
            salaryAfter: ''
          },
          {
            salary: 'Notice Period',
            salaryBeforeProp: 'beforePromotionNoticePeriodMonth',
            salaryAfterProp: 'afterPromotionNoticePeriodMonth',
            salaryBefore: '',
            salaryAfter: ''
          },
        ]
        promotionInfo(data).then((res) => {
          if (res && res.code === 200) {
            console.log('aaa--', res)

            // 后端说用的此处workflowId 的workflowId为id提交
            this.$emit('setId', res.data.workflowId)
            this.AudiDataNextData = { ...this.AudiDataNextData, ...res.data }
            positionTableListData.forEach((item) => {
              item.positionAfter = this.AudiDataNextData[item.positionAfterProp]
              item.positionBefore =
                this.AudiDataNextData[item.positionBeforeProp]
            })
            this.positionNextTableData = positionTableListData
            salaryTableListData.forEach((item, index) => {
              item.salaryAfter = this.AudiDataNextData[item.salaryAfterProp]
              item.salaryBefore = this.AudiDataNextData[item.salaryBeforeProp]
              // console.log("irem",item)
              // if(index==3) item.status = true;
            })
            this.SalaryNextTableData = salaryTableListData
            if (salaryTableListData[3].salaryBefore) {
              let money =
                ((salaryTableListData[3].salaryAfter -
                  salaryTableListData[3].salaryBefore) /
                  salaryTableListData[3].salaryBefore) *
                100
              this.Increase = money.toFixed(2) + '%'
            }

            console.log('money', this.Increase)
            this.AudiDataNextData.effectiveDate = dayjs(
              this.AudiDataNextData.effectiveDate
            ).format('YYYY-MM-DD')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.AudiDataNext__AudiDataNextTable__positionTableData {
  .increase {
    color: #eebd75;
  }
}

.leftBox {
  width: 100%;
  .ftit {
    margin-top: 10px;
    color: #999;
  }
}
.AudiDataNext {
  &__EffectiveDate {
    width: 224px;
    height: 56px;
    background: #f4f4f4;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px 12px 16px;
    span {
      &:first-child {
        font-size: 24px;
        font-family: Oswald-Bold;
        font-weight: normal;
        color: #323435;
        margin-right: 8px;
      }
      &:last-child {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323435;
      }
    }
  }
  &__AudiDataNextTable {
    display: flex;
    margin-top: 12px;
    &__positionTableData {
      margin-right: 20px;
    }
  }
}
</style>
