<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="TurnsOutInformationDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>填写转出信息</div>
    </template>

    <div class="drawer_content">
      <descriptions :descriptionData="listData" />
      <PGTitle title="接收人">
        <el-input
          v-model="keyword"
          style="width: 273px; height: 30px; margin-bottom: 12px;margin-right: 12px"
          @keyup.enter.native="getData"
          placeholder="请输入HFM Code/Name/Tel"
          suffix-icon="el-icon-search"
        />
        <el-button type="primary" size="mini"  @click="getData">查询</el-button>
<!--        <el-button size="mini" @click="onAddShow">新增</el-button>-->
      </PGTitle>
        <PocTable
          ref="dataTable"
          v-loading="loading"
          :data="tableData"
          max-height="400"
          hidepage
          stripe
        >
          <el-table-column align="center" width="55">
            <template v-slot="scope">
              <el-radio
                :label="scope.row.id"
                v-model="radioModel"
                @change.native="getCurrentRow(scope.row)"
                >&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column
            v-for="item in TurnsOutInformationColumn"
            show-overflow-tooltip
            v-if="item.visible"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
          ></el-table-column>
        </PocTable>
      <!-- <PGTitle title="HC">
        <span class="hc__text">将HC带出</span>
        <span>
          <el-switch
            v-model="hcIsBroughtOut"
            active-color="#6EBAAF"
            inactive-color="#E36124"
          >
          </el-switch>
          <span class="switchText" v-if="hcIsBroughtOut">带出</span>
          <span class="switchText" v-else>不带出</span>
        </span>
      </PGTitle> -->
      <PGTitle title="附件及其他">
        <pgUploadFile
          :id="candidateInformationId"
          url="candidateUpload"
          :upload-list-call="uploadListCall"
          :limit="3"
          type="Transfer"
        />
      </PGTitle>
    </div>
    <div class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button @click="save" :loading="buttonLoading"
        >暂 存</el-button
      >
      <el-button type="primary" @click="submit" :loading="buttonLoading"
        >确 定</el-button
      >
    </div>
    <el-dialog
      :title="addTitle"
      :visible.sync="addDialogVisible"
      width="450px"
      class="PersonnelSelectionAddDialog"
      top="140px"
      append-to-body
      destroy-on-close
      :before-close="addClose"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="80px"
      >
        <el-form-item label="LM Lion ID">
          <el-input v-model="addForm.id"></el-input>
        </el-form-item>
        <el-form-item label="LM Name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="LM Email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
      </el-form>
      <div class="addform-tags">适用于LM在国外，不在系统中，无法选择到的情况</div>
      <div slot="footer">
        <el-button @click="addClose">取 消</el-button>
        <el-button type="primary" @click="addSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import { TurnsOutInformationColumn } from '@/views/employee/columnConfig/TurnsOutInformationColumn'
import PGTitle from '@/components/PG-title/PG-title'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import { getTransferUserList } from '@/api/organization'
import { getUserInfo } from '@/api/employees'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'TurnsOutInformationDialog',
  components: {
    descriptions,
    PGTitle,
    pgUploadFile
  },
  data() {
    return {
      drawer: false,
      radioModel: 0,
      loading: false,
      keyword: '',
      hcIsBroughtOut: false,
      candidateInformationId: '',
      tableData: [],
      uploadListCall: [],
      TurnsOutInformationColumn,
      listData: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 8
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: '',
              lg: 8
            },
            {
              label: 'HFM Code',
              key: 'hfmCode',
              value: '',
              lg: 8
            },
            {
              label: 'Level2',
              key: 'level2',
              value: '',
              lg: 8
            },
            {
              label: 'Level 3',
              key: 'level3',
              value: '',
              lg: 8
            },
            {
              label: 'English Title',
              key: 'englishTitle',
              value: '',
              lg: 8
            },
            {
              label: 'Line manager',
              key: 'lineManager',
              value: '',
              lg: 8
            },
            {
              label: 'Original Join Date',
              key: 'origianlJoinDate',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      selectVal: [],
      buttonLoading: false,
      // 新增
      addTitle: '新增LM',
      addDialogVisible: false,
      addForm: {
        id: '',
        name: '',
        email: ''
      }
    }
  },
  methods: {
    close() {
      this.selectVal = []
      this.radioModel = 0
      this.hcIsBroughtOut = false
      this.candidateInformationId = ''
      this.tableData = []
      this.drawer = false
    },
    loadingOpen() {
      this.buttonLoading = true
    },
    loadingClose() {
      this.buttonLoading = false
    },
    selectValCall(val) {
      let userId = val.userId
      let callIdData = this.tableData.find((item) => item.id === userId)
      console.log('callIdData', callIdData)
      if (callIdData) {
        this.radioModel = callIdData.id
        this.$refs.dataTable.setCurrentRow(callIdData, true)
        this.selectVal = [callIdData]
      }
    },
    getData() {
      this.loading = true
      const data = {
        keyword: this.keyword
      }
      getTransferUserList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data) {
            this.tableData = res.data
            const data1 = {
              id: this.candidateInformationId
            }
            getUserInfo(data1).then((res) => {
              if (res && res.code === 200) {
                mergeDesDataOfPort(this.listData, res.data)
                this.uploadListCall = res.data.files
                if(res.data.hcIsBroughtOut){
                  this.hcIsBroughtOut = res.data.hcIsBroughtOut
                }
                this.selectValCall(res.data)
              }
            })
          } else {
            this.tableData = []
          }
        }
      })
    },
    show(val) {
      if (val) {
        this.candidateInformationId = val.id
        if (this.candidateInformationId) {
          this.getData()
          this.drawer = true
        } else {
          this.$message.warning('缺失id')
        }
      }
    },
    getCurrentRow(row) {
      this.selectVal = [row]
    },
    emit(val) {
      const data = {
        // 去掉，然默认是带hc，就是选中的状态 调用接口时候是带hc的接口
        // hcIsBroughtOut: this.hcIsBroughtOut,
        hcIsBroughtOut: true,
        candidateInformationId: this.candidateInformationId,
        selectVal: this.selectVal
      }
      this.$emit(val, data)
    },
    submit() {
      this.emit('onConfirm')
    },
    save() {
      this.emit('onStaging')
    },
    onAddShow() {
      this.addDialogVisible = true;
      this.addForm = {
        id: '',
        name: '',
        email: ''
      };
    },
    addClose() {
      this.addDialogVisible = false;
    },
    addSubmit() {

    }
  }
}
</script>

<style lang="scss" scoped>
.TurnsOutInformationDialog {
  .el-drawer__header {
    margin-bottom: 16px !important;
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 110px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  //padding-top: 30px;
  //padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
:deep(.el-drawer__header) {
  margin-bottom: 16px;
}
</style>
