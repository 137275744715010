<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    custom-class="quitTableRoleButtonSendQuitEmailDialog"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <div class="quitTableRoleButtonSendQuitEmailDialog__content">
      {{ content }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save" :loading="loading"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  hcInformationContractFinish,
  hcInformationContractTermination,
  notified,
  terminationQuit
} from '@/api/employees'

export default {
  name: 'quitTableRoleButtonSendQuitEmailDialog',
  data() {
    return {
      dialogVisible: false,
      title: '',
      content: '',
      id: '',
      loading: false,
      flag: ''
    }
  },
  methods: {
    close() {
      this.loading = false
      this.dialogVisible = false
    },
    show(val) {
      if (val) {
        this.title = val.title
        this.content = val.content
        this.id = val.id
        this.flag = val.flag
        this.dialogVisible = true
      }
    },
    save() {
      const data = {
        id: this.id
      }
      this.loading = true
      if (this.flag === 'quit') {
        notified(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.$message.success(`${this.title}成功`)
            this.close()
            this.$emit('close')
          }
        })
      } else if (this.flag === 'receive') {
        terminationQuit(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.$message.success('终止成功')
            this.close()
            this.$emit('close')
          }
        })
      } else if (this.flag === 'renewTableRoleButtonReceive') {
        hcInformationContractTermination(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.$message.success('终止成功')
            this.close()
            this.$emit('close')
          }
        })
      } else if (this.flag === 'renewTableRoleButtonCompletionRenewal') {
        hcInformationContractFinish(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.$message.success('确认完成续签成功')
            this.close()
            this.$emit('close')
          }
        })
      }else{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quitTableRoleButtonSendQuitEmailDialog {
  &__content {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
  }
}
</style>
