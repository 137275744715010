<template>
  <el-dialog
    title="转正"
    :visible.sync="dialogVisible"
    width="400px"
    custom-class="becomeRegularWorkerDialog"
    destroy-on-close
    v-loading="loading"
    :before-close="handleClose"
  >
    <div class="becomeRegularWorkerDialog__content">
      是否要手动发起{{ personName }}的转正流程，
      <br />
      并向LM发送试用期评估表?
    </div>
    <div class="becomeRegularWorkerDialog__inputData">
      <el-form
        ref="form"
        :label-position="'left'"
        label-width="80px"
        :model="form"
      >
        <el-form-item label="转正日期">
          <el-date-picker
            v-model="form.probationEndDate"
            type="date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
            placeholder="选择转正日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收件人">
          <el-autocomplete
            style="width: 100%"
            v-model="form.lineManager"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="选择转收件人"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="收件人邮箱">
          <el-input v-model="form.lineManagerEmail"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getLineManageEmailList, getPositiveInfo } from '@/api/employees'

export default {
  name: 'becomeRegularWorkerDialog',
  data() {
    return {
      dialogVisible: false,
      personName: '',
      loading: false,
      form: {
        lineManager: '',
        lineManagerEmail: '',
        probationEndDate: ''
      }
    }
  },
  methods: {
    getData(id) {
      const data = {
        id
      }
      getPositiveInfo(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
        }
      })
    },
    handleClose() {
      this.personName = ''
      this.form = {
        lineManager: '',
        lineManagerEmail: '',
        probationEndDate: ''
      }
      this.dialogVisible = false
    },
    show(val) {
      if (val) {
        this.personName = val.personName
        if (val.id) {
          this.loading = true
          this.getData(val.id)
        }
        this.dialogVisible = true
      }
    },
    submit() {
      const returnData = {
        ...this.form
      }
      this.$emit('onConfirm', returnData)
      this.handleClose()
    },
    querySearch(queryString, cb) {
      const data = {
        lineManager: queryString
      }
      getLineManageEmailList(data).then((res) => {
        if (res && res.code === 200) {
          let transferList = res.data
          const cbList = []
          if (transferList && transferList.length) {
            transferList.forEach((item) => {
              cbList.push({
                value: item.lineManager,
                businessMail: item.businessMail
              })
            })
            cb(cbList)
          } else {
            cb([])
          }
        }
      })
    },
    handleSelect(item) {
      if (item) {
        this.form.lineManagerEmail = item.businessMail
      }
    }
  }
}
</script>

<style lang="scss">
.becomeRegularWorkerDialog {
  &__content {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    padding-bottom: 16px;
    border-bottom: 1px solid #ebeef5;
  }
  &__inputData {
    margin-top: 15px;
  }
  .el-dialog__title {
    font-weight: 400;
  }
  .el-icon-close {
    display: block;
  }
  .becomeRegularWorkerDialog__contentLM {
    margin: 4px 30px 30px 30px;
  }
}
</style>
