<template>
  <el-dialog
    title="上传线下审批通过附件"
    :visible.sync="approvalAttachmentsDialogVisible"
    width="450px"
    class="promotionAttachmentsAuditDialog"
    append-to-body
    destroy-on-close
    :before-close="approvalAttachmentsDialogClose"
  >
    <el-form
      :model="approvalAttachmentsData"
      :inline="true"
      :label-position="'top'"
      ref="promotionAuditDialogDataForm"
    >
      <el-form-item label="附件信息(仅支持rar,zip,doc,docx,pdf格式。)">
        <pgUploadFile
          :id="approvalAttachmentsData.candidateInformationId"
          url="candidateUpload"
          :limit="3"
          :getFile="true"
          :isSuffix="true"
          @file="getFile"
          type="DegreeCertificate"
        />
      </el-form-item>
      <el-form-item label="备注" style="width: 100%">
        <el-input
          type="textarea"
          placeholder="请输入备注理由"
          v-model="approvalAttachmentsData.remarks"
          maxlength="100"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="approvalAttachmentsDialogClose">取 消</el-button>
      <el-button type="primary" @click="approvalAttachmentsDialogSubmit"
        >提交</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import pgUploadFile from '@/components/pg-uploadFileNotRequest/pg-uploadFile'
import { uploadPromotionPassFile } from '@/api/employees'
export default {
  name: 'promotionAttachmentsAuditDialog',
  components:{
    pgUploadFile,
  },
  data() {
    return {
      approvalAttachmentsDialogVisible: false,
      approvalAttachmentsDataRules: [],
      approvalAttachmentsData: {
        candidateInformationId: '',
        file: {},
        remarks: '',
        workflowId: '',
        id: ''
      }
    }
  },
  methods: {
    getFile(val) {
      console.log("val---",val)
      if (val) {
        this.approvalAttachmentsData.file = val
      }
    },
    show(val) {
      if (val) {
        Object.keys(this.approvalAttachmentsData).forEach((item) => {
          this.approvalAttachmentsData[item] = val[item]
        })
        this.approvalAttachmentsDialogVisible = true
      }
    },
    approvalAttachmentsDialogClose() {
      //这是晋升的代码
      this.approvalAttachmentsData = {
        candidateInformationId: '',
        file: {},
        remarks: '',
        workflowId: '',
        id: ''
      }
      this.approvalAttachmentsDialogVisible = false
    },
    approvalAttachmentsDialogSubmit() {
      const data = new FormData()
      data.append(
        'candidateInformationId',
        this.approvalAttachmentsData.candidateInformationId
      )

      data.append('remarks', this.approvalAttachmentsData.remarks)

      data.append('id', this.approvalAttachmentsData.id)

      // data.append('workflowId', this.approvalAttachmentsData.workflowId)
      if (Object.keys(this.approvalAttachmentsData.file).length !== 0) {
        let val = this.approvalAttachmentsData.file
        data.append('file', val.raw)
      }

      uploadPromotionPassFile(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('上传线下审批通过附件成功')
          this.approvalAttachmentsDialogClose()
          this.$emit('close')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.promotionAttachmentsAuditDialog {
}
</style>
