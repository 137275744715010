<script>
import { defineComponent, ref } from 'vue'
import { getItem } from '@/utils/storage'
export default defineComponent({
  props: {
    scope: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    let roleButton = ref([])
    let scope = props.scope

    let userName = getItem('userName')
    let status = ref(scope.status)
    const pushRoleButton = (val) => {
      roleButton.value = []
      roleButton.value.push(...val)
    }
    //是否在流程内
    if (status.value) {
      switch (status.value) {
        case '已发送，待反馈':
          pushRoleButton([
            'becomeRegularWorkerRoleButtonPositiveSendEmail',
            'becomeRegularWorkerRoleButtonAudit',
            'becomeRegularWorkerRoleButtonTerminate'
          ])
          break
        case '已反馈，待审核':
          pushRoleButton([
            'becomeRegularWorkerRoleButtonAudit',
            'becomeRegularWorkerRoleButtonTerminate'
          ])
          break
        case '已通过，待生效':
          pushRoleButton([
            'becomeRegularWorkerRoleButtonTerminate'
          ])
          break
        default:
          pushRoleButton([])
      }
    } else {
      roleButton.value = []
    }
    return {
      roleButton,
      status
    }
  },
  render(createElement, context) {
    const emit = (val) => {
      this.$emit(val, this.scope)
    }
    const getRoleButton = (type, name) => {
      const roleButton = {
        becomeRegularWorkerRoleButtonTerminate: (
          <el-button
            class="roleButton"
            type={type}
            role="positiveTerminate"
            v-permission
            size="mini"
            onclick={() => {
              emit('becomeRegularWorkerRoleButtonTerminate')
            }}
          >
            终止
          </el-button>
        ),
        becomeRegularWorkerRoleButtonAudit: (
          <el-button
            class="roleButton"
            type={type}
            role="positiveAudit"
            v-permission
            size="mini"
            onclick={() => {
              emit('becomeRegularWorkerRoleButtonAudit')
            }}
          >
            审核
          </el-button>
        ),
        becomeRegularWorkerRoleButtonPositiveSendEmail: (
          <el-button
            class="roleButton"
            type={type}
            role="positiveSendEmail"
            v-permission
            size="mini"
            onclick={() => {
              emit('becomeRegularWorkerRoleButtonPositiveSendEmail')
            }}
          >
            发送邮件
          </el-button>
        )
      }
      return roleButton[name]
    }
    const mapButton = () => {
      if (this.roleButton.length) {
        if (this.roleButton.length === 1) {
          return <div>{getRoleButton('', [this.roleButton[0]])}</div>
        } else if (this.roleButton.length === 2) {
          return (
            <div>
              {getRoleButton('', [this.roleButton[0]])}
              {getRoleButton('', [this.roleButton[1]])}
            </div>
          )
        } else if (this.roleButton.length > 2) {
          return (
            <div>
              {getRoleButton('', this.roleButton[0])}
              <el-dropdown triger="click">
                <el-button class="dropdownButton">
                  <svg-icon
                    icon="ellipsis"
                    class="tablePointSvgClass"
                    title="更多"
                  />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  {this.roleButton.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <el-dropdown-item>
                          {getRoleButton('text', item)}
                        </el-dropdown-item>
                      )
                    } else {
                      return null
                    }
                  })}
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          )
        }
      } else {
        return null
      }
    }
    const mapStatus = () => {
      let status = this.status
      if (status) {
        let splitStatus = status.split('，')
        if (splitStatus && splitStatus.length) {
          const isSplit = () => {
            if (splitStatus[1]) {
              return (
                <span>
                  <span class="circle"></span>
                  <span>{splitStatus[1]}</span>
                </span>
              )
            } else {
              return null
            }
          }
          return (
            <div class="statusText">
              <span>{splitStatus[0]}</span>
              {isSplit()}
            </div>
          )
        }
      }
    }
    return (
      <div class="becomeRegularWorkerRoleButton">
        {mapStatus()} {mapButton()}
      </div>
    )
  }
})
</script>

<style lang="scss" scoped>
.becomeRegularWorkerRoleButton {
  display: flex;
  div {
    &:first-child {
      margin-right: 14px;
    }
    .el-button {
      transform: scale(0.8);
      margin-right: -2px !important;
    }
    .dropdownButton {
      transform: scale(0.75);
    }
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e6a23c;
    margin-left: 8px;
    margin-right: 4px;
  }
  .statusText {
    line-height: 30px;
  }
}
</style>
