<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__renewTable"
    stripe
    ref="changeManagement__table__renewTableRef"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
  >
    <el-table-column
      key="englishName"
      prop="englishName"
      label="English Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.englishName }}</span
        >
      </template>
    </el-table-column>
    <el-table-column label="" class="warningTipLine" width="1">
      <template slot-scope="scope">
        <div class="warningTip" v-if="scope.row.showRenewTipOne">
          <svg-icon icon="tableTopIcon" />
          该员工LM未在一个月内填写续约确认表单，请及时处理
        </div>
      </template>
    </el-table-column>
    <el-table-column label="" class="warningTipLine" width="1">
      <template slot-scope="scope">
        <div class="warningTip" v-if="scope.row.showRenewTipTwo">
          <svg-icon icon="tableTopIcon" />
          该员工未在两周内完成合同续签，请及时处理
        </div>
      </template>
    </el-table-column>
    <el-table-column
      key="chineseName"
      prop="chineseName"
      label="Chinese Name"
      width="150"
    >
      <template slot-scope="scope">
        <span
          class="employees__container__table--textHover"
          @click="chatOnTheJobItem(scope.row)"
          >{{ scope.row.chineseName }}</span
        >
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in renewTableColumn"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column label="状态" width="380" align="left">
      <template slot-scope="scope">
        <renewTableRoleButton
          :scope="scope.row"
          @renewTableRoleButtonSendEmail="renewTableRoleButtonSendEmail"
          @renewTableRoleButtonAudit="renewTableRoleButtonAudit"
          @renewTableRoleButtonReceive="renewTableRoleButtonReceive"
          @renewTableRoleButtonCompletionRenewal="
            renewTableRoleButtonCompletionRenewal
          "
          @renewTableRoleButtonEmployeeNotification="
            renewTableRoleButtonEmployeeNotification
          "
        />
      </template>
    </el-table-column>
    <renewTableDialog ref="renewTableDialogRef" @close="queryApi" />
    <renewAuditDialog ref="renewAuditDialogRef" @close="queryApi" />
    <quitTableRoleButtonSendQuitEmailDialog
      ref="quitTableRoleButtonSendQuitEmailDialogRef"
      @close="queryApi"
    />
    <renewTableRoleButtonEmployeeNotificationDialog
      ref="renewTableRoleButtonEmployeeNotificationDialogRef"
      @close="queryApi"
    />
  </PocTable>
</template>

<script>
import { getItem, setItem } from '@/utils/storage'
import { getEmployeesList, terminationQuit } from '@/api/employees'
import { renewTableColumn } from '@/views/employee/columnConfig/renewTableColumn'
import renewTableRoleButton from '@/views/employee/pages/components/renewTableRoleButton'
import renewTableDialog from '@/views/employee/dialog/renewTableDialog'
import renewAuditDialog from '@/views/employee/dialog/renewAuditDialog'
import quitTableRoleButtonSendQuitEmailDialog from '@/views/employee/dialog/quitTableRoleButtonSendQuitEmailDialog'
import renewTableRoleButtonEmployeeNotificationDialog from '@/views/employee/dialog/renewTableRoleButtonEmployeeNotificationDialog'
import store from '@/store'
import md5 from "md5";
export default {
  mixins: [$PCommon.TableMixin],
  name: 'renewTable',
  components: {
    renewTableRoleButton,
    renewTableDialog,
    renewAuditDialog,
    quitTableRoleButtonSendQuitEmailDialog,
    renewTableRoleButtonEmployeeNotificationDialog
  },
  data() {
    return {
      renewTableColumn,
      tableData: [],
      loading: false
    }
  },
  mounted() {
    console.log(this.$router)
  },
  methods: {
    renewTableRoleButtonEmployeeNotification(val) {
      console.log(121212)
      if (val) {
        val.title = '发起员工通知'
        val.content = '是否要通知所选的员工进行线下合同流程?'
        this.$refs.renewTableRoleButtonEmployeeNotificationDialogRef.show(val)
      }
    },
    renewTableRoleButtonAudit(val) {
      this.$refs.renewAuditDialogRef.show(val)
    },
    renewTableRoleButtonSendEmail(row) {
      row.title = '发送邮件'
      row.flag = 'sendEmail'
      row.content = '是否要向该员工的LM再次发送合同续签确认?'
      this.$refs.renewTableDialogRef.show(row)
    },
    chatOnTheJobItem(row) {
      setItem('tabsClickData__yc', row)
      const obj = JSON.stringify({
        jumpFlag: 'goDetail',
        candidateInformationId: row.candidateInformationId,
        idNumber: row.idNumber,
        userName : md5(getItem("userName"))
      })
      let page = this.$router.resolve({
        path: '/employees/employeesItemDetails',
        query: {
          jumpParams: obj
        },
      })
      window.open(page.href, '_blank')
    },
    renewTableRoleButtonReceive(val) {
      if (val) {
        const data = {
          title: '提示',
          content: `是否要终止该员工的续签流程?`,
          id: val.candidateInformationId,
          flag: 'renewTableRoleButtonReceive'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    renewTableRoleButtonCompletionRenewal(val) {
      if (val) {
        const data = {
          title: '提示',
          content: `是否确认该员工已经完成合同续签?`,
          id: val.candidateInformationId,
          flag: 'renewTableRoleButtonCompletionRenewal'
        }
        this.$refs.quitTableRoleButtonSendQuitEmailDialogRef.show(data)
      }
    },
    termination(val) {
      if (val) {
        const data = {
          id: val.id
        }
        terminationQuit(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('终止成功')
            this.queryApi()
          }
        })
      }
    },
    chat(val) {
      if (val) {
        this.$refs.quitChatDialogRef.show(val)
      }
    },
    editButton(val) {
      if (val) {
        this.$refs.quitDialogRef.show(val)
      }
    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__postTransferTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.changeManagement__table__renewTable {
  :deep(.el-table) {
    .cell {
      white-space: pre-line;
      text-align: left;
      line-height: 13px;
    }
  }
}
.warningTip {
  width: 350px;
  height: 14px;
  position: absolute;
  left: 15px;
  top: 30px;
  z-index: 999;
  background: rgba(222, 57, 52, 0.1);
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #de3934;
  line-height: 14px;
}
</style>
