<template>
  <el-drawer
    :visible.sync="drawer"
    size="95%"
    custom-class="promotionDetailDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>晋升信息</div>
    </template>
    <div class="drawer_content AudiDataNext">
      <div class="AudiDataNext__EffectiveDate">
        <span>{{ effectiveDate }}</span>
        <span>生效日期</span>
      </div>
        <descriptions :descriptionData="listData" />
        <div class="AudiDataNext__AudiDataNextTable">
        <PocTable
          v-loading="loading"
          :data="positionNextTableData"
          :max-height="100000000"
          class="AudiDataNext__AudiDataNextTable__positionTableData"
          hidepage
          stripe
        >
          <el-table-column
            v-for="item in positionTableDataColumn"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          ></el-table-column>
        </PocTable>
        <PocTable
          v-loading="loading"
          :data="SalaryNextTableData"
          :max-height="100000000"
          class="AudiDataNext__AudiDataNextTable__positionTableData"
          hidepage
          stripe
        >
          <el-table-column
            v-for="(item, index) in SalaryTableDataColumn"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="flex">
                <div v-if="scope.row[item.key]">
                  {{ scope.row[item.key] }}
                </div>
                <div
                  class="increase"
                  v-if="item.key == 'salaryAfter' && scope.$index == 2"
                >
                  （较晋升前涨幅{{ Increase }}）
                </div>
              </div>
            </template>
          </el-table-column>
        </PocTable>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import { mergeDesDataOfPort } from "@/utils/util";

export default {
  name: "promotionDetailDialog",
  components:{descriptions},
  data(){
    return{
      drawer:false,
      workflowId:'',
      rowData:[],
      Increase: '0%',
      loading: false,
      positionNextTableData: [],
      SalaryNextTableData: [],
      positionTableDataColumn: [
        {
          key: 'position',
          name: 'Position',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'positionBefore',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'positionAfter',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      SalaryTableDataColumn: [
        {
          key: 'salary',
          name: 'Position',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'salaryBefore',
          name: '晋升前',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'salaryAfter',
          name: '晋升后',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        }
      ],
      listData: [
        {
          title: '',
          column: 5,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 8
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: '',
              lg: 8
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: '',
              lg: 8
            },
          ]
        }
      ],
      effectiveDate:'',
    }
  },
  methods:{
    getData(){
      let params = {
        workflowId:this.workflowId
      }
      this.$Api.staff.promotionDetail(params)
        .then(({code,data})=>{
          if(code === 200 && data){
            this.effectiveDate = data.effectiveDate
            mergeDesDataOfPort(this.listData, data)
            this.initData(data)
          }
        })
    },
    initData(data){
      this.positionNextTableData = []
      this.SalaryNextTableData = []
      this.positionNextTableData.push({position:'Job Title EN',positionBefore:data.beforePromotionJobTitleEn,positionAfter:data.afterPromotionJobTitleEn})
      this.positionNextTableData.push({position:'Job Title CN',positionBefore:data.beforePromotionJobTitleCn,positionAfter:data.afterPromotionJobTitleCn})
      this.positionNextTableData.push({position:'Job Grade',positionBefore:data.beforePromotionJobGrade,positionAfter:data.afterPromotionJobGrade})
      // this.positionNextTableData.push({position:'Annual Leave Days',positionBefore:data.beforePromotionAnnualLeaveDays,positionAfter:data.afterPromotionAnnualLeaveDays})

      this.SalaryNextTableData.push({salary:'Base Salary(CNY)',salaryBefore:data.beforePromotionBaseSalaryCny,salaryAfter:data.afterPromotionBaseSalaryCny})
      this.SalaryNextTableData.push({salary:'13 Month Salary',salaryBefore:data.beforeMonthSalary13,salaryAfter:data.afterMonthSalary13})
      this.SalaryNextTableData.push({salary:'Annual Total Salary',salaryBefore:data.beforeAnnualTotalSalary,salaryAfter:data.afterAnnualTotalSalary})
      // this.SalaryNextTableData.push({salary:'Annual Leave Days',salaryBefore:data.beforePromotionAnnualLeaveDays,salaryAfter:data.afterPromotionAnnualLeaveDays})
      this.SalaryNextTableData.push({salary:'Relocation Allowance',salaryBefore:data.beforePromotionRelocationAllowance,salaryAfter:data.afterPromotionRelocationAllowance})
      this.SalaryNextTableData.push({salary:'Benefits(CNY)',salaryBefore:data.beforePromotionBenefitsCny,salaryAfter:data.afterPromotionBenefitsCny})
      this.SalaryNextTableData.push({salary:'Target Bonus %',salaryBefore:data.beforePromotionTargetBonus,salaryAfter:data.afterPromotionTargetBonus})
      this.SalaryNextTableData.push({salary:'Contractural Target Bonus %',salaryBefore:data.beforePromotionContracturalTargetBonus,salaryAfter:data.afterPromotionContracturalTargetBonus})
      this.SalaryNextTableData.push({salary:'Notice Period',salaryBefore:data.beforePromotionNoticePeriodMonth,salaryAfter:data.afterPromotionNoticePeriodMonth})

      let money =((data.afterAnnualTotalSalary -data.beforeAnnualTotalSalary) /
          data.beforeAnnualTotalSalary) *100
      this.Increase = money.toFixed(2) + '%'
    },
    show(val) {
      if (val) {
        this.workflowId = val.workflowId
        this.hcIsBroughtOut = val.hcIsBroughtOut
        if (this.workflowId) {
          this.getData()
          this.drawer = true
        } else {
          this.$message.warning('缺失id')
        }
      }
    },
    close() {
      this.workflowId = ''
      this.tableData = []
      this.drawer = false
    },
  }
};
</script>

<style lang="scss" scoped>
.promotionDetailDialog {
  .effective-date{
    position: relative;
    top:18px;
    left: 112px;
    width: 141px;
    font-size: 14px;
    font-weight: 600;
    span{
      font-size: 12px;
    }
  }
  .el-drawer__header {
    margin-bottom: 16px !important;
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 110px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .hc__text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #868686;
    margin-right: 13px;
  }
  .switchText {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
    margin-left: 4px;
  }
}
.dialog-footer {
  text-align: right;
  padding-right: 120px;
  //padding-top: 30px;
  //padding-bottom: 30px;
}
:deep(.el-drawer__close-btn) {
  .el-dialog__close {
    display: block !important;
    padding-right: 100px;
  }
}
:deep(.el-drawer__header) {
  margin-bottom: 16px;
}
.AudiDataNext__AudiDataNextTable__positionTableData {
  .increase {
    color: #eebd75;
  }
}

.leftBox {
  width: 100%;
  .ftit {
    margin-top: 10px;
    color: #999;
  }
}
.AudiDataNext {
  &__EffectiveDate {
    width: 224px;
    height: 56px;
    background: #f4f4f4;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px 12px 16px;
    margin-bottom: 10px;
    span {
      &:first-child {
        font-size: 24px;
        font-family: Oswald-Bold;
        font-weight: normal;
        color: #323435;
        margin-right: 8px;
      }
      &:last-child {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323435;
      }
    }
  }
  &__AudiDataNextTable {
    display: flex;
    margin-top: 12px;
    &__positionTableData {
      margin-right: 20px;
    }
  }
}
</style>

