<script>
import { defineComponent, ref, getCurrentInstance  } from 'vue'
import { getItem } from '@/utils/storage'


export default defineComponent({
  props: {
    scope: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    const { $dayjs } = getCurrentInstance().proxy;
    let roleButton = ref([
      'quitTableRoleButtonChat',
    ])
    let scope = props.scope
    let userRole = getItem('userRole') || ''
    let status = ref(scope.status)
    let haveQuota = ref(null);
    let endDate = $dayjs(scope.joinDate).add(scope.probationPeriodMonth,'month').format('YYYY-MM-DD');
    let isDuring = $dayjs(scope.quitDate).isBetween(scope.joinDate, endDate, 'day', '[)');
    haveQuota.value = isDuring;
    isDuring ? console.log('点击生成配额-离职', '开始-期间-结束',scope.joinDate+'---'+scope.quitDate+'---'+endDate, isDuring) : ''
    const pushRoleButton = (val) => {
      //'quitTableRoleButtonMail'
      roleButton.value = [
        'quitTableRoleButtonChat',
      ]
      roleButton.value.push(...val)
    }
    //是否在流程内
    if (status.value) {
      if (userRole.includes('HRBP')) {
        console.log('是HRBP')
        switch (status.value) {
          case '待提交':
            pushRoleButton([
              'quitTableRoleButtonEditButton',
              'quitTableRoleButtonTermination'
            ])
            break
          case '已提交，待通知员工':
            pushRoleButton([
              'quitTableRoleButtonEditButton',
              'quitTableRoleButtonTermination',
              'quitTableRoleButtonSendQuitEmail'
            ])
            break
          case '已通知员工，待生效':
            pushRoleButton([
              'quitTableRoleButtonEditButton',
              'quitTableRoleButtonTermination',
              'quitTableRoleButtonSendQuitEmail'
            ])
            break
          case '已离职':
            if(haveQuota.value) {
              pushRoleButton([
                'quitTableRoleButtonGenerateQuota'
              ])
            }
            break
          default:
            pushRoleButton([])
        }
      } else if (userRole.includes('OPS') || userRole.includes('Payroll')) {
        switch (status.value) {
          case '已提交，待通知员工':
            pushRoleButton(['quitTableRoleButtonSendQuitEmail'])
            break
          case '已通知员工，待生效':
            pushRoleButton(['quitTableRoleButtonSendQuitEmail','quitTableRoleButtonResignationCertificate'])
            break
          case '已离职':
            pushRoleButton(['quitTableRoleButtonResignationCertificate'])
            break
          default:
            pushRoleButton([])
        }
      }
    } else {
      roleButton.value = [
        'quitTableRoleButtonChat',
      ]
    }
    return {
      roleButton,
      status
    }
  },
  render(createElement, context) {
    const emit = (val) => {
      this.$emit(val, this.scope)
    }
    const getRoleButton = (type, name) => {
      const roleButton = {
        quitTableRoleButtonEditButton: (
          <el-button
            class="roleButton"
            type={type}
            role="resignEdit"
            v-permission
            size="mini"
            onclick={() => {
              emit('quitTableRoleButtonEditButton')
            }}
          >
            编辑
          </el-button>
        ),
        quitTableRoleButtonChat: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignView"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonChat')
            }}
          >
            查看
          </el-button>
        ),
        // quitTableRoleButtonMail: (
        //   <el-button
        //     class="roleButton"
        //     type={type}
        //     size="mini"
        //     role="resignView"
        //     v-permission
        //     onclick={() => {
        //       emit('quitTableRoleButtonMail')
        //     }}
        //   >
        //     发送离职手续邮件
        //   </el-button>
        // ),
        quitTableRoleButtonReceive: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignIn"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonReceive')
            }}
          >
            接收
          </el-button>
        ),
        quitTableRoleButtonSendQuitEmail: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignSendEmail"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonSendQuitEmail')
            }}
          >
            发送离职手续邮件
          </el-button>
        ),
        quitTableRoleButtonTermination: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignTermination"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonTermination')
            }}
          >
            终止
          </el-button>
        ),
        quitTableRoleButtonGenerateQuota: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignCreateNewHc"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonGenerateQuota')
            }}
          >
            生成配额
          </el-button>
        ),
        quitTableRoleButtonResignationCertificate: (
          <el-button
            class="roleButton"
            type={type}
            size="mini"
            role="resignationCertificate"
            v-permission
            onclick={() => {
              emit('quitTableRoleButtonResignationCertificate')
            }}
          >
            离职证明
          </el-button>
        ),
      }
      return roleButton[name]
    }
    const mapButton = () => {
      // this.roleButton.push('chat')
      if (this.roleButton.length) {
        if (this.roleButton.length === 1) {
          return <div>{getRoleButton('', [this.roleButton[0]])}</div>
        } else if (this.roleButton.length === 2) {
          return (
            <div>
              {getRoleButton('', [this.roleButton[0]])}
              {getRoleButton('', [this.roleButton[1]])}
            </div>
          )
        } else if (this.roleButton.length > 2) {
          return (
            <div>
              {getRoleButton('', this.roleButton[0])}
              <el-dropdown triger="click">
                <el-button class="dropdownButton">
                  <svg-icon
                    icon="ellipsis"
                    class="tablePointSvgClass"
                    title="更多"
                  />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  {this.roleButton.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <el-dropdown-item>
                          {getRoleButton('text', item)}
                        </el-dropdown-item>
                      )
                    } else {
                      return null
                    }
                  })}
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          )
        }
      } else {
        return null
      }
    }
    const mapStatus = () => {
      let status = this.status
      if (status) {
        let splitStatus = status.split('，')
        if (splitStatus && splitStatus.length) {
          const isSplit = () => {
            if (splitStatus[1]) {
              return (
                <span>
                  <span class="circle"></span>
                  <span>{splitStatus[1]}</span>
                </span>
              )
            } else {
              return null
            }
          }
          return (
            <div class="statusText">
              <span>{splitStatus[0]}</span>
              {isSplit()}
            </div>
          )
        }
      }
    }
    return (
      <div class="quitTableRoleButton">
        {mapStatus()} {mapButton()}
      </div>
    )
  }
})
</script>

<style lang="scss" scoped>
.quitTableRoleButton {
  display: flex;
  div {
    &:first-child {
      margin-right: 14px;
    }
    .el-button {
      transform: scale(0.8);
      margin-right: -2px !important;
    }
    .dropdownButton {
      transform: scale(0.75);
    }
  }
  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e6a23c;
    margin-left: 8px;
    margin-right: 4px;
  }
  .statusText {
    line-height: 30px;
  }
}
</style>
