<template>
  <el-drawer
    :visible.sync="drawer"
    size="93%"
    custom-class="dataUpdateDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    v-loading="loading"
    element-loading-text="正在获取中..."
    element-loading-spinner="el-icon-loading"
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>数据导入</div>
    </template>
    <div class="drawer_content">
      <div class="flexBet" style="margin-bottom: 12px">
        <div class="flexBet">
          <el-button @click="csTemplateExport">下载模板</el-button>
          <el-button @click="downloadErrorData">下载错误原因</el-button>
        </div>
        <div>
          <el-upload
            class="buttonunActive"
            style="display: inline-block"
            action="#"
            :on-change="onChangeFile"
          >
            <el-button
              class="buttonActive"
            >上传</el-button
            >
          </el-upload>
          <el-button
            @click="confirm"
            type="primary"
            v-loading.fullscreen.lock="fullscreenLoading"
          >确认入库</el-button>
        </div>
      </div>
      <PocTable
        ref="dataUpdateDialogTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :page-size.sync="pageConfig.pageSize"
        size="mini"
        stripe
        :max-height="100000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          label="序号"
          type="index"
          width="60">
        </el-table-column>
        <el-table-column
          v-for="item in dataUpColumn"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          :label="errorLabel"
          key="checkMessage"
          align="left"
        >
          <template v-slot="scope">
            <div style="color: #DE3934">
              {{ scope.row['checkMessage'] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60" fixed="right" align="center">
          <template v-slot="scope">
            <svg-icon
              icon="delete"
              class="tablePointSvgClass"
              title="删除"
              @click.native="deImportInfo(scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
    </div>
  </el-drawer>
</template>

<script>

export default {
  name: "dataUpdateDialog",
  mixins: [$PCommon.TableMixin],
  data(){
    return{
      drawer:false,
      loading:false,
      dataUpColumn:[
        {
          key: 'nationality',
          name: 'Nationality',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },{
          key: 'englishName',
          name: 'English Name',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },{
          key: 'idNumber',
          name: 'ID/Passport No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },{
          key: 'careerSettingId',
          name: 'Career Setting ID',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 140 // 默认宽度，像素
        },{
          key: 'lionId',
          name: 'Lion ID of employee',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 170 // 默认宽度，像素
        },{
          key: 'businessEmail',
          name: 'Business Email',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 180 // 默认宽度，像素
        },{
          key: 'hrbp',
          name: 'HRBP',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
      ],
      tableData:[],
      pageConfig:{},
      errorCount:0,
      fullscreenLoading:false,
    }
  },
  computed:{
    errorLabel(){
      return `错误原因（${this.errorCount}）`
    }
  },
  methods:{
    show(){
      this.drawer = true
      // this.pageConfig.pageNum = 1
      // this.queryApi()
      // this.csErrorCount()
      this.pageConfig.pageSize = 10
      this.pageConfig.total = 0
    },
    close(){
      this.$confirm('当前数据尚未入库，关闭页面，数据将丢失，是否继续？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.tableData = []
          this.errorCount = 0
          this.$Api.candidate.deAllImportInfo()
          this.drawer = false
        })
        .catch(action => {
        });
    },
    downloadErrorData(){
      if(this.errorCount === 0){
        this.$message.error('没有错误数据')
        return
      }
      this.$Api.candidate.csErrorExport()
    },
    csTemplateExport(){
      this.$Api.candidate.csTemplateExport()
    },
    confirm(){
      if(this.errorCount > 0){
        this.$message.error('请先处理错误数据后入库')
        return
      }
      if(this.tableData.length === 0){
        this.$message.error('请先上传数据')
        return
      }
      this.fullscreenLoading = true
      this.$Api.candidate.csImportInfoUpdate()
        .then(({code,msg})=>{
          this.fullscreenLoading = false
          if(code === 200){
            this.$message.success(msg)
            setTimeout(()=>{
              this.tableData = []
              this.errorCount = 0
              this.$Api.candidate.deAllImportInfo()
              this.drawer = false
            },800)
          }
        })
    },
    csErrorCount(){
      this.$Api.candidate.csErrorCount().then(({code,data})=>{
        if(code === 200){
          this.errorCount = data
        }
      })
    },
    deImportInfo(row){
      this.$confirm('是否删除这条数据？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(()=>{
        this.$Api.candidate.deImportInfo({'id':row.id}).then(({code})=>{
          if(code === 200){
            this.$message.success('删除成功')
            this.tableData = this.tableData.filter(item=>item.id !== row.id)
            this.pageConfig.total -= 1
            this.csErrorCount()
          }
        })
      }).catch(()=>{

      })
    },
    onChangeFile(file) {
      if (file.status !== 'ready') return;
      if (file.name.endsWith('.xlsx')) {
        this.upload(file)
      }else {
        this.$message.error('只能上传xlsx格式的文件')
      }
    },
    upload(file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      let loadingInstance = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$Api.candidate.importCsInfo(formData)
        .then(({code,data,msg})=>{
          loadingInstance.close()
          if (code === 200) {
            this.$message({
              type: 'success',
              message: msg
            })
            this.tableData = data.list || []
            this.pageConfig.total = data.total
            this.csErrorCount()
          }
        })
    },
    queryApi(){
      this.loading = true
      this.$Api.candidate.csImportInfoList({...this.pageConfig})
        .then(({code,data})=>{
          this.loading = false
          if(code === 200){
            this.tableData = data.list || []
            this.pageConfig.total = data.total
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.dataUpdateDialog{
  .el-drawer__header {
    div {
      &:first-child {
       display: inline-block;
       padding-left: 100px;
       font-size: 21px;
       font-family: PingFangSC-Medium, PingFang SC;
       font-weight: 500;
       color: #303133;
     }
      u{
        margin-left: 20px;
        font-size: 14px;
        cursor:pointer;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .el-drawer__close-btn{
    margin-right: 100px;
  }
  .delBtn{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}

</style>
<style>
.el-drawer{
  border-radius: 16px 16px 0 0 !important;
}
</style>
