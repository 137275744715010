<template>
  <div class="AudiData">
    <descriptions :descriptionData="descriptionData" />
    <descriptions :descriptionData="descriptionDataAudit" />
    <el-form
      class="filter-form rpm-query-from el-form"
      :rules="AudiRules"
      :model="AudiData"
      :inline="true"
      :label-position="'top'"
      ref="AudiDataRef"
    >
      <PGTitle title="Position">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              label="New Job Title EN"
              prop="afterPromotionJobTitleEn"
            >
              <el-input v-model="AudiData.afterPromotionJobTitleEn"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="New Job Title CN"
              prop="afterPromotionJobTitleCn"
            >
              <el-input v-model="AudiData.afterPromotionJobTitleCn"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Job Grade" prop="afterPromotionJobGrade">
              <el-select
                v-model="AudiData.afterPromotionJobGrade"
                clearable
                filterable
                @change="JobGradeChange"
                placeholder="Job Grade"
              >
                <el-option
                  v-for="item in jobGradeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
<!--            <el-form-item-->
<!--              label="Annual Leave Days"-->
<!--              prop="afterPromotionAnnualLeaveDays"-->
<!--            >-->
<!--              <el-input-->
<!--                disabled-->
<!--                v-model="AudiData.afterPromotionAnnualLeaveDays"-->
<!--              >-->
<!--              </el-input>-->
<!--            </el-form-item>-->
          </el-col>
        </el-row>
      </PGTitle>
      <PGTitle title="Salary">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              label="Annual Increase Amount"
              prop="afterPromotionAnnualIncreaseAmount"
            >
              <el-input
                @input="countSum"
                v-model="AudiData.afterPromotionAnnualIncreaseAmount"
                @blur="costChange($event,'afterPromotionAnnualIncreaseAmount')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="New Annual Base Salary (CNY)"
              prop="afterPromotionBaseSalaryCny"
            >
              <el-input disabled v-model="AudiData.afterPromotionBaseSalaryCny"
                        @blur="costChange($event,'afterPromotionBaseSalaryCny')"
                        @input="limitInput($event,'afterPromotionBaseSalaryCny')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="Increase%"
              prop="afterPromotionAnnualBaseSalaryIncrease"
            >
              <el-input
                disabled
                v-model="AudiData.afterPromotionAnnualBaseSalaryIncrease"
                @blur="costChange($event,'afterPromotionAnnualBaseSalaryIncrease')"
                @input="limitInput($event,'afterPromotionAnnualBaseSalaryIncrease')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="New 13 Monthly Salary"
              prop="afterPromotionThirteenSalaryName"
            >
              <el-switch
                v-model="AudiData.afterPromotionThirteenSalaryName"
                active-color="#6EBAAF"
                inactive-color="#E36124"
              >
              </el-switch>
              <span
                class="switchText"
                v-if="AudiData.afterPromotionThirteenSalaryName"
                >是</span
              >
              <span class="switchText" v-else>否</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Benefits(CNY)">
              <el-input v-model="AudiData.afterPromotionBenefitsCny" @blur="costChange($event,'afterPromotionBenefitsCny')" @input="limitInput($event,'afterPromotionBenefitsCny')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Annual Allowance">
              <el-popover
                placement="bottom"
                v-model="popoverShow"
                width="400"
                trigger="click"
              >
                <el-form
                  class="popoverForm"
                  ref="form"
                  :model="AudiData"
                  label-width="180px"
                >
                  <el-form-item
                    label="Annual Housing Allowance"
                  >
                    <el-input
                      v-model="AudiData.afterPromotionAnnualHousingAllowance"
                      @blur="costChange($event,'afterPromotionAnnualHousingAllowance')"
                      @input="limitInput($event,'afterPromotionAnnualHousingAllowance')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Annual Living Allowance"
                  >
                    <el-input
                      v-model="AudiData.afterPromotionAnnualLivingAllowance"
                      @blur="costChange($event,'afterPromotionAnnualLivingAllowance')"
                      @input="limitInput($event,'afterPromotionAnnualLivingAllowance')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Annual Education Allowance"
                  >
                    <el-input
                      v-model="AudiData.afterPromotionAnnualEducationAllowance"
                      @blur="costChange($event,'afterPromotionAnnualEducationAllowance')"
                      @input="limitInput($event,'afterPromotionAnnualEducationAllowance')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Annual Other Allowance"
                  >
                    <el-input
                      v-model="AudiData.afterPromotionAnnualOtherAllowance"
                      @blur="costChange($event,'afterPromotionAnnualOtherAllowance')"
                      @input="limitInput($event,'afterPromotionAnnualOtherAllowance')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item> Total {{ totalAnnual }} </el-form-item>
                  <el-form-item>
                    <el-button @click="popoverShow = false">取消</el-button>
                    <el-button type="primary" @click="confirmAnnual"
                      >确认</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-input
                  slot="reference"
                  v-model="AudiData.afterPromotionAnnualAllowance"
                  @blur="costChange($event,'afterPromotionAnnualAllowance')"
                  @input="limitInput($event,'afterPromotionAnnualAllowance')"
                />
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Relocation Allowance">
              <el-input v-model="AudiData.afterPromotionRelocationAllowance"
                    @blur="costChange($event,'afterPromotionRelocationAllowance')"
                    @input="limitInput($event,'afterPromotionRelocationAllowance')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Target Bonus %">
              <el-input v-model="AudiData.afterPromotionTargetBonus"
                        @blur="costChange($event,'afterPromotionTargetBonus')"
                        @input="limitInput($event,'afterPromotionTargetBonus')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Contractural Target Bonus %">
              <el-input
                v-model="AudiData.afterPromotionContracturalTargetBonus"
                @blur="costChange($event,'afterPromotionContracturalTargetBonus')"
                @input="limitInput($event,'afterPromotionContracturalTargetBonus')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Effective Date" prop="effectiveDate">
              <el-date-picker
                v-model="AudiData.effectiveDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择时间"
                style="width: 100%"
              >
              </el-date-picker>
              <div class="btnRed" v-if="dateTitle">
                *请确认，目前所选日期不是1号
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Notice Period">
              <el-input
                v-model="AudiData.afterPromotionNoticePeriodMonth"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </PGTitle>
    </el-form>
  </div>
</template>

<script>
import PGTitle from '@/components/PG-title/PG-title'
import descriptions from '@/components/descriptions/descriptions'
import { baseDataByCode, promotionInfo } from '@/api/employees'
import { check, getPercent, mergeDesDataOfPort, typeRange } from '@/utils/util'

export default {
  name: 'AudiData',
  components: {
    PGTitle,
    descriptions
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalAnnual() {
      let sum =
        Number(this.AudiData.afterPromotionAnnualHousingAllowance) +
        Number(this.AudiData.afterPromotionAnnualLivingAllowance) +
        Number(this.AudiData.afterPromotionAnnualEducationAllowance) +
        Number(this.AudiData.afterPromotionAnnualOtherAllowance)
      return sum.toFixed(2)
    }
  },
  created() {
    this.addRules()
    this.initData()
  },
  data() {
    return {
      dateTitle: false,
      popoverShow: false,
      AudiRules: {},
      jobGradeList: [],
      annualAllowanceFormData: {},
      AudiData: {
        afterPromotionJobTitleEn: '',
        beforePromotionBaseSalaryCny: '',
        afterPromotionJobTitleCn: '',
        afterPromotionJobGrade: '',
        // afterPromotionAnnualLeaveDays: '',
        afterPromotionAnnualIncreaseAmount: '',
        afterPromotionBaseSalaryCny: '',
        afterPromotionAnnualBaseSalaryIncrease: '',
        afterPromotionThirteenSalaryName: '',
        afterPromotionBenefitsCny: '',
        afterPromotionAnnualAllowance: '',
        afterPromotionRelocationAllowance: '',
        afterPromotionTargetBonus: '',
        afterPromotionAnnualHousingAllowance: '',
        afterPromotionAnnualLivingAllowance: '',
        afterPromotionAnnualEducationAllowance: '',
        afterPromotionAnnualOtherAllowance: '',
        afterPromotionContracturalTargetBonus: '',
        effectiveDate: '',
        afterPromotionNoticePeriodMonth: '',
      },
      descriptionDataAudit: [
        {
          title: '上次晋升信息',
          column: 4,

          content: [
            {
              label: 'Last Increase Date',
              key: 'lastIncreaseDate',
              value: '',
              lg: 8
            },
            {
              label: 'HRBP',
              key: 'hrbpName',
              value: '',
              lg: 8
            },
            {
              label: 'Annual Increase Amount',
              key: 'annualIncreaseAmount',
              value: '',
              lg: 8
            },
            {
              label: 'Annual Base Salary Increase%',
              key: 'annualBaseSalaryIncrease',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      descriptionData: [
        {
          title: '基础信息',
          column: 4,

          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: '',
              lg: 8
            },
            {
              label: 'Current Annual Base Sallary(CNY)',
              key: 'beforePromotionBaseSalaryCny',
              value: '',
              lg: 8
            },
            {
              label: 'Current Job Title EN Title',
              key: 'beforePromotionJobTitleEn',
              value: '',
              lg: 8
            },
            {
              label: 'Current 13 Monthly Salary',
              key: 'beforePromotionThirteenSalaryName',
              value: '',
              lg: 8
            },
            {
              label: 'Notice Period',
              key: 'beforePromotionNoticePeriodMonth',
              value: '',
              lg: 8
            },
          ]
        }
      ]
    }
  },
  methods: {
    costChange(value, name){
      let temp = this.AudiData[name].toString();
      let str = temp.split('.')
      if(str[1]){
        if(str[1].length === 1){
          temp = temp + '0'
        }
      }else {
        temp = temp + '.00'
      }
      this.AudiData[name] = temp;
    },
    limitInput(value, name) {
      let temp = value.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.AudiData[name] = temp;
    },
    ruleDate(rule, value, callback) {
      if (value) {
        let str = value.slice(8, 10)
        if (str != '01') {
          this.dateTitle = true
          // callback(new Error('*请确认，目前所选日期不是1号'))
          callback()
        } else {
          this.dateTitle = false
          callback()
        }
      }
    },
    addRules() {
      Object.keys(this.AudiData).forEach((item) => {
        if (item == 'effectiveDate') {
          this.AudiRules[item] = [
            {
              required: true,
              trigger: 'blur',
              message: 'The field is required'
            },
            { required: false, trigger: 'blur', validator: this.ruleDate }
          ]
        } else {
          this.AudiRules[item] = [
            {
              required: true,
              trigger: 'blur',
              message: 'The field is required'
            }
          ]
        }
      })
      // // this.AudiRules['effectiveDate'].push({required: true, validator: this.ruleDate})
      // console.log('AudiRules', this.AudiRules)
    },
    confirmAnnual() {
      this.AudiData.afterPromotionAnnualAllowance = this.totalAnnual
      this.popoverShow = false
    },
    initData() {
      if (this.rowData) {
        let candidateInformationId = this.rowData.candidateInformationId
        const data = {
          candidateInformationId,
          flag: this.rowData.flag
        }
        promotionInfo(data).then((res) => {
          if (res && res.code === 200) {
            this.AudiData = { ...this.AudiData, ...res.data }
            mergeDesDataOfPort(this.descriptionData, res.data)
            mergeDesDataOfPort(this.descriptionDataAudit, res.data)
            this.countSum()
          }
        })
        const data1 = {
          vo: 'hr_job_grading'
        }
        baseDataByCode(data1).then((res) => {
          if (res && res.code === 200) {
            if (res.data && check(res.data) === typeRange.array) {
              let options = []
              options = res.data.map((item) => {
                return {
                  label: item,
                  value: item
                }
              })
              this.jobGradeList = options
            }
          }
        })
      }
    },
    JobGradeChange(val) {
      const checkObj = {
        a: {
          arr: ['M', 'L', 'K', 'J', 'H', 'G', 'F', 'E'],
          val: '20'
        },
        b: {
          arr: ['O', 'N'],
          val: '15'
        },
        c: {
          arr: ['S', 'R', 'Q', 'P'],
          val: '12'
        }
      }
      Object.keys(checkObj).forEach((item) => {
        if (checkObj[item].arr.includes(val)) {
          this.AudiData.afterPromotionAnnualLeaveDays = checkObj[item].val
        }
      })
    },
    countSum() {
      let beforePromotionBaseSalaryCny = Number(
        this.AudiData.beforePromotionBaseSalaryCny
      )
      let afterPromotionAnnualIncreaseAmount = Number(
        this.AudiData.afterPromotionAnnualIncreaseAmount
      )
      /*
      以前的薪资 + 涨的薪资 = 新的
       */
      this.AudiData.afterPromotionBaseSalaryCny = (
        afterPromotionAnnualIncreaseAmount + beforePromotionBaseSalaryCny
      ).toFixed(2)
      let a =
        parseFloat(this.AudiData.afterPromotionAnnualIncreaseAmount) /
        parseFloat(this.AudiData.beforePromotionBaseSalaryCny)
      console.log('a11', this.AudiData.beforePromotionBaseSalaryCny)
      if (a && this.AudiData.beforePromotionBaseSalaryCny) {
        this.AudiData.afterPromotionAnnualBaseSalaryIncrease =
          (a * 100).toFixed(2) + '%'
      } else {
        this.AudiData.afterPromotionAnnualBaseSalaryIncrease = '0.00%'
      }
      // let aa = "17000a";
      // let bb = 0;
      // let a =
      //   parseFloat(aa) /
      //   parseFloat(bb)
      //   console.log("a",a)
      // if (a) {
      //   this.AudiData.afterPromotionAnnualBaseSalaryIncrease =
      //     (a * 100).toFixed(2) + '%'
      // }
      console.log('a', this.AudiData.afterPromotionAnnualBaseSalaryIncrease)
    },
    async getData(val) {
      if (val && val === 'staging') {
        return { ...this.AudiData }
      } else {
        let data = {}
        await this.$refs.AudiDataRef.validate((valid) => {
          if (valid) {
            data = { ...this.AudiData }
          } else {
            data = false
          }
        })
        return data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switchText {
  margin-left: 5px;
}
.AudiData {
  .popoverForm {
    :deep(.el-form-item__label) {
      word-break: normal;
      overflow: auto;
    }
  }
}
.btnRed {
  color: red;
  font-size: 12px;
}
</style>
