<template>
  <PocTable
    v-loading="loading"
    :data="tableData"
    :max-height="100000000"
    :current-page.sync="pageConfig.pageNum"
    :total="pageConfig.total"
    class="changeManagement__table__becomeRegularWorkerTable"
    stripe
    ref="changeManagement__table__becomeRegularWorkerTableRef"
    @size-change="pageSizeChange"
    @page-current-change="pageChange"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="43"> </el-table-column>
    <el-table-column label="" class="warningTipLine" width="1">
      <template slot-scope="scope">
        <div class="warningTip" v-if="scope.row.showPositiveTip">
          <svg-icon icon="tableTopIcon" />
          该员工试用期{{ countLastDay(scope.row) }}，
          LM未填写试用期评估表，请及时处理
        </div>
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in becomeRegularWorkerTableColumnOptions"
      :key="item.key"
      :prop="item.key"
      :label="item.name"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :width="item.width"
      align="left"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column label="状态" fixed="right" width="280" align="left">
      <template slot-scope="scope">
        <div
          class="changeManagement__table__becomeRegularWorkerTable__statusButton"
        >
          <div
            class="changeManagement__table__becomeRegularWorkerTable__statusButton__button"
          >
            <becomeRegularWorkerRoleButton
              :scope="scope.row"
              @becomeRegularWorkerRoleButtonPositiveSendEmail="sendEmail"
              @becomeRegularWorkerRoleButtonAudit="audit"
              @becomeRegularWorkerRoleButtonTerminate="terminate"
            />
          </div>
        </div>
      </template>
    </el-table-column>
    <PositiveReviewDialog ref="PositiveReviewDialogRef" @close="queryApi" />
    <becomeRegularWorkerTableTwoDialog
      ref="becomeRegularWorkerTableTwoDialogRef"
      @close="queryApi"
    />
    <terminateReviewDialog ref="terminateReviewDialogRef" @close="queryApi" />
  </PocTable>
</template>

<script>
import { becomeRegularWorkerTableColumnOptions } from '@/views/employee/columnConfig/becomeRegularWorkerTableColumnOptions'
import { getEmployeesList, getPositiveInfo } from '@/api/employees'
import PositiveReviewDialog from '@/views/employee/dialog/PositiveReviewDialog'
import terminateReviewDialog from '@/views/employee/dialog//terminateReviewDialog.vue'
import { getItem, setItem } from '@/utils/storage'
import becomeRegularWorkerRoleButton from '@/views/employee/pages/components/becomeRegularWorkerRoleButton'
import becomeRegularWorkerTableTwoDialog from '@/views/employee/dialog/becomeRegularWorkerTableTwoDialog'
import store from '@/store'
import { intervalTime } from '@/utils/util'
export default {
  name: 'becomeRegularWorkerTable',
  mixins: [$PCommon.TableMixin],
  components: {
    PositiveReviewDialog,
    becomeRegularWorkerRoleButton,
    becomeRegularWorkerTableTwoDialog,
    terminateReviewDialog
  },
  data() {
    return {
      tableData: [],
      loading: false,

      becomeRegularWorkerTableColumnOptions
    }
  },
  methods: {
    countLastDay(row) {
      let employmentConfirmationDate = row.employmentConfirmationDate
      if (employmentConfirmationDate) {
        let employmentConfirmationDateUnix = this.$dayjs(
          employmentConfirmationDate
        ).unix()
        let nowDataUnix = this.$dayjs(new Date()).unix()
        let lastDay = Number(
          intervalTime(nowDataUnix, employmentConfirmationDateUnix).days
        )
        if (lastDay <= 0) {
          return '已结束'
        } else {
          return '仅剩' + lastDay + '天结束'
        }
      } else {
        return 0
      }
    },
    queryApi() {
      let val = getItem('changeManagement__yc')
      if (val) {
        this.loading = true
        const data = {
          ...val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
        this.tableData = []
        getEmployeesList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            store.dispatch('employee/getStaffInfoCount')
            this.tableData = res.data.list || []
            let saveClickRow__yc = getItem('saveClickRow__yc')
            if (saveClickRow__yc) {
              let row = this.tableData.find(
                (item) =>
                  item.candidateInformationId ===
                  saveClickRow__yc.candidateInformationId
              )
              if (row) {
                this.$refs.changeManagement__table__becomeRegularWorkerTableRef.setCurrentRow(
                  row,
                  true
                )
              }
            }
            this.pageConfig.total = res.data.total
          }
        })
      }
    },
    roleButton(row, role) {
      let status = row.status
      /*
        没有status表示不在流程中，按钮全不显示
       */
      if (status) {
        switch (status) {
          case '已发送，待反馈':
            return true
          case '已反馈，待审核':
            return role !== 'sendEmail'
          default:
            return false
        }
      } else {
        return false
      }
    },
    statusButton(row, flag) {
      let status = row.status || ''
      if (status) {
        let splitStatus = status.split('，')
        if (splitStatus && splitStatus.length) {
          if (flag === 'status') {
            return splitStatus[0]
          } else {
            return splitStatus[1]
          }
        }
      }
    },
    sendEmail(row) {
      this.saveClickRow(row)
      this.$refs.becomeRegularWorkerTableTwoDialogRef.show(row)
    },

    saveClickRow(row) {
      setItem('saveClickRow__yc', row)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    audit(row) {
      this.saveClickRow(row)
      this.$refs.PositiveReviewDialogRef.show(row)
    },
    terminate(row) {
      console.log('转正终止按钮', row)
      this.saveClickRow(row)
      this.$refs.terminateReviewDialogRef.show(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.changeManagement__table__becomeRegularWorkerTable {
  &__statusButton {
    display: flex;
    &__status {
      span {
        &:last-child {
          margin-right: 14px;
          .circle {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #e6a23c;
            margin-left: 8px;
            margin-right: 4px;
          }
        }
      }
    }
    &__button {
      display: flex;
      .el-button {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4e4e50;
        line-height: 10px;
        &:first-child {
          width: 80px !important;
          height: 24px !important;
        }
        &:last-child {
          height: 24px !important;
        }
      }
    }
  }
}
.warningTip {
  width: 413px;
  height: 14px;
  position: absolute;
  left: 14px;
  top: 32px;
  z-index: 999;
  background: rgba(222, 57, 52, 0.1);
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #de3934;
  line-height: 14px;
}
</style>
